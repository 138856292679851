import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AudioRecorder from '../../utilities/AudioRecorder';

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

class Planning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            problems: [],
            newGoal: {}, // Track new goal input for each problem
            newPlan: {}, // Track new plan input for each problem and status
            editingGoalId: null, // Track which goal is being edited
            editingPlanId: null, // Track which plan entry is being edited
            editValue: '', // Track the current value being edited
            editingProblemId: null, // Track which problem is being edited
            editProblemValue: '',
            //active tab
            activeTabs : {},
            //editing
            editingJourneyId: null,  // Track the currently editing journey
            editingReviewId: null,   // Track the currently editing review
            editingJourneyText: '',
            editingJourneyDate: '',
            editingJourneyType: '',
            editingReviewText: '',
            editingReviewDate: '',
        };
        
    }


    componentDidMount() {
        const activeTabs = {};
        if (this.props.problems) {
            this.props.problems.forEach((problem) => {
                activeTabs[problem.id] = 'planning'; // Set default tab to 'planning'
            });        
            this.setState({ problems: this.props.problems, activeTabs });
        } else {
            this.setState({ problems: [], activeTabs });
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.problems !== this.props.problems) {
            const activeTabs = { ...this.state.activeTabs };
            this.props.problems.forEach((problem) => {
                if (!activeTabs[problem.id]) {
                    activeTabs[problem.id] = 'planning'; // Set default tab to 'planning' if not already set
                }
            });
            this.setState({ problems: this.props.problems, activeTabs });
        }
    }

    // Function to switch tabs for a specific problem
    switchTab = (problemId, tab) => {
        this.setState((prevState) => ({
            activeTabs: { ...prevState.activeTabs, [problemId]: tab }
        }));
    };
    // Toggle collapse/expand
    toggleCollapse = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        collapsed: !problem.collapsed,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a goal
    deleteGoal = (problemId, goalId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: problem.goal.filter((goal) => goal.id !== goalId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a plan entry
    deletePlanEntry = (problemId, planId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: problem.plan.filter((plan) => plan.id !== planId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a journey
    deleteJourney = (problemId, journeyId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: problem.journey.filter((journey) => journey.id !== journeyId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a review
    deleteReview = (problemId, reviewId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: problem.review.filter((review) => review.id !== reviewId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete Problem
    deleteProblem = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.filter(problem => problem.id !== problemId);
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }
    

    // Method to add a new goal
    addGoal = (problemId) => {
        const { newGoal } = this.state;
        if (!newGoal[problemId] || newGoal[problemId].trim() === '') return; // Don't add empty goals

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: [
                            ...problem.goal,
                            { id: generateId(), description: newGoal[problemId] },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newGoal: { ...prevState.newGoal, [problemId]: '' },
            };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new plan entry
    addPlanEntry = (problemId, status) => {
        const { newPlan } = this.state;
        const key = `${problemId}-${status}`;
        const newEntry = newPlan[key];

        if (!newEntry || newEntry.trim() === '') return; // Don't add empty entries

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: [
                            ...problem.plan,
                            { id: generateId(), entry: newEntry, status: status },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newPlan: { ...prevState.newPlan, [key]: '' },
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new Journey
    addJourney = (problemId) => {
        const { newJourneyText, newJourneyDate, newJourneyType } = this.state;
        if (!newJourneyText || !newJourneyDate || !newJourneyType) return; // Ensure all fields are filled

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: [
                            ...problem.journey,
                            { id: generateId(), date: newJourneyDate, journey: newJourneyText, type: newJourneyType },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newJourneyText: '',
                newJourneyDate: '',
                newJourneyType: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new Review
    addReview = (problemId) => {
        const { newReviewText, newReviewDate } = this.state;
        if (!newReviewText || !newReviewDate) return; // Ensure all fields are filled

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: [
                            ...problem.review,
                            { id: generateId(), date: newReviewDate, notes: newReviewText },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newReviewText: '',
                newReviewDate: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    addNewProblem = () => {
        const newProblem = {
            id: generateId(),
            problem: 'New Problem', // You can change this to a default description or leave it as an empty string
            priority: 'Low',
            goal: [],
            plan: [],
            journey: [],
            review: [],
            collapsed: false
        };
    
        this.setState((prevState) => ({
            problems: [newProblem, ...prevState.problems]
        }), () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }
    

    startEditingJourney = (item) => {
        this.setState({
            editingJourneyId: item.id,
            editingJourneyText: item.journey,
            editingJourneyDate: item.date,
            editingJourneyType: item.type,
        });
    };
    
    startEditingReview = (item) => {
        this.setState({
            editingReviewId: item.id,
            editingReviewText: item.notes,
            editingReviewDate: item.date,
        });
    };

    startEditingProblem = (problemId, currentProblem) => {
        this.setState({
            editingProblemId: problemId,
            editProblemValue: currentProblem
        });
    }
    

    updateJourney = (problemId, journeyId) => {
        const { editingJourneyText, editingJourneyDate, editingJourneyType } = this.state;
        
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        journey: problem.journey.map((journey) => {
                            if (journey.id === journeyId) {
                                return {
                                    ...journey,
                                    journey: editingJourneyText,
                                    date: editingJourneyDate,
                                    type: editingJourneyType,
                                };
                            }
                            return journey;
                        }),
                    };
                }
                return problem;
            });
    
            return {
                problems: updatedProblems,
                editingJourneyId: null,
                editingJourneyText: '',
                editingJourneyDate: '',
                editingJourneyType: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };
    
    updateReview = (problemId, reviewId) => {
        const { editingReviewText, editingReviewDate } = this.state;
    
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        review: problem.review.map((review) => {
                            if (review.id === reviewId) {
                                return {
                                    ...review,
                                    notes: editingReviewText,
                                    date: editingReviewDate,
                                };
                            }
                            return review;
                        }),
                    };
                }
                return problem;
            });
    
            return {
                problems: updatedProblems,
                editingReviewId: null,
                editingReviewText: '',
                editingReviewDate: '',
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };
    
    // Handle key press for adding new entries
    handleKeyPress = (event, problemId, type, status) => {
        if (event.key === 'Enter') {
            if (type === 'goal') {
                this.addGoal(problemId);
            } else if (type === 'plan') {
                this.addPlanEntry(problemId, status);
            }
        }
    };

    // Handle input changes
    handleInputChange = (event, problemId, type, status) => {
        const value = event.target.value;

        if (type === 'goal') {
            this.setState((prevState) => ({
                newGoal: { ...prevState.newGoal, [problemId]: value },
            }));
        } else if (type === 'plan') {
            const key = `${problemId}-${status}`;
            this.setState((prevState) => ({
                newPlan: { ...prevState.newPlan, [key]: value },
            }));
        }
    };

    // Handle double-click to edit
    handleDoubleClick = (entryId, type, currentValue) => {
        if (type === 'goal') {
            this.setState({ editingGoalId: entryId, editValue: currentValue });
        } else if (type === 'plan') {
            this.setState({ editingPlanId: entryId, editValue: currentValue });
        }
    };

    // Handle change while editing
    handleEditChange = (event) => {
        this.setState({ editValue: event.target.value });
    };

    // Save edited value
    handleEditSave = (event, problemId, type) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        if (type === 'goal') {
                            return {
                                ...problem,
                                goal: problem.goal.map((goal) => {
                                    if (goal.id === prevState.editingGoalId) {
                                        return { ...goal, description: prevState.editValue };
                                    }
                                    return goal;
                                }),
                            };
                        } else if (type === 'plan') {
                            return {
                                ...problem,
                                plan: problem.plan.map((plan) => {
                                    if (plan.id === prevState.editingPlanId) {
                                        return { ...plan, entry: prevState.editValue };
                                    }
                                    return plan;
                                }),
                            };
                        }
                    }
                    return problem;
                });

                return {
                    problems: updatedProblems,
                    editingGoalId: null,
                    editingPlanId: null,
                    editValue: '',
                };
            });
        }
    };

    handleProblemEditChange = (event) => {
        this.setState({ editProblemValue: event.target.value });
    }
    
    handleProblemEditSave = (event, problemId) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        return {
                            ...problem,
                            problem: prevState.editProblemValue
                        };
                    }
                    return problem;
                });
    
                return {
                    problems: updatedProblems,
                    editingProblemId: null,
                    editProblemValue: ''
                };
            }, () => {
                this.props.onProblemsUpdate(this.state.problems);
            });
        }
    }
    

    // Handle drag end
    onDragEnd = (result, problemId) => {
        const { destination, draggableId } = result;
        if (!destination) return;
    
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    const draggedItem = problem.plan.find((plan) => plan.id === draggableId);
    
                    // Remove dragged item from its original place
                    const newPlan = problem.plan.filter((plan) => plan.id !== draggableId);
    
                    // Add dragged item to the new status
                    draggedItem.status = destination.droppableId;
                    newPlan.splice(destination.index, 0, draggedItem);
    
                    return {
                        ...problem,
                        plan: newPlan,
                    };
                }
                return problem;
            });
    
            return { problems: updatedProblems };
        }, () => {
            
            // Now `updatedProblems` is available within the callback
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // send transcription to parent component
    handleInputUpdate = async (newTranscription, toastId) => {

        this.props.onVoiceInput(newTranscription, toastId)
    };


    // Method to toggle priority
    togglePriority = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    let newPriority;
                    if (problem.priority === 'Inactive') {
                        newPriority = "Low"
                    } else if (problem.priority === "Low") {
                        newPriority = "Moderate";
                    } else if (problem.priority === "Moderate") {
                        newPriority = "High";
                    } else {
                        newPriority = "Inactive";
                    }
                    return {
                        ...problem,
                        priority: newPriority,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Rendering Journey Timeline and Review
    renderJourney(journey, review, problemId) {
        return (
            <div className='col-span-5 grid grid-cols-6 grid-rows-2 gap-8'>
                <div className='col-span-4 flex flex-row items-center'>
                    {journey.map(item => (
                        <div className="relative flex flex-col items-center" key={item.id} style={{ minWidth: '200px' }}>
                            <div className="flex items-center justify-center mb-2">
                                <div className="bg-sky-700 h-6 w-6 rounded-full z-10"></div>
                            </div>
                            <div className="h-1 w-full bg-gray-400 absolute top-3 z-4 ml-12"></div>
                            <div className="px-4">
                                {this.state.editingJourneyId === item.id ? (
                                    <div className='bg-slate-200 p-2 rounded h-52 w-52'>
                                        <div className='bg-slate-300 p-2'>
                                        <textarea 
                                            className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                                            value={this.state.editingJourneyText}
                                            onChange={(e) => this.setState({ editingJourneyText: e.target.value })}
                                        />
                                        <input 
                                            type='date' 
                                            className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                                            value={this.state.editingJourneyDate}
                                            onChange={(e) => this.setState({ editingJourneyDate: e.target.value })}
                                        />
                                        <select 
                                            className='bg-white border border-slate-200 rounded w-full text-black my-1'
                                            value={this.state.editingJourneyType}
                                            onChange={(e) => this.setState({ editingJourneyType: e.target.value })}
                                        >
                                            <option value="">-Select type-</option>
                                            <option value="Diagnosis">Diagnosis</option>
                                            <option value="Pathology">Pathology</option>
                                            <option value="Imaging">Imaging</option>
                                            <option value="Report">Report</option>
                                            <option value="Event">Event</option>
                                        </select>
                                        <button 
                                            className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                                            onClick={() => this.updateJourney(problemId, item.id)}
                                        >
                                            Update Journey
                                        </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div 
                                        className='bg-slate-200 p-2 rounded h-52 w-52' 
                                        onDoubleClick={() => this.startEditingJourney(item)}
                                    >
                                        <div className='bg-white rounded shadow p-2 relative'>
                                            <div className='grid grid-cols-2'>
                                                <p className="text-gray-600 text-sm place-self-start">{new Date(item.date).toLocaleDateString()}</p>  
                                                <h3 className="text-sm text-gray-600 place-self-end">{item.type}</h3>
                                            </div>
                                            <p className="text-black text-md my-4">{item.journey}</p>      
                                            <button className='text-md absolute bottom-1 right-2' onClick={() => this.deleteJourney(problemId, item.id)}>X</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className='bg-slate-300 rounded p-2 col-span-2'>
                    <p className='p-2 font-semibold text-lg text-center'>Review</p>
                    {review.map(item => (
                        <div key={item.id} className="p-2 m-2 bg-white rounded relative">
                            {this.state.editingReviewId === item.id ? (
                                <div>
                                    <textarea 
                                        className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                                        value={this.state.editingReviewText}
                                        onChange={(e) => this.setState({ editingReviewText: e.target.value })}
                                    />
                                    <input 
                                        type='date' 
                                        className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                                        value={this.state.editingReviewDate}
                                        onChange={(e) => this.setState({ editingReviewDate: e.target.value })}
                                    />
                                    <button 
                                        className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                                        onClick={() => this.updateReview(problemId, item.id)}
                                    >
                                        Update Review
                                    </button>
                                </div>
                            ) : (
                                <div onDoubleClick={() => this.startEditingReview(item)}>
                                    <div className='text-sm text-gray-500'>{new Date(item.date).toLocaleDateString()}</div>
                                    <div>{item.notes}</div>
                                    <button className='text-md absolute bottom-1 right-2' onClick={() => this.deleteReview(problemId, item.id)}>X</button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
    
                {/* Section for adding new Journey */}
                <div className='rounded py-2 px-32 col-span-3 place-self-start'>
                    <p className='p-2 font-semibold text-md text-center'>Add Journey</p>
                    <div className='p-2'>
                        <textarea 
                            className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                            placeholder='Type in journey here'
                            value={this.state.newJourneyText || ''}
                            onChange={(e) => this.setState({ newJourneyText: e.target.value })}
                        />
                        <input 
                            type='date' 
                            className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                            value={this.state.newJourneyDate || ''}
                            onChange={(e) => this.setState({ newJourneyDate: e.target.value })}
                        />
                        <select 
                            className='bg-white border border-slate-200 rounded w-full text-black my-1'
                            value={this.state.newJourneyType || ''}
                            onChange={(e) => this.setState({ newJourneyType: e.target.value })}
                        >
                            <option value="">-Select type-</option>
                            <option value="Diagnosis">Diagnosis</option>
                            <option value="Pathology">Pathology</option>
                            <option value="Imaging">Imaging</option>
                            <option value="Report">Report</option>
                            <option value="Event">Event</option>
                        </select>
                        <button 
                            className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                            onClick={() => this.addJourney(problemId)}
                        >
                            Add Journey
                        </button>
                    </div>
                </div>
    
                {/* Section for adding new Review */}
                <div className='rounded py-2 px-32 col-span-3 place-self-end'>
                    <p className='p-2 font-semibold text-lg text-center'>Add Review</p>
                    <div className='p-2'>
                        <textarea 
                            className='bg-white border border-slate-200 rounded w-full h-24 resize-none placeholder:italic' 
                            placeholder='Type in review note here'
                            value={this.state.newReviewText || ''}
                            onChange={(e) => this.setState({ newReviewText: e.target.value })}
                        />
                        <input 
                            type='date' 
                            className='bg-white border border-slate-200 rounded w-full text-gray-400 my-2'
                            value={this.state.newReviewDate || ''}
                            onChange={(e) => this.setState({ newReviewDate: e.target.value })}
                        />
                        <button 
                            className='bg-sky-500 text-white rounded p-2 mt-2 w-full'
                            onClick={() => this.addReview(problemId)}
                        >
                            Add Review
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    
    render() {
        const { problems, newGoal, newPlan, editingGoalId, editingPlanId, editValue, activeTabs } = this.state;
    
        // Only sort if there are problems
        var sortedProblems;
        if (problems && problems.length > 0) {
            sortedProblems = problems.sort((a, b) => {
                const priorityOrder = { High: 1, Moderate: 2, Low: 3, Inactive: 4 };
                return priorityOrder[a.priority] - priorityOrder[b.priority];
            });
        } else {
            sortedProblems = problems;
        }
        
    
        return (
            <div className='col-span-6 bg-slate-50 m-2'>
                <div className='col-span-6'>  <AudioRecorder onInputUpdate={this.handleInputUpdate} /></div>
   
                <div className='flex justify-center'>
                <button className="text-md bg-sky-600 hover:bg-sky-700 text-white rounded text-center p-2 m-2 cursor-pointer w-60" onClick={this.addNewProblem}>Add Problem</button>
                </div>
                {sortedProblems.map((item) => (
                    <div key={item.id}
                        className={`m-2 p-2 rounded border-l-8 bg-slate-200 ${
                        item.priority === "High" ? "border-red-700" :
                        item.priority === "Moderate" ? "border-yellow-400" : 
                        item.priority === "Low" ? "border-green-400" :
                        "border-slate-500"
                        
                    }`}
                    >
                     
                    <div className='col-span-4 text-md font-semibold m-2'>
                        {this.state.editingProblemId === item.id ? (
                            <>
                            <input
                                value={this.state.editProblemValue}
                                onChange={this.handleProblemEditChange}
                                onKeyPress={(e) => this.handleProblemEditSave(e, item.id)}
                                className='border-slate-100 focus:border-slate-200 rounded placeholder-gray-400 w-80'
                            />
                            </>
                        ) : (
                            <span onDoubleClick={() => this.startEditingProblem(item.id, item.problem)}>
                                {item.problem}
                            </span>
                        )}
                        
                        <span className="text-sm mx-2 italic rounded text-black p-1 font-light float-right cursor-pointer" onClick={() => this.toggleCollapse(item.id)}>
                            {item.collapsed ? "Click to expand" : "Click to collapse"}
                        </span>
                        <span className='float-right bg-red-700 rounded p-1 mx-2 text-sm font-light hover:bg-red-800 cursor-pointer w-20 text-center text-white' onClick={()=> this.deleteProblem(item.id)}>Delete</span>
                        <span
                            className={`text-sm mx-2 rounded p-1 font-light float-right cursor-pointer w-20 text-center ${
                                    item.priority === "High" ? "bg-red-700 text-white" :
                                    item.priority === "Moderate" ? "bg-yellow-400 text-black" : 
                                    item.priority === "Low" ? "bg-green-400 text-black" :
                                    "bg-slate-500 text-gray-300"}`}
                            onClick={() => this.togglePriority(item.id)}
                        > 
                            {item.priority}
                        </span>

                    </div>

                        {!item.collapsed && (
                            <DragDropContext onDragEnd={(result) => this.onDragEnd(result, item.id)}>
                            <div className='grid grid-cols-5' style={{ gridTemplateRows: 'minmax(6px, auto) minmax(400px, 680px)' }}>
                                <div className='col-span-5 flex justify-center'>
                                    <div className='bg-slate-300 rounded w-72 -mt-8 mb-2'>
                                        <button
                                            className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTabs[item.id] === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                                            onClick={() => this.switchTab(item.id, 'planning')}
                                        >
                                            Planning
                                        </button>
                                        <button
                                            className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTabs[item.id]=== 'journey' ? 'bg-sky-300' : 'bg-slate-200'}`}
                                            onClick={() => this.switchTab(item.id, 'journey')}
                                        >
                                            Journey
                                        </button>
                                    </div>
                                </div>
                                <div className='col-span-5 grid grid-cols-5'>
                                    {activeTabs[item.id] === 'journey' && this.renderJourney(item.journey, item.review, item.id)}
                                    {activeTabs[item.id] === 'planning' && (
                                        
                                        <div className='m-2 p-2 text-sm bg-white rounded'>
                            
                                            <div className='p-2 m-1 bg-slate-200'>
                                                <p className='text-md font-semibold'>Goals</p>
                                                {item.goal.map((each) => (
                                                    <div key={each.id} className='bg-white p-2 m-1 flex justify-between items-center'>
                                                        {editingGoalId === each.id ? (
                                                            <input
                                                                value={editValue}
                                                                onChange={this.handleEditChange}
                                                                onKeyPress={(e) => this.handleEditSave(e, item.id, 'goal')}
                                                                className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                            />
                                                        ) : (
                                                            <span
                                                                onDoubleClick={() => this.handleDoubleClick(each.id, 'goal', each.description)}
                                                            >
                                                                {each.description}
                                                            </span>
                                                        )}
                                                        <button
                                                            className='text-slate-800 ml-4'
                                                            onClick={() => this.deleteGoal(item.id, each.id)}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                ))}
                                                <div className='p-2 text-md flex justify-between items-center'>
                                                    <input
                                                        placeholder='Type in new goal here'
                                                        className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                        value={newGoal[item.id] || ''}
                                                        onChange={(e) => this.handleInputChange(e, item.id, 'goal')}
                                                        onKeyPress={(e) => this.handleKeyPress(e, item.id, 'goal')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                        
                                    {activeTabs[item.id] === 'planning' && (
                                        ['plan', 'doing', 'done', 'pending'].map((status) => (
                                            <Droppable droppableId={status} key={status}>
                                                {(provided) => (
                                                    <div
                                                        className='bg-white rounded m-2'
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        <div
                                                            className={` p-2 text-md text-center rounded m-2 ${
                                                                status === 'plan'
                                                                    ? 'bg-yellow-400 text-black'
                                                                    : status === 'doing'
                                                                    ? 'bg-red-700 text-white'
                                                                    : status === 'pending'
                                                                    ? 'bg-slate-500 text-white'
                                                                    : 'bg-teal-600 text-white'
                                                            }`}
                                                        >
                                                            {status.charAt(0).toUpperCase() + status.slice(1)}
                                                        </div>
                                                        {item.plan
                                                            .filter((each) => each.status === status)
                                                            .map((each, idx) => (
                                                                <Draggable key={each.id} draggableId={each.id} index={idx}>
                                                                    {(provided) => (
                                                                        <div
                                                                            className='bg-slate-200 m-2 p-4 text-md flex justify-between items-center rounded'
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            {editingPlanId === each.id ? (
                                                                                <input
                                                                                    value={editValue}
                                                                                    onChange={this.handleEditChange}
                                                                                    onKeyPress={(e) =>
                                                                                        this.handleEditSave(e, item.id, 'plan')
                                                                                    }
                                                                                    className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                                                />
                                                                            ) : (
                                                                                <span
                                                                                    onDoubleClick={() =>
                                                                                        this.handleDoubleClick(each.id, 'plan', each.entry)
                                                                                    }
                                                                                >
                                                                                    {each.entry}
                                                                                </span>
                                                                            )}
                                                                            <button
                                                                                className='text-slate-800 ml-4'
                                                                                onClick={() => this.deletePlanEntry(item.id, each.id)}
                                                                            >
                                                                                X
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                        {provided.placeholder}
                                                        <div className='p-2 text-md flex justify-between items-center'>
                                                            <input
                                                                placeholder={`Type in new task here`}
                                                                className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                                value={newPlan[`${item.id}-${status}`] || ''}
                                                                onChange={(e) =>
                                                                    this.handleInputChange(e, item.id, 'plan', status)
                                                                }
                                                                onKeyPress={(e) =>
                                                                    this.handleKeyPress(e, item.id, 'plan', status)
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Droppable>
                                        ))
                                    )}
                                </div>
                            </div>
                        </DragDropContext>
                        
                        )}
                    </div>
                ))}
            </div>
        );
    }
    
}

export default Planning;