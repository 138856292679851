import React, { Component } from 'react';
import { CTAPopUp, CTAReady } from './CTA';
import { useNavigate } from 'react-router-dom';

const CTA = () => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col items-center'>
            <button               
                onClick={() => navigate('/register')}
                className='my-10 mx-4 bg-sky-700 hover:bg-sky-800 rounded w-52 p-4 shadow text-white text-xl font-bold'>Sign Up
            </button>
            <button onClick={() => navigate('/login')} className='text-red-800 text-md italic'>Click here to login if you already have an account with us</button>
        </div>
    )
}



export class HeroRightImage extends Component {

    render() {
        return (
            <>
            <h1 className='text-5xl font-bold text-center mt-10 mb-4 text-sky-800 mx-64'>{this.props.title}</h1>
            <p className='text-2xl text-center'>{this.props.subtitle}</p>
            <div className='grid grid-cols-2 gap-10 p-10 m-6'>
                <div className='align-items-right'>
                 
                    <p className='text-md'>{this.props.subtitle}</p>
                </div>
                <div>
                    <img src={this.props.image} alt="Consultation Image" className="w-[600px] object-contain"/>
                </div>

            </div>
            </>
        )
    }
}

export class HeroLeftImage extends Component {

    render() {
        return (
            <>
            <h1 className='text-5xl font-bold text-center mt-10 mb-4 text-sky-800 mx-64'>{this.props.title}</h1>
            <p className='text-2xl text-center'>{this.props.subtitle}</p>
            <div className='grid grid-cols-2 gap-10 p-10 m-6'>
                <div>
                    <img src={this.props.image} alt="Consultation Image" className="w-[600px] object-contain"/>
                </div>
                <div className='align-items-right'>
           
                    {/* <p className='text-md'>{this.props.subtitle}</p> */}
                    <div>
                    <CTAReady label="I'm interested" instruction="Fill your details below to try our demo"/>
                    </div>
                </div>
            

            </div>
            </>
        )
    }
}

export class HeroCenterImage extends Component {

    render() {
        return (
            <>
                   <h1 className='text-5xl font-bold text-center mt-10 mb-4 text-sky-800 mx-64'>{this.props.title}</h1>
                   <p className='text-2xl text-center'>{this.props.subtitle}</p>
            <div className='flex justify-center mt-10'>
                <img src={this.props.image} alt="Consultation Image" className="w-[1200px] object-contain"/>
 
            </div>
            </>
        )
    }
}

export class HeroCenterText extends Component {

    render() {
        return (
            <>
            <h1 className='text-5xl font-bold text-center mt-10 mb-4 text-sky-800 mx-64'>{this.props.title}</h1>
            <p className='text-2xl text-center'>{this.props.subtitle}</p>
            <CTAPopUp label="I'm interested" instruction="Fill your details below to try our demo"/>
            </>
        )
    }
}

export class HeroCenterVideo extends Component {

    render() {
        return (
            <div className='py-24 xs:py-4 md:py-4 sm:py-4'>
                <h1 className='text-6xl font-bold text-center mt-10 mb-4 text-sky-800 mx-64 xs:text-4xl xs:mx-8 sm:mx-4 sm:text-5xl'>{this.props.title}</h1>
                <p className='text-3xl text-center xs:text-2xl xs:mx-4 sm:mx-4 md:mx-4 md:text-2xl sm:text-2xl'>{this.props.subtitle}</p>
                <div className='flex justify-center mt-10'>
                    <iframe className="inset-0 w-[1000px] aspect-video shadow-xl rounded xs:mx-4 sm:mx-4" src={this.props.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className='text-center'><CTA /></div>
            </div>
        )
    }
}