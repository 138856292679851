import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import GPMPPdf from '../../docs/GPMP.pdf';
import TCAPdf from '../../docs/TCA.pdf';

const formatDate = (dateString) => {
  const dateObject = new Date(dateString);

  // Extract the day, month, and year
  const day = dateObject.getDate().toString().padStart(2, '0'); // Ensure two digits for the day
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for the month
  const year = dateObject.getFullYear();

  // Format the date as "21/03/2024"
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

function wrapText(text, maxLength = 60) {
  if (!text) return '';
  const words = text.split(' ');
  let wrappedText = '';
  let line = '';

  words.forEach((word) => {
      if (line.length + word.length + 1 > maxLength) {
          wrappedText += line.trim() + '\n';
          line = '';
      }
      line += word + ' ';
  });

  wrappedText += line.trim(); // add the last line
  return wrappedText;
}

export const printing = () => {
    var htmlcode = document.getElementById('plan').innerHTML;
    var iframe = document.getElementById("CaredevoPrint");
    var printDocument = iframe.contentDocument;
    var printPreview = iframe.contentWindow;
    printPreview.focus();
   
    printDocument.open();
    printDocument.write(`<!DOCTYPE html>
    <html>
    <head>
    <link rel="stylesheet" type="text/css" media='print' href="/dist/print.css">
    </head>
    <style>
    body {
        line-height : 1.6;
        font-size : 12pt;
        padding-bottom  :50pt;
    }
    iframe {
        display : none;
    }
    @media print {        
        @page {
            size: A4;
            margin-top : 0.80cm;
            margin-bottom : 0.80cm;
        }
        * {
            -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
            color-adjust: exact !important;  /*Firefox*/
        }
        .section {
            page-break-inside: avoid;
        }
        .title {
            padding : 30pt
            width : 100%;
            height : 20pt;
            background-color: #42b3f5;
            background-image: linear-gradient(to right, #42b3f5 , white);
            border-radius: 5pt;
        }
        .title, {
            font-size:large;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            color: black;
            padding: 10px;
            text-align:center
        }
        .header {
            font-size : 13pt;
            color : black;
            font-weight : 600;
            margin-left : 10pt;
            margin-bottom : 20pt;
            margin-top : 20pt;
        }
        .button {
            display: none;
        }
        .decoration {
            position: fixed;
            font-family: Arial, Helvetica, sans-serif;
            font-weight:600;
            top:  300px;
            left : 100px;
            width : 300px;
            color : #42b3f5;
            opacity : 0.1;
            font-size : 140pt;
            letter-spacing : 2pt;
            z-index : -1;
        }
        .profile {
            display: flex;
            flex-direction: column;
            background-color: #f1f5f9; 
            padding: 1pt;
        }
        input {
            font-family: Arial, Helvetica, sans-serif;
            font-size: medium;
            margin : 2pt;
            border: none;
        }
        option {
            font-family: Arial, Helvetica, sans-serif;
            font-size: medium;
            margin : 2pt;
            border: none;
        }
        label {
            font-family: Arial, Helvetica, sans-serif;
            font-size: medium;
            font-weight: 300;
            margin : 10pt;
            border: none;
        }
        .containerparent {
            display: grid;
            grid-template-columns : repeat(2, 1fr);
            grid-gap: 2pt;
        }
        .containerchild {
            display: grid;
            grid-template-columns : 1fr 3fr;
            grid-gap: 2pt;
        }


    }
    </style>
    <body>`)
    printDocument.write(htmlcode);
    printDocument.write("</body></html>");
    printPreview.print();
    printPreview.close();
}

export const printGPMP = async (event, formData, dateOfPlan, reviewDate, signature) => {
  event.preventDefault();
  
  // Fetch your existing PDF as an array buffer
  const existingPdfBytes = await fetch(GPMPPdf).then(res => res.arrayBuffer());

  // Load a PDFDocument from the existing PDF bytes
  const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });

  // Get the form in the PDF
  const form = pdfDoc.getForm();

  // Fill up Profile
  const profile = formData.patient;
  const personText = `${profile.firstName} ${profile.lastName}\nDOB : ${profile.dob}\nGender : ${profile.gender}\nPronoun : ${profile.pronoun}\n${profile.address1} ${profile.address2} \n${profile.suburb}, ${profile.state} ${profile.postcode}`;
  const Profile = form.getTextField('Patient');
  Profile.setText(personText);

  // Fill up Practitioner
  const practitioner = formData.practitioner;
  const practitionerText = `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}\n${practitioner.practiceName} \n${practitioner.address1} ${practitioner.address2}\n ${practitioner.suburb}, ${practitioner.state} ${practitioner.postcode}\nPhone : ${practitioner.phone}\nEmail : ${practitioner.email}\n${practitioner.providerNumber}`;
  const Practitioner = form.getTextField('Practitioner');
  Practitioner.setText(practitionerText);

   // Fill up Problems
   const problems = formData.cdm;

   const eachProblem = (item, index) => {
       // Sort the plans with 'doing' first, 'plan' next, and 'done' last
       const sortedPlans = item.plan.sort((a, b) => {
           const order = { 'doing': 1, 'plan': 2, 'done': 3 };
           return order[a.status] - order[b.status];
       });
   
       return `
Problem #${index + 1}: \n${wrapText(item.problem)}
Goal : 
${item.goal.map(entry => `- ${entry.description}`).join('\n')}
Plan : 
${sortedPlans.map(plan => {
   let statusText;
   switch (plan.status) {
   case 'doing':
       statusText = 'Ongoing';
       break;
   case 'plan':
       statusText = 'Planned';
       break;
   case 'done':
       statusText = 'Done';
       break;
   default:
       statusText = plan.status;
   }
   return `- ${plan.entry}\n  Status: ${statusText}`;
}).join('\n')}

       `;
   };

  // const problemTexts = problems.map(eachProblem);
  // const Problem = form.getTextField('Problem');
  // console.log(problemTexts[0])
  // Problem.setText(problemTexts[0]);
   
   
   const problemTexts = problems.map(eachProblem);
   const firstFiveProblems = problemTexts.slice(0, 3).join('\n');
   const remainingProblems = problemTexts.slice(3).join('\n');

   // Get the text fields
   const Problem1 = form.getTextField('Problem1');
   const Problem2 = form.getTextField('Problem2');

   // Set the text for each field
   Problem1.setText(firstFiveProblems);

   if (remainingProblems) {
       Problem2.setText(remainingProblems);
   } else {
       Problem2.setText('');  
   }


  // Fill up Problem
  // const tags = formData.tags;
  // const journeys = formData.journeys;
  // const tagText = tags.map(tag => {
  //   const journeyText = journeys
  //     .filter(item => item.tagId === tag._id)
  //     .map(item => {
  //       const date = new Date(item.date).toLocaleDateString('en-GB');
  //       return `  - ${date} ${item.title}`;
  //     }).join('\n  ');
  //   return journeyText ? `${tag.tag}\n  ${journeyText}` : tag.tag;
  // }).join('\n\n');
  // const Problem = form.getTextField('Problem'); 
  // Problem.setText(tagText);

  // // Fill up Todo Planned
  // const todos = formData.todos;
  // const todoText = todos.map(item => {
  //   const dateAdded = new Date(item.dateAdded).toLocaleDateString('en-GB');
  //   return `${item.title}
  // Added on ${dateAdded}
  // Review interval every ${item.every} ${item.everyUnit}`;
  // }).join('\n\n');
  // const TodoPlanned = form.getTextField('Todo');
  // TodoPlanned.setText(todoText);
  
  // // Fill up Goal Planned
  // const goals = formData.goals;
  // const goalText = goals.map(item => {
  //   const dateAdded = new Date(item.dateAdded).toLocaleDateString('en-GB');
  //   return `${item.title} ${item.equation} ${item.value} within ${item.within} ${item.withinUnits}
  // Added on ${dateAdded}
  // Review interval every ${item.reviewInterval} ${item.reviewUnits}
  // `;
  // }).join('\n\n');
  // const GoalPlanned = form.getTextField('Goal');
  // GoalPlanned.setText(goalText);
  
  // Fill up Team 
  const teams = formData.teams;
  const teamsText = teams.map(team => 
    `${team.title} ${team.firstName} ${team.lastName}
${team.profession}`
  ).join('\n\n');
  const Team = form.getTextField("Team1");
  Team.setText(teamsText);

  // Fill up Date of Plan
  const PlanDate = form.getTextField('planDate');
  PlanDate.setText(formatDate(dateOfPlan));

  // Fill up Review Date
  const ReviewDate = form.getTextField('ReviewDate');
  ReviewDate.setText(formatDate(reviewDate));

  // Add the signature to the bottom of the second page
  if (signature) {
      const pages = pdfDoc.getPages();
      const secondPage = pages[1]; // Get the second page

      if (secondPage) {
          // Convert the signature data URL to an image that can be embedded in the PDF
          const signatureImage = await pdfDoc.embedPng(signature);

          // Get the dimensions of the signature image
          const signatureDims = signatureImage.scale(0.5); // Scale the signature if necessary

          // Draw the signature on the second page at the bottom
          secondPage.drawImage(signatureImage, {
              x: 40, // Adjust X coordinate as needed
              y: 560, // Y coordinate near the bottom of the page
              width: signatureDims.width,
              height: signatureDims.height,
          });
      }
  }

  // Flatten the form to prevent further editing
  form.flatten();

  // Serialize the PDF to bytes (Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Create a Blob and a link element to allow the user to download the PDF
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  
  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Open the PDF in a new browser window
  const newWindow = window.open(blobUrl, '_blank');
  if (!newWindow) {
      // Handle cases where the browser blocked the new window
      alert('Please allow pop-ups to view the PDF.');
  }
};

export const printTCA = async (event, formDataArray, patient, practitioner, signature) => {
    event.preventDefault();
    
    // Fetch your existing PDF as an array buffer
    const existingPdfBytes = await fetch(TCAPdf).then(res => res.arrayBuffer());
  
    // Create a new PDFDocument to combine all individual PDFs into one
    const combinedPdfDoc = await PDFDocument.create();
  
    // Load a font for the sample letter
    const font = await combinedPdfDoc.embedFont(StandardFonts.Helvetica);
  
    // Iterate over each object in formDataArray to create a PDF for each
    for (const formData of formDataArray) {
      // Add a new page for the GP letter
      const letterPage = combinedPdfDoc.addPage([595.28, 841.89]); // A4 size in points
      const { width, height } = letterPage.getSize();

      // Sample letter text with line breaks
      const letterText = `
  To : ${formData.title} ${formData.firstName} ${formData.lastName},
       ${formData.address1} ${formData.address2}
       ${formData.suburb}, ${formData.state} ${formData.postcode}
  
       Dear ${formData.firstName},
  
       We are referring ${patient.firstName} ${patient.lastName} for further evaluation and management.
       Please find the attached detailed care plan with TCA form.
  
       Sincerely,
  
  
  
       ${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}
      `;
  
      // Split the letterText by newlines to handle line breaks
      const lines = letterText.trim().split('\n');
  
      // Set initial Y position for text
      let yPosition = height - 100;
      const lineHeight = 14; // Adjust line height as needed
  
      // Draw each line of text
      lines.forEach((line) => {
        letterPage.drawText(line, {
          x: 50,
          y: yPosition,
          size: 12,
          font,
          color: rgb(0, 0, 0),
          maxWidth: width - 100,
        });
        yPosition -= lineHeight; // Move to the next line
      });
  
      // Embed the signature image if it exists
      if (signature) {
        const signatureImage = await combinedPdfDoc.embedPng(signature);
        letterPage.drawImage(signatureImage, {
          x: 50, // Adjust the x position
          y: yPosition - 60, // Adjust the y position (below the "Sincerely" text)
          width: 150, // Adjust the width of the signature
          height: 50, // Adjust the height of the signature
        });
      }
  
      // Load a PDFDocument from the existing PDF bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
  
      // Get the form in the PDF
      const form = pdfDoc.getForm();
  
      // Define the fields and values to update based on formData
      const fieldsToUpdate = [
        { field: "GPName", value: `${practitioner.title} ${practitioner.firstName} ${practitioner.lastName}` },
        { field: "GPAddress", value: `${practitioner.practiceName}, ${practitioner.address1} ${practitioner.address2}, ${practitioner.suburb} ${practitioner.state}` },
        { field: "ProviderNumber", value: `${practitioner.providerNumber}` },
        { field: "GPPostcode", value: `${practitioner.postcode}` },
        { field: "PatientName", value: `${patient.firstName} ${patient.lastName}` },
        { field: "MedicareNumber", value: `${patient.medicare}` },
        { field: "PatientAddress", value: `${patient.address1} ${patient.address2}, ${patient.suburb} ${patient.state}` },
        { field: "PatientPostcode", value: `${patient.postcode}` },
        { field: "AHPName", value: `${formData.firstName} ${formData.lastName}` },
        { field: "AHPAddress", value: `${formData.address1} ${formData.address2}, ${formData.suburb} ${formData.state}` },
        { field: "AHPPostcode", value: `${formData.postcode}` },
        { field: "ATSI", value: formData.profession === "ATSI Worker" ? formData.visits : `` },
        { field: "Audiologist", value: formData.profession === "Audiologist" ? formData.visits : `` },
        { field: "Chiropractor", value: formData.profession === "Chiropractor" ? formData.visits : `` },
        { field: "DiabetesEducator", value: formData.profession === "Diabetes Educator" ? formData.visits : `` },
        { field: "Dietitian", value: formData.profession === "Dietitian" ? formData.visits : `` },
        { field: "ExercisePhysiologist", value: formData.profession === "Exercise Physiologist" ? formData.visits : `` },
        { field: "MHWorker", value: formData.profession === "Mental Health Worker" ? formData.visits : `` },
        { field: "OT", value: formData.profession === "Occupational Therapist" ? formData.visits : `` },
        { field: "Osteopath", value: formData.profession === "Osteopath" ? formData.visits : `` },
        { field: "Physio", value: formData.profession === "Physiotherapist" ? formData.visits : `` },
        { field: "Podiatrist", value: formData.profession === "Podiatrist" ? formData.visits : `` },
        { field: "Psychologist", value: formData.profession === "Psychologist" ? formData.visits : `` },
        { field: "Speech", value: formData.profession === "Speech Therapist" ? formData.visits : `` },
      ];
  
      // Update the fields in the PDF
      fieldsToUpdate.forEach(({ field, value }) => {
        const fieldToUpdate = form.getTextField(field);
        fieldToUpdate.setText(value);
      });
  
      // Fill up the Date field with the current date
      const DateField = form.getTextField('Date');
      const dateNow = new Date().toLocaleDateString('en-GB');
      DateField.setText(dateNow);
  
      // Embed the signature image in the form PDF if it exists
      if (signature) {
        const signatureImage = await pdfDoc.embedPng(signature);
        const signaturePage = pdfDoc.getPage(0); // Assuming signature is on the first page
  
        // Positioning the signature image in the form (Adjust x, y, width, and height as needed)
        signaturePage.drawImage(signatureImage, {
          x: 50, // Adjust the x position
          y: 120, // Adjust the y position (bottom of the page)
          width: 150, // Adjust the width of the signature
          height: 50, // Adjust the height of the signature
        });
      }
  
      // Flatten the form fields to make them non-editable
      form.flatten();
  
      // Serialize the individual PDF to bytes (Uint8Array)
      const pdfBytes = await pdfDoc.save();
  
      // Load the individual PDF into a new PDFDocument to extract pages
      const individualPdfDoc = await PDFDocument.load(pdfBytes);
  
      // Copy all pages from the individual PDF to the combined PDF
      const [copiedPage] = await combinedPdfDoc.copyPages(individualPdfDoc, [0]);
      combinedPdfDoc.addPage(copiedPage);
    }
  
    // Serialize the combined PDF to bytes (Uint8Array)
    const combinedPdfBytes = await combinedPdfDoc.save();
  
    // Create a Blob for the combined PDF
    const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
  
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
  
    // Open the combined PDF in a new browser window or tab
    const newWindow = window.open(blobUrl, '_blank');
    if (!newWindow) {
      alert('Please allow pop-ups to view the PDF.');
    }
};
  
  
  