import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../../utilities/NavigationContext';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToForm, textToPlan, textToReview } from '../../utilities/HealthAssessmentAI';

import Planning from '../HAComponents/Planning';
import Review from '../HAComponents/Review';

import AudioRecorder from '../../utilities/AudioRecorder';
const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    if (!Array.isArray(problems)) {
        return []; // Return an empty array or handle the error as needed
    }

    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        priority : "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    }));
};

const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    return `${day}-${month}-${year}`; // Format as 'DD-MM-YYYY' for display
}

const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};

class HealthAssessment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.initializeFormData(props.patientData),
            isEditing: false,
            // AI processing
            isLoading: false,
            //active tab
            activeTab: 'background',


        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

    }

    // Initialize formData to ensure healthassessment contains all necessary fields
    initializeFormData = (patientData) => {
        const defaultHealthAssessment = {
            medicalHistory: '',
            pastMedical: '',
            allergies: '',
            familyHistory: '',
            alcohol: '',
            smoking: '',
            substance: '',
            medications: '',
            medicationArrangement: '',
            vision: '',
            hearing: '',
            fallAndHomeSafety: '',
            skin: '',
            feet: '',
            nutrition: '',
            oralHealth: '',
            cognition: '',
            mentalHealth: '',
            urineContinence: '',
            faecalContinence: '',
            mobility: '',
            activity: '',
            social: '',
            housing: '',
            legal: '',
            finance: '',
            driving: '',
            advancedCareDirective: '',
            services: '',
            examination: '',
            surveillance: '',
            vaccination: '',
            date: '', // Format this appropriately
        };

        // Ensure healthassessment has all the necessary fields
        return {
            ...patientData,
            healthassessment: {
                ...defaultHealthAssessment,
                ...patientData.healthassessment,
            },
            haplanning: patientData.haplanning || [], // Use patientData.haplanning directly
            hareviews: patientData.hareviews || []
        };
    };
    
    // componentDidMount() {
    //     const encryptedAdmin = localStorage.getItem('admin');
    //     if (encryptedAdmin) {
    //         const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
    //         const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //         const referrences = adminData.contacts;
    //         const dashboard = adminData.dashboard;
    //         this.setState({ referrences: referrences, dashboard: dashboard });
    //     }
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({
                formData: this.initializeFormData(this.props.patientData),
            });
        }
    }
    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => {
            // If we're switching from editing to saving, update the parent data
            if (prevState.isEditing) {
                this.updatePatientData(); // Call updatePatientData to send the updated formData to the parent
            }
    
            return {
                isEditing: !prevState.isEditing, // Toggle editing state
            };
        });
    };
    

    handleInputChange = (key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                healthassessment: {
                    ...prevState.formData.healthassessment,
                    [key]: value
                }
            }
        }));
    };
    
    //// AI Section
    // After receiving transcription from Voice
    AITranscription = async (text, toastId) => {
       const formData = this.state.formData
       // filling up form
       toast.update(toastId, {
        render: 'Filling up Form..',
        autoClose: false,
        hideProgressBar: false
        });

        var respond = await textToForm(text, formData);

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                healthassessment: respond,
            },
        }), this.saveToLocalStorage);

        // organising Plan
        toast.update(toastId, {
            render: 'Organising Plan..',
            autoClose: false,
            hideProgressBar: false
        });
        var respond2 = await textToPlan(text, formData);
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                haplanning: respond2
            },
        }), this.saveToLocalStorage);

        // writing review
        toast.update(toastId, {
            render: 'Writing Review...',
            autoClose: false,
            hideProgressBar: false
        });
        var respond3 = await textToReview(text, formData.hareviews);
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                hareviews: respond3,
            }
        }), this.saveToLocalStorage); 


        // all done
        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });
    };

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                haplanning: updatedProblems,
            },
        }), this.updatePatientData);
    };

    handleReviewUpdate = (updatedReviews) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            hareviews: updatedReviews,
          },
        }), this.updatePatientData);
    };

    updatePatientData = () => {
        console.log('being called')
        this.props.updatePatientData(this.state.formData)
    };

    // update message
    updateMessage = (message) => {
        this.setState({message: message})
    }

    

    renderBackground() {
        const { isEditing } = this.state;
        const fieldData = [
            { name: 'medicalHistory', label: 'Medical History' },
            { name: 'pastMedical', label: 'Past Medical History' },
            { name: 'allergies', label: 'Allergies' },
            { name: 'familyHistory', label: 'Family History' },
            { name: 'alcohol', label: 'Alcohol Use' },
            { name: 'smoking', label: 'Smoking' },
            { name: 'substance', label: 'Substance Use'},
            { name: 'medications', label: 'Medications' },
            { name: 'medicationArrangement', label: 'Medication Arrangement' },
            { name: 'vision', label: 'Vision' },
            { name: 'hearing', label: 'Hearing' },
            { name: 'fallAndHomeSafety', label: 'Fall and Home Safety' },
            { name: 'skin', label: 'Skin' },
            { name: 'feet', label: 'Feet' },
            { name: 'nutrition', label: 'Nutrition' },
            { name: 'oralHealth', label: 'Oral Health' },
            { name: 'cognition', label: 'Cognition' },
            { name: 'mentalHealth', label: 'Mental Health' },
            { name: 'urineContinence', label: 'Urinary Continence' },
            { name: 'faecalContinence', label: 'Bowel Continence' },
            { name: 'mobility', label: 'Mobility' },
            { name: 'activity', label: 'Activity' },
            { name: 'social', label: 'Social' },
            { name: 'housing', label: 'Accomodation'},
            { name: 'legal', label: 'Legal' },
            { name: 'finance', label: 'Finance' },
            { name: 'driving', label: 'Driving' },
            { name: 'advancedCareDirective', label: 'Advanced Care Directive' },
            { name: 'services', label: 'Services' },
            { name: 'examination', label: 'Examination' },
            { name: 'surveillance', label: 'Surveillance' },
            { name: 'vaccination', label: 'Vaccinations' },
            { name: 'date', label: 'Date completed' },

        ];

        return (
            <div className='col-span-6 rounded shadow-md'>
            <div className='col-span-6'>  <AudioRecorder onInputUpdate={this.AITranscription} /></div>
            <div className='bg-slate-100 m-4'>
            <div className='flex justify-between items-center px-6 py-2'>
                <h2 className='text-lg font-bold text-center'>Assessment</h2>
                <button
                    onClick={this.handleToggleEdit}
                    className={`px-4 py-2 rounded text-white ${isEditing ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                >
                    {isEditing ? 'Save' : 'Edit'}
                </button>
            </div>
            <div className='mt-4 grid grid-cols-2 gap-4 m-4 p-2'>
                {fieldData.map((field) => (
                    <div key={field.name}>
                        <h3 className='text-sm font-semibold'>{field.label}</h3>


                        { field.name === 'date' ? (
                            <input
                                type="date"
                                className='w-full text-black w-[90%] text-sm border-gray-200'
                                value={this.state.formData.healthassessment[field.name]}
                                readOnly={!isEditing}
                                onChange={(e) => this.handleInputChange(field.name.target.value)}
                                disabled={!isEditing}
                            />
                        ) : (
                            <textarea
                                className='w-full h-32 resize-none text-black w-[90%] text-sm border-gray-200'
                                value={this.state.formData.healthassessment[field.name]}
                                readOnly={!isEditing}
                                onChange={(e) => this.handleInputChange(field.name, e.target.value)}
                                disabled={!isEditing}
                            />
                        )}

                    </div>
                ))}
            </div>
            </div>

        </div>
        )
    }

    render() {

        const { activeTab, formData } = this.state;
   
        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
               
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Health Assessment</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                  
                    <div className='bg-slate-100 rounded w-92'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'background' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('background')}
                    >
                        Background
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'review' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('review')}
                    >
                        Review
                    </button>
                    </div>
                </div>
                <div className='tabContent col-span-6'>
                
                    {activeTab === 'background' &&  this.renderBackground()}
                    {activeTab === 'planning' && formData.haplanning && (
                    <Planning problems={formData.haplanning} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription} />
                    )}
                    {activeTab === 'review' && formData.hareviews && (        
                    <Review reviews={formData.hareviews} onReviewUpdate={this.handleReviewUpdate}  onVoiceInput={this.AITranscription} />
                
                    )}
                
                </div>
                <ToastContainer />
            </div>
        );
    }

}

    
export default HealthAssessment;