import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../utilities/NavigationContext';

import Menu from './NavComponents/Menu';
import Sidebar from './NavComponents/Sidebar';

import Practitioner from './PatientComponents/Practitioner';
import Profile from './PatientComponents/Profile';
import Team from './PatientComponents/Team';

import MentalHealth from './MainComponents/MentalHealth';
import CDM from './MainComponents/CDM';
import HealthAssessment from './MainComponents/HealthAssessment';
import Consult from './MainComponents/Consult';
import General from './MainComponents/General';
import Settings from './MainComponents/Settings';
const secretStorage = process.env.REACT_APP_SECRET_STORAGE

const patientDataInit = {
    profile: {},
    practitioner: {},
    teams: [],
    history : [],
    mhcp: {},
    mhformulation: {},
    mhsafety: {},
    mhproblems: [],
    mhrelapse: {},
    mhreviews: [],
    healthassessment: {},
    haplanning: [],
    hareviews: [],
    cdm: [],
    cdmreviews: []
};

const adminDataInit = {
    patientList: [],
    dashBoard: [],
    referrences : [], 
    contacts : [],
    practices : []
}

class Master extends React.Component  {
    constructor(props) {
        super(props);
        // const patientDataStorage = localStorage.getItem('patientData');
        // const encryptedAdmin = localStorage.getItem('admin');
        // const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
        // const adminDataStorage = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


        this.state = {
            adminData: adminDataInit,
            patientData: patientDataInit,
            type: "Consultation", 
            settingComponent: ""
        };
        this.saveAdminRef = React.createRef();
    }

    componentDidMount() {
        const patientDataStorage = localStorage.getItem('patientData');
        if (patientDataStorage) {
            this.setState({ patientData: JSON.parse(patientDataStorage) });
        }
        const encryptedAdmin = localStorage.getItem('admin');
        if (encryptedAdmin) {
            const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
            const adminDataStorage = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            this.setState({adminData: adminDataStorage})
        }
    }

    // Update patient data and save to localStorage
    loadPatientData = (patientData) => {
        this.setState({ patientData }, () => {
            localStorage.setItem('patientData', JSON.stringify(this.state.patientData));
        });
    }

    // Save patient data method, similar to updatePatientData
    savePatientData = (patientData) => {
        this.setState({ patientData }, () => {
            localStorage.setItem('patientData', JSON.stringify(this.state.patientData));
        });
    }

    changeComponent = (type) => {
        this.setState({type})
    }

    changeSettingComponent = (type) => {
        this.setState({settingComponent: type})
    }

    saveAdminData = (data) => {
        if (this.saveAdminRef.current) {
            this.saveAdminRef.current.saveAdminToLocalStorage(data); // Calling the child's function
          }
    }

    render() {
        const {type, patientData, adminData, settingComponent } = this.state;

        return (
        <div className='ml-10 grid grid-cols-6' style={{gridTemplateRows: "minmax(50px, auto) minmax(320px, auto) minmax(300px, auto)"}}>
            <Menu 
                loadPatientData={this.loadPatientData}
                patientData={this.state.patientData}
                type={this.state.type}        
                changeComponent={this.changeSettingComponent}   
                adminData={this.state.adminData}
                ref={this.saveAdminRef}
            />
            <Sidebar 
                component={this.changeComponent}
            />

            {type !== "Settings" &&  (
            <>
             <Profile 
                patientData={patientData}
                updatePatientData={this.savePatientData}
            />

            <Practitioner 
                patientData={patientData}
                updatePatientData={this.savePatientData}
                adminData={adminData}
                
            />
            <Team
                patientData={patientData}
                updatePatientData={this.savePatientData}
                adminData={adminData}
            />
            </>
            )}

            {type === "Consultation" && (
                <Consult patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "General" && (
                <General patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData} />
            )}
            {type === "CDM" && (
                <CDM patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Mental Health" && (
                <MentalHealth patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Health Assessment" && (
                <HealthAssessment patientData={patientData} adminData={adminData} updatePatientData={this.savePatientData}/>
            )}
            {type === "Settings" && (
                <Settings patientData={patientData}  updatePatientData={this.savePatientData} saveData={this.saveAdminData} adminData={adminData} settingComponent={settingComponent}/>
            )}
        </div>
        )
    }


}
const MasterWithNavigation = (props) => {
    const navigate = useNavigation();
    return <Master {...props} navigate={navigate} />;
};

export default MasterWithNavigation;
