import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { printTCA } from './PrintGPMP';

class TCAOptions extends Component {
  constructor(props) {
    super(props);
    const today = new Date().toISOString().slice(0, 10);
    this.state = {    
      dateOfPlan: today,
      reviewDate: "",
      dropdowns: [
        { value: '', number: '' },
        { value: '', number: '' },
        { value: '', number: '' },
        { value: '', number: '' },
        { value: '', number: '' },
      ],
      trimmedDataURL: null, // This will store the trimmed signature data
    };
  }

  handleDropdownChange = (index, event) => {
    const { dropdowns } = this.state;
    dropdowns[index].value = event.target.value;
    this.setState({ dropdowns });
  };

  handleNumberChange = (index, event) => {
    const { dropdowns } = this.state;
    const number = event.target.value;
    if (/^\d{0,2}$/.test(number)) { // Only allows up to 2 digits
      dropdowns[index].number = number;
      this.setState({ dropdowns });
    }
  };

  sendEmail = () => {
    var selectedTeam = this.state.dropdowns;
    const formData = this.props.formData;
    const patient = formData.patient;
    const practitioner = formData.practitioner;
    const teams = formData.teams;

    var formTCA = [];
    selectedTeam.map(item => {
      const associatedTeam = teams.find(team => team._id === item.value);  
      if (associatedTeam) {
        associatedTeam.visits = item.number;      
        formTCA.push(associatedTeam);
      }
    });

    // You can now include the signature image data if needed
    const signatureImage = this.state.trimmedDataURL;
    // Use the signatureImage with the formData if necessary
    printTCA(event, formTCA, patient, practitioner, signatureImage);
  };

  sigPad = {}; // Reference for SignatureCanvas

  clearSignature = () => {
    this.sigPad.clear();
    this.setState({ trimmedDataURL: null }); // Clear the saved signature
  };

  saveSignature = () => {
    this.setState({ trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png') });
  };

  handleSignatureEnd = () => {
    this.saveSignature(); // Automatically save the signature when the user stops drawing
  };

  print = () => {
    console.log(this.state.dropdowns);
  }

  render() {
    const { formData } = this.props;
    const allTeams = formData.teams;
    const professionsToInclude = ["Podiatrist", "Occupational Therapist", "Physiotherapist", "Speech Pathologist", "Dietitian", "Audiologist", "Chiropractor", "Exercise Physiologist", "Osteopath", "Psychologist", "Counsellor", "ATSI Worker", "Diabetes Educator", "Mental Health Nurse", "Occupational Therapist"];
    const teams = allTeams.filter(team => professionsToInclude.includes(team.profession));

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-auto">
          <h2 className="text-xl font-semibold m-10 text-center text-black">Team Care Arrangements</h2>
          {this.state.dropdowns.map((dropdown, index) => (
            <div key={index} className="flex items-center justify-between m-4">
              <select
                value={dropdown.value}
                onChange={(e) => this.handleDropdownChange(index, e)}
                className="p-2 border border-gray-300 rounded-md flex-grow mr-2 text-black"
              >
                <option className='text-black' value="">Select...</option>
                {teams.map((team, idx) => (
                  <option className='text-black' key={idx} value={team._id}>
                    {`${team.firstName} ${team.lastName} (${team.profession})`}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={dropdown.number}
                onChange={(e) => this.handleNumberChange(index, e)}
                className="w-12 p-2 border border-gray-300 rounded-md text-center text-black"
                maxLength="2"
              />
            </div>
          ))}
          
          {/* Signature Canvas */}
          <div className="mt-6">
            <p className='m-4'>Sign here</p>
            <div className='flex flex-row'>
              <SignatureCanvas
                ref={(ref) => { this.sigPad = ref; }}
                penColor="black"
                onEnd={this.handleSignatureEnd} // Automatically save on end
                canvasProps={{width: 500, height: 200, className: 'sigCanvas border border-gray-300 m-4'}}
              />
                 <button
                  onClick={this.clearSignature}
                  className="px-4 py-2 my-4 bg-slate-500 hover:bg-slate-600 text-white rounded-md transition duration-200"
                >
                  Clear
                </button>
            </div>
        
          </div>

          <div className="flex justify-center mt-4 gap-4">
            <button
              onClick={this.props.onClose}
              className="px-4 py-2 bg-slate-500 hover:bg-slate-600 text-white rounded-md transition duration-200"
            >
              Close
            </button>
            <button
              onClick={this.print}
              className="px-4 py-2 bg-sky-600 hover:bg-sky-700 text-white rounded-md transition duration-200"
            >
              Print
            </button>
            <button
              onClick={this.sendEmail}
              className="px-4 py-2 bg-sky-600 hover:bg-sky-700 text-white rounded-md transition duration-200"
            >
              Email
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TCAOptions;
