import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../../utilities/NavigationContext';
import axios from 'axios';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToPlan, textToReview } from '../../utilities/CDMAI';


import Planning from '../CDMComponents/Planning';
import Review from '../CDMComponents/Review';


const secretStorage = process.env.REACT_APP_SECRET_STORAGE;


function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    if (!Array.isArray(problems)) {
        return []; // Return an empty array or handle the error as needed
    }

    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        priority : "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    }));
};


function generateRandomDate(start, end) {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('T')[0]; // Format as yyyy-mm-dd
}

function addFieldsToProblems(problems) {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    // Ensure problems is an array
    if (problems.length > 0) {
        return problems.map(problem => ({
        ...problem,
        dateAdded: formattedDate,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        review: [],
        journey: (problem.journey || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        goal: (problem.goal || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        plan: (problem.plan || []).map(p => ({
            ...p,
            id: generateId(),
            dateAdded: formattedDate,
            status: "plan"
        }))
        }));
    }
  
}



// sample data
const problems = [
    {
        id: generateId(),
        problem: "Diabetes type-2",
        goal: [
            { id: generateId(), description: "Maintain HbA1C below 7%" },
            { id: generateId(), description: "Adopt healthy eating habits" }
        ],
        plan: [
            { id: generateId(), entry: "Start metformin 500mg daily", status: "doing" },
            { id: generateId(), entry: "Monitor blood glucose daily", status: "plan" },
            { id: generateId(), entry: "Referral to dietitian", status: "pending" }
        ],
        priority: "High",
        collapsed: true,
        review: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), notes: "HbA1C improved to 7.5% after 3 months" }
        ],
        journey: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "HbA1C 8%", type: "Pathology" },
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "Started metformin", type: "Event" }
        ]
    },
    {
        id: generateId(),
        problem: "COPD",
        goal: [
            { id: generateId(), description: "Improve exercise tolerance" },
            { id: generateId(), description: "Reduce shortness of breath" }
        ],
        plan: [
            { id: generateId(), entry: "Pulmonary rehabilitation program", status: "plan" },
            { id: generateId(), entry: "Start long-acting bronchodilator", status: "doing" },
            { id: generateId(), entry: "Smoking cessation support", status: "done" }
        ],
        priority: "Moderate",
        collapsed: true,
        review: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), notes: "Patient reports improved breathing after bronchodilator" }
        ],
        journey: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "FEV1 at 60%", type: "Pathology" },
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "Started pulmonary rehab", type: "Event" }
        ]
    },
    {
        id: generateId(),
        problem: "Ischaemic Heart Disease",
        goal: [
            { id: generateId(), description: "Prevent further cardiac events" },
            { id: generateId(), description: "Control cholesterol levels" }
        ],
        plan: [
            { id: generateId(), entry: "Continue statin therapy", status: "doing" },
            { id: generateId(), entry: "Cardiac rehabilitation program", status: "plan" },
            { id: generateId(), entry: "Monitor lipid profile regularly", status: "done" }
        ],
        priority: "High",
        collapsed: true,
        review: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), notes: "LDL cholesterol reduced to 2.0 mmol/L" }
        ],
        journey: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "Coronary angiogram results", type: "Imaging" },
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "Completed cardiac rehab", type: "Event" }
        ]
    },
    {
        id: generateId(),
        problem: "Parkinson's Disease",
        goal: [
            { id: generateId(), description: "Maintain mobility and function" },
            { id: generateId(), description: "Manage tremor and rigidity" }
        ],
        plan: [
            { id: generateId(), entry: "Adjust levodopa dose", status: "plan" },
            { id: generateId(), entry: "Referral to physiotherapy", status: "pending" },
            { id: generateId(), entry: "Regular neurologist follow-up", status: "doing" }
        ],
        priority: "Moderate",
        collapsed: true,
        review: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), notes: "Tremors reduced after dose adjustment" }
        ],
        journey: [
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "Started new medication regimen", type: "Report" },
            { id: generateId(), date: generateRandomDate(new Date(2022, 0, 1), new Date()), journey: "MRI brain results", type: "Imaging" }
        ]
    }  
    
];

const reviews = [
    {
        id: generateId(),
        date: "2023-02-10",
        review: "Patient reports improved breathing after starting long-acting bronchodilator. Pulmonary rehabilitation is ongoing."
    },
    {
        id: generateId(),
        date: "2023-04-15",
        review: "Exercise tolerance has improved, but patient still experiences shortness of breath during strenuous activities."
    },
    {
        id: generateId(),
        date: "2023-07-20",
        review: "Patient completed smoking cessation program, reports significant improvement in breathing and overall energy levels."
    },
    {
        id: generateId(),
        date: "2023-03-08",
        review: "HbA1C reduced to 7.5%. Patient adheres to metformin regimen and continues to monitor blood glucose daily."
    },
    {
        id: generateId(),
        date: "2023-05-14",
        review: "Dietary changes have positively impacted blood sugar control. Referral to dietitian pending."
    },
    {
        id: generateId(),
        date: "2023-06-25",
        review: "LDL cholesterol reduced to 2.0 mmol/L after continued statin therapy. Cardiac rehabilitation scheduled."
    },
    {
        id: generateId(),
        date: "2023-09-02",
        review: "Patient completed cardiac rehabilitation, reports improved stamina and fewer angina episodes."
    }
];

// baseline data
const patientData = {
    patient: {
        _id: '23lkjf',
        firstName: 'John',
        lastName: 'Doe',
        gender: 'male',
        ethnic: 'Caucasian',
        pronoun: 'he/him',
        dob: '1957-03-09T08:25:37.123Z',
        medicare: '',
        address1 : '',
        address2 : '',
        suburb : '',
        state : '',
        postcode: ''
    },
    practitioner: {},
    teams: [],
    history: [],
    todos: [],
    goals: [],
    reviews: [],
    journeys: [],
    tags: [],
    mhcp: {},
    mhformulation: {},
    mhsafety: {},
    relapsePrevention: {},
    mhproblems: [],
    mhreviews: [],
    healthassessment: {},
    haplanning: [],
    hareviews: [],
    cdm: [],
    cdmreviews: []
};


const systemPasteMessage = (userInput) => {
    const context = `
    Your task is to organise the provided information from userInput into an ordered format.
    Use British spelling throughout.
    Only provide the information requested; do not add or summarise.
    Format your response as a flat JSON object.

    The information to retrieve is as follows:
    - First name, last name, date of birth, address, suburb, postcode, mobile phone
    - List of allergies
    - List of medications
    - Active or inactive past history with the date. If there is additional information along with the history, create it as a journey list.
    - List of immunisations

    Return the information as a JSON object with the following structure:
    - patientData: {lastName: 'last name', firstName: 'first name', dob: 'date of birth', address: 'address', suburb: 'suburb', postcode: 'postcode', contact: 'mobile phone'}
    - allergies: [{agent: 'substance causing the allergy', reaction: 'reaction to the allergen, if any'}]
    - medications: [{name: 'name of medication', strength: 'dose of the medication, e.g., in mg', form: 'form of the medication, e.g., cap, tab, EC tab, syr', dose: 'prescribed dose, e.g., 1 tablet or 2 tablets', frequency: 'frequency of administration, e.g., once daily, twice a day, etc.'}]
    - pastHistory: [{date: 'date of the medical condition, format as yyyy-mm-dd; if the exact date is unknown, use "01" for both day and month', problem: 'description of the medical condition, e.g., anxiety, type-2 diabetes', journey: [{date: 'date of the related event', journey: 'description of the event, e.g., diabetic retinopathy, diabetic nephropathy'}]}]
    - immunisations: [{vaccine: 'name of the vaccine', date: 'date of immunisation, format as yyyy-mm-dd'}]

    For allergies, medications, pastHistory, and journey entries within pastHistory, there may be multiple entries.
    If any field is not provided, return it as an empty string ("").

    `;
    return `${context}\nUser: ${userInput}`;
}


class CDM extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            referrences: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            isEditing: false,
            //AI section
            isLoading: false,
            //active tab
            activeTab: 'planning',
           

        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
    }
    

    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => ({
            isEditing: !prevState.isEditing
        }));
    };

    handleInputChange = (key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                cdm: {
                    ...prevState.formData.cdm,
                    [key]: value
                }
            }
        }));
    };
    

    /////  AI SECTION

    // After receiving transcription from Voice
    AITranscription = async (text, toastId) => {
       
            // organising Plan
            toast.update(toastId, {
                render: 'Organising Plan..',
                autoClose: false,
                hideProgressBar: false
            });

            var formData = this.state.formData;
            var adminData = this.state.adminData;
            var referrences = adminData.referrences
            var respond2 = await textToPlan(text, formData.cdm, referrences);
            var plan = addFieldsToProblems(respond2);
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    cdm: plan
                },
            }), this.updatePatientData);
    
            // writing review
            toast.update(toastId, {
                render: 'Writing Review...',
                autoClose: false,
                hideProgressBar: false
            });
            var respond3 = await textToReview(text, formData.cdmreviews);
      
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    cdmreviews: respond3,
                }
            }), this.updatePatientData); 
    
    
            // all done
            toast.update(toastId, {
                render: 'All task done!',
                type: toast.TYPE.SUCCESS,
                autoClose: 1000,  
                hideProgressBar: false
            });
    };

    //Smart paste
    handleSubmitPaste = async (event) => {
  
        event.preventDefault();
        const smartPaste = this.state.smartPaste;

        const processedInput = systemPasteMessage(smartPaste);
        this.setState({isLoading: true})
        try {
            const res = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                model: 'gpt-4-turbo',
                messages: [{ role: 'user', content: processedInput }],
                },
                {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
                },
                }
            );
        
            let messageContent = res.data.choices[0].message.content;
    
            //checking type of respond injson
            if (messageContent.startsWith('Here is the report in JSON format:')) {
                const prefixLength = 'Here is the report in JSON format:\n\n'.length;
                messageContent = messageContent.substring(prefixLength).trim();
            }
        
            if (messageContent.startsWith('```json')) {
                messageContent = messageContent.substring(7);
            }
        
            if (messageContent.endsWith('```')) {
                messageContent = messageContent.substring(0, messageContent.length - 3);
            }
            var respond = JSON.stringify(messageContent);
            this.setState({input: respond})
            this.handleSubmit(event)
            // this.setState({AIrespond : JSON.parse(messageContent)})
            // this.setState({isLoading: false})
            //update the form
            // this.AItoForm(JSON.parse(messageContent));
        } catch (error) {
            console.error('Error fetching the response:', error);
            // this.setState({isLoading: false})
        }
    };

    ////// UPDATE FROM CHILD COMPONENT 

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                cdm: updatedProblems
            },
        }), this.updatePatientData);
    };

    handleReviewUpdate = (updatedReviews) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            mhreviews: updatedReviews,
          },
        }), this.updatePatientData);
    };
    
    // update message
    updateMessage = (message) => {
        this.setState({message: message})
    }

    render() {

        const { formData, activeTab } = this.state;
       
        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
              
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Chronic Disease Management Plan</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                    <div className='bg-slate-100 rounded w-70'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'review' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('review')}
                    >
                        Review
                    </button>
                    </div>
                </div>
      
                
                <div className='tabContent col-span-6'>
   
                    {activeTab === 'planning' && (
                    <Planning problems={formData.cdm} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription}  />
                    )}
                    {activeTab === 'review' && formData.cdmreviews && (
                    <Review reviews={formData.cdmreviews} onReviewUpdate={this.handleReviewUpdate}  onVoiceInput={this.AITranscription}  />
                    )}
                
                </div>
                <ToastContainer />
                
            </div>
        );
    }

}

export default CDM;
