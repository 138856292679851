import { AITextToObject, AITextToText } from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};



const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};


// Prompts
const instructionTextToForm = `
    You are a medical practitioner responsible for compiling comprehensive medical notes. Follow these instructions carefully:

    - Use British spelling throughout.
    - Provide only the information requested; do not add or summarize.
    - Format your response as a flat JSON object with no nested lists or objects, except in the 'problems' field.
    - Each field should be concise yet thorough, addressing all relevant details. If no information is provided, use the default values specified below.

    Please fill in the following fields:

    - medicalhistory: Describe the patient's current medical issues. Default is "No current medical issues reported."
    - pastmedical: List the patient's past medical conditions. Default is "None."
    - allergies: Include all known allergies and reactions, with each entry on a separate line. Default is "No known allergies."
    - familyhistory: Detail the patient's family medical history. Default is "None."
    - alcohol: Document the patient's alcohol consumption habits. Default is "No alcohol consumption reported."
    - smoking: Provide the patient's smoking history. Default is "No smoking history reported."
    - substance: Include details of any substance use (e.g., cannabis, heroin, cocaine, etc.). Default is "No substance use reported."
    - medications: List current medications and dosages, each on a separate line. Default is "No current medications."
    - medicationarrangement: Information about the prescription provider, pharmacy, use of Webster packs, and the last community pharmacy review. Default is "No specific medication arrangement details provided."
    - vision: Include any issues related to vision, such as glasses, surgeries, or visual aids. Default is "No vision issues reported."
    - hearing: Document the patient's hearing status, including the use of hearing aids. Default is "No hearing issues reported."
    - fallandhomesafety: Record any falls in the past 12 months, use of safety aids like grab rails, and personal safety alarms. Default is "No falls or safety concerns reported."
    - skin: Describe any skin-related conditions. Default is "No skin conditions reported."
    - feet: Document any foot-related issues, including toenail problems, gait, footwear, and podiatrist visits. Default is "No foot issues reported."
    - nutrition: Detail the patient's nutritional habits, including meal preparation, portion sizes, and use of dentures or PEG. Default is "No nutritional issues reported."
    - oralhealth: Provide information on dental health, including the use of dentures, implants, and any oral conditions. Default is "No oral health issues reported."
    - cognition: Address any cognitive issues, such as memory, comprehension, or processing speed. Default is "No cognitive issues reported."
    - mentalhealth: Record any mental health concerns, including mood disturbances, delusions, or suicidal thoughts. Default is "No mental health issues reported."
    - urineContinence: Document urinary continence status, use of pads, and any impact on daily life. Default is "No urinary continence issues reported."
    - faecalContinence: Record fecal continence status, use of pads, and any impact on daily life. Default is "No fecal continence issues reported."
    - mobility: Describe the patient's mobility status, including the use of aids and access to community travel. Default is "No mobility issues reported."
    - activity: List the patient's hobbies, social activities, and club memberships. Default is "No activities or hobbies reported."
    - social: Provide details on the patient's social support network, including friends, family, and social groups. Default is "No social support network reported."
    - housing: Describe the patient's living arrangements (e.g., own home, residential facility). Default is "No specific housing arrangements reported."
    - legal: Document any legal matters, including wills and power of attorney. Default is "No legal issues reported."
    - finance: Provide information on how the patient's finances are managed, including decision-makers. Default is "No financial management issues reported."
    - driving: Detail the patient's driving status, including any assessments and use of alternative transportation. Default is "No driving issues reported."
    - advancedcaredirective: Include information on the existence and details of an advanced care directive. Default is "No advanced care directive reported."
    - teamcare: List the healthcare team involved in the patient's care, including specialists and allied health professionals. Default is "No healthcare team reported."
    - services: Document any external services involved in the patient's care (e.g., aged care, community services). Default is "No external services involved."
    - examination: Provide details of any recent medical examinations. Default is "No recent examinations reported."
    - surveillance: Record any future surveillance plans (e.g., cancer screenings). Default is "No surveillance plans reported."
    - vaccination: List any vaccinations that have been administered or are pending. Default is "No vaccinations reported."
   

    Instructions for handling data:
    - For problems, identify any potential issues/problems, and create a separate entry for each problem. Do not combine multiple problems into a single entry. Keep priority as "Low" unless otherwise specified.
    - If a goal or plan contains multiple actions or recommendations, create a separate entry for each action in the 'goal' or 'plan' array. Do not combine multiple actions into a single entry.
    - Fill any fields with empty [] if there's no entry.
    - Do not make a summary at the end.

    Ensure your response is accurate, well-organized, and adheres strictly to the requested format.
    `;

const instructionTextToPlan = `
    You are a medical practitioner. Your job is to identify list of problems from the input.
    Ensure British spelling is used throughout.
   Identify any potential issues/problems based on the input. 
   Return as JSON object.
   Create a separate entry for each problem and avoid combining multiple problems into a single entry. 
   Each problem should be structured as follows:
           {
               id: null,
               problem: "Problem description",
               notes: "Details about the problem if applicable",
               goal: [
                   {
                       id: null,
                       description: "Goal description if applicable"
                   }
               ],
               plan: [
                   {
                       id: null,
                       entry: "Plan description if applicable",
                       status: "plan"
                   }
               ]
           }
   `
   
const instructionTextToReview = `
   You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:
   
   Use short sentences and simple points.
   Organize the notes in this specific order:
   History: Summarize key points of the patient's history concisely.
   Examination: Include details of any physical or mental state examination conducted, if applicable.
   Assessment: List any identified problems, issues, or diagnoses. Use ICD 10 diagnosis if possible. 
   Management: Outline any plans or actions to be taken, if applicable.Do not provide any information if not provided.
   Write the content directly underneath each title. If no content is provided for a section, skip that title. Do not include anything outside the input.
   `

   
// text to Form
export const textToForm = async (text, formData) => {


    // Creating system command
    const data = await AITextToObject(instructionTextToForm, text, formData);

    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const filledForm = {
        medicalHistory: data.medicalhistory,
        pastMedical: data.pastmedical,
        allergies: data.allergies,
        familyHistory: data.familyhistory,
        alcohol: data.alcohol,
        smoking: data.smoking,
        substance: data.substance,
        medications: data.medications,
        medicationArrangement: data.medicationarrangement,
        vision: data.vision,
        hearing: data.hearing,
        fallAndHomeSafety: data.fallandhomesafety,
        skin: data.skin,
        feet: data.feet,
        nutrition: data.nutrition,
        oralHealth: data.oralhealth,
        cognition: data.cognition,
        mentalHealth: data.mentalhealth,
        urineContinence: data.urineContinence,
        faecalContinence: data.faecalContinence,
        mobility: data.mobility,
        activity: data.activity,
        social: data.social,
        housing: data.housing,
        legal: data.legal,
        finance: data.finance,
        driving: data.driving,
        advancedCareDirective: data.advancedcaredirective,
        services: data.services,
        examination: data.examination,
        surveillance: data.surveillance,
        vaccination: data.vaccination, 
        date: formattedDate
    };

    if (filledForm) {
        return filledForm
    } else {
        console.log("Error")
        return null;
    }
}

// text to plan
export const textToPlan = async (text, formData) => {
    const data = await AITextToObject(instructionTextToPlan, text, formData);
    const plan = regenerateIds(data);
    return plan;
}

// text to review
export const textToReview = async (text, reviews) => {
    const data = await AITextToText(instructionTextToReview, text, reviews);
    const reviewData = [...(reviews || [])];
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const newReview = { date: formattedDate, review: data };
    
    reviewData.push(newReview);
    return reviewData;

}