import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Consultation from '../images/Consultation.png';
import Concept from '../images/concept.png';
import Tools from '../images/Tools.png';
import CDM from '../images/CDM.png';
import Journey from '../images/journey.png';
import Library from '../images/library.png';
import MHCP from '../images/MHCP1.jpg';
import Platform from '../images/platform.png';

//import sections
import {GridLeftImage, GridRightImage, FeatureListRow, FeatureListRight, FeatureListLeft, FeatureListTop, BentoFeatures } from './LandingComponents/Features';
import { HeroRightImage, HeroLeftImage, HeroCenterImage, HeroCenterText, HeroCenterVideo } from './LandingComponents/Hero';
import { SocialRow } from './LandingComponents/Social';

import { useAuth } from '../utilities/AuthContext'; 

const socialComments = [
  {from:"Dr Xiaoting Ge", comment : `Increasing complexity and time consumption overwhelmed by competing tasks`},
  {from:"Dr Mohammed Vakil", comment: "Chronic disease is a substantial part of our management in general practice. A streamlined process to manage chronic conditions will help GPs manage the conditions in a comprehensive manner."},
  {from:"Dr Chris Hinton", comment: "Chronic disease managed by team of GP and nurses. Some case overlap. Some challenges in appointment scheduling and particularly delay in initiating first consult."},
  {from:"Dr Huseyin Ibrahim", comment: "Fear of further loss of income by Medicare. Making CDM efficient and having nurses agreeable to this"},
  {from:"Dr Krutik Patel", comment: "Difficult to differentiate from history and physical examination only"},
  {from:"Dr Faisal Khan", comment: "Like to have more comprehensive approach , minimal time consuming"},
  {from:"Dr Andrew Harris", comment: "Like to have more comprehensive approach , minimal time consuming"},
  {from:"Dr Ahmed Al-Obaidi", comment: "Inefficiencies with templates - repeatedly entering the same info - poor text formatting capabilities - lack of streamlined process (eg suggesting PSA check for every male > 50 yrs)"},
  {from:"Dr Evangeline Marzan", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Simon Luk", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Kapil Maharaj", comment: "The major issue is that if patient has multiple chronic illness, sometimes it's quite easy to lose track of the goals / issues we have to deal with."},
  {from:"Dr Timothy Vo", comment: `I've recently reviewed my care plans and care plans process. What Ive found is that the care plans were inadequate. They really did not achieve what I had in mind, which was to really make a positive difference in the patient's health and their chronic conditions. We have a nurse that does the care plans, and they were very generic. I managed to change that. They were still not satisfactory... So wordy, the process on Best practice was rubbish.... drawn out, not user friendly, not efficient... if you wanted to do a care plan that was indivualised. I've started doing them myself from start to finish and using a letter as my GPMP/TCA, rather than the "GPMP/TCA" button in Best Practice. This is so much better. Care plans are cleaner, easier to read, simpler. So now looking to improve efficiency in this area including the future follow ups.`},

]

const video = "https://www.youtube.com/embed/aj9KBA5k8hA?si=9qSsr6dmNN5AhxMa";

function Home() {
  const navigate = useNavigate();
  const [auth] = useAuth(); 

  const problemStatement = () => {

    return socialComments.map((item,index) => {
      return (
          <div className='p-2 m-2 rounded-xl bg-white shadow-lg w-[320px] h-auto border-l-2 border-slate-400'>
            <p className='text-left text-sm p-2'>"{item.comment}"</p>
            <p className='text-left text-xs p-2'>{item.from}</p>
          </div> 
      )
    });
  
  
  }

  const title = '3 Minutes Care Plan for GP';
  const subtitle = 'AI-platform to generate actionable care plan documentation swiftly'

  const Features = [
    {title: "Create Care Plan Swiftly", description: "Streamline Care Plan generation from voice to plan to print in seconds", image: Concept},
    {title: "Project Manage Patient", description: "Creative and speed up processing time", image: Consultation},
    {title: "Patient Dashboard", description: "Never let patient out of track.", image: Tools},
    {title: "Automated Form Generation", description: "Form filled automatically just with one click", image: Concept}
  ]

  const Testimonials = [
    {title: "Increasing complexity and time consumption overwhelmed by competing tasks", author: 'Dr Linda'},
    {title: "Like to have more comprehensive approach , minimal time consuming", author : "Dr John King"},
  ]


  const features = [{
    title : "AI-Driven Care Plan",
    notes : `Effortlessly create multiple care plans with our AI-driven platform.
 
    Automatically fill out forms, create list of problems, develop action plans, and review notes after each dictation or consultation.`,
    image: Platform
  }, {
    title : "Kanban Style",
    notes : `Leverage Kanban-style project management to visualise care plans and easily track patient progress.

    Create and update plans effortlessly, either manually or through voice commands. `,
    image : MHCP
  }, {
    title : "Patient Progress", 
    notes : `Review and update patient progress seamlessly with AI-powered voice assistance or manual entry.

    Get a clear, instant overview of patient progress.`,
    image : Journey
  }, {
    title : "Care Library",
    notes : `Multiple goals and plans are available for selection when creating a new plan.

    Use Smart Paste to let AI automatically build the library from the provided text. `,
    image : Library
  }
]

const benefits = [
  {
    title: "Enhanced Efficiency",
    notes: "Automate admin tasks with AI-powered Voice Input, Smart-Paste, Smart-Scan and AI-agents.",
    image: CDM
  },
  {
    title: "Improved Care Visualisation",
    notes: "Get a clear, visual overview of care plans."
  },
  {
    title: "Streamlined Patient Management",
    notes: "Coordinate and monitor patient care efficiently."
  },
  {
    title: "Comprehensive Care Library",
    notes: "Reuse a curated library for quick, personalized care."
  },
  {
    title: "Data-Driven Insights",
    notes: "Use AI to analyze data and optimize care strategies."
  },
  {
    title: "Enhanced Collaboration",
    notes: "Enable seamless collaboration with up-to-date info."
  }
];





  return (

      <div className="bg-gradient-to-tr from-sky-200">
      <HeroCenterVideo title={title} subtitle={subtitle} video={video} />

      <GridRightImage title={features[0].title} subtitle={features[0].notes}  image={features[0].image} />
      <GridLeftImage title={features[1].title} subtitle={features[1].notes}  image={features[1].image}/>
      
      <BentoFeatures title="Benefits" content={benefits}/>
      <GridRightImage title={features[2].title} subtitle={features[2].notes}  image={features[2].image} />
      <GridLeftImage title={features[3].title} subtitle={features[3].notes}  image={features[3].image} />
    
      {/* <FeatureListRow  title={title} subtitle={subtitle}  features={Features} /> */}
      {/* <FeatureListRight  title={title} subtitle={subtitle}  features={Features} /> */}
      {/* <FeatureListLeft  title={"Multiple Care Plan At Once"} subtitle={"Fill up forms, create care plan, and notes in one consultation"}  features={Features} /> */}
      {/* <FeatureListTop  title={title} subtitle={subtitle}  features={Features} /> */}
      </div>
      //  <div className='flex justify-center mt-20 h-auto xs:mt-0'>
      //   <div className='flex flex-col w-auto text-center bg-slate-50 p-10 text-md rounded-lg shadow-lg border border-slate-200' >
      //   <div className=' text-xs'>
      //     <p className='text-lg font-semibold'>Thank you for your interest!</p><br></br>
      //     <p>
      //     While we're still fine-tuning our platform, you're welcome to explore our prototypes and use the psychometric tools. 
      //     </p>

      //     <p>
      //       Click the left icon or thumbnail below to try our Complex Consultations and Chronic Care prototypes. 
      //     </p>
      //     <p>
      //       Click Tools above or thumbnail below to use the psychometric tools.
      //     </p> 
    
      //     <p>We value your feedback, comments or feature request; simply click the comments pane on the right side of the screen</p>
      //     <br></br>
      //     <p>

      //     We'll notify you as soon as we're ready for launch. Thanks for your patience!
      //     </p>
      //   </div>

      //   <div className='flex flex-row p-4 xs:flex-col' >
      //       <div className='m-4 bg-white rounded p-4 shadow-lg w-[330px] hover:bg-slate-50 cursor-pointer sm:w-[220px]' onClick={() => {navigate('/plan')}}>
      //           <h1 className='text-lg font-bold text-sky-800'>Chronic Disease Care</h1>
      //           <div className="flex justify-center">
      //           <img src={Concept} alt="Consultation Image" className="w-[300px] h-[200px] object-contain"/>
      //           </div>
      //           <h2 className='m-2 text-sm'>Discover our prototype for Chronic Disease Care, employing a Kanban Board style tailored for patients managing multiple chronic conditions, necessitating tracking across various activities and goal assessments.</h2>
      //       </div>
      //       <div className='m-4 bg-white rounded p-4 shadow-lg w-[330px] hover:bg-slate-50 cursor-pointer sm:w-[220px]' onClick={() => {navigate('/Consultation')}}>
      //           <h1 className='text-lg font-bold text-sky-800'>Complex Consultations</h1>
      //           <div className="flex justify-center">
      //           <img src={Consultation} alt="Consultation Image" className="w-[330px] h-[200px] object-contain"/>
      //           </div>
      //           <h2 className='m-2 text-sm'>Prototype the Complex Consultation Journey for a patient presenting with multiple issues requiring follow-up to ensure nothing is overlooked.</h2>
      //       </div>
      //       <div className='m-4 bg-white rounded p-4 shadow-lg w-[330px] hover:bg-slate-50 cursor-pointer sm:w-[220px]' onClick={() => {navigate('/tools')}}>
      //           <h1 className='text-lg font-bold text-sky-800'>Psychometric Tools</h1>
      //           <div className="flex justify-center">
      //           <img src={Tools} alt="Consultation Image" className="w-[330px] h-[200px] object-contain"/>
      //           </div>
      //           <h2 className='m-2 text-sm'>Commence utilising Psychometric Tools. Patients can independently complete them at home, proceeding at their preferred pace and in the comfort of their own environment. Instructions on how to utilise these tools will be provided in your email.</h2>
      //       </div>

      //   </div>
      //   <h1 className='font-semibold text-lg mt-10'>Comments</h1>
      //   <h2 className='text-sm p-4'>Here are a few comments from GPs regarding difficulties with managing chronic diseases.</h2>
      //   <div className='flex flex-wrap p-4 justify-center w-[1100px] xs:w-[400px] sm:w-[800px]'>
         
      //     {problemStatement()}
      //   </div>
      //   </div>
  
      // </div>

  );
}

export default Home;
