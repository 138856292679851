import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Tesseract from 'tesseract.js';
import CryptoJS from 'crypto-js';

import mammoth from 'mammoth';
import { PDFDocument } from 'pdf-lib';
import * as XLSX from 'xlsx';
import { pptxParser } from 'pptx-parser';


//   const chunkSize = chunkSizeMB * 1024 * 1024; // Convert MB to bytes
//   const totalChunks = Math.ceil(blob.size / chunkSize);

//   let start = 0;
//   const chunks = [];

//   for (let i = 0; i < totalChunks; i++) {
//     const end = Math.min(start + chunkSize, blob.size);
//     const chunk = blob.slice(start, end);
//     console.log(`Chunk ${i + 1}: size = ${chunk.size} bytes`);
//     chunks.push(chunk);
//     start = end;
//   }

//   return chunks;
// };

// export const transcribeAudio = async (blob) => {
//   const chunks = splitAudioBlob(blob, 25); 
//   const transcriptions = [];

//   for (const chunk of chunks) {
//     const formData = new FormData();
//     formData.append('file', chunk);
//     formData.append('model', 'whisper-1');
//     formData.append('language', 'en');

//     try {
//       const transcribeRes = await axios.post(
//         'https://api.openai.com/v1/audio/transcriptions',
//         formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
//           },
//         }
//       );
//       transcriptions.push(transcribeRes.data.text);
//     } catch (error) {
//       console.error('Error transcribing audio chunk:', error);
//     }
//   }

//   // Combine all transcriptions
//   return transcriptions.join(' ');
// };

export const transcribeAudio = async (blob) => {
  if (!blob) {
    console.error('No audio to transcribe.');
    return;
  }

  try {
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('model', 'whisper-1');
    formData.append('language', 'en');
    formData.append('temperature', '0.1');  

    const transcribeRes = await axios.post(
      'https://api.openai.com/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );
    return transcribeRes.data.text;
  } catch (error) {
    console.error('Error transcribing audio:', error);
    return 'Error transcribing audio.';
  }
};

// Helper function to extract text from DOCX files
const extractDocxText = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const result = await mammoth.extractRawText({ arrayBuffer });
  return result.value; // Extracted plain text
};

// Helper function to extract text from PDF files
const extractPdfText = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const pages = pdfDoc.getPages();
  let extractedText = '';

  for (const page of pages) {
    const textContent = await page.getTextContent();
    textContent.items.forEach(item => {
      extractedText += item.str + ' ';
    });
  }

  return extractedText.trim(); // Trim any excess whitespace
};

// Helper function to extract text from image files (JPG, PNG) using Tesseract.js
const extractImageText = async (blob) => {
  const result = await Tesseract.recognize(blob, 'eng');
  return result.data.text; // Extracted text from the image
};

// Helper function to extract text from RTF files
const extractRtfText = async (blob) => {
  const text = await blob.text(); // RTF files are often plain text or simple markup
  return text;
};

// Helper function to extract text from PPTX files
const extractPptxText = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const text = await pptxParser(arrayBuffer); // Extracted text from the presentation
  return text;
};

// Helper function to extract text from XLSX files
const extractXlsxText = async (blob) => {
  const arrayBuffer = await blob.arrayBuffer();
  const workbook = XLSX.read(arrayBuffer, { type: 'array' });
  let extractedText = '';

  workbook.SheetNames.forEach(sheetName => {
    const sheet = workbook.Sheets[sheetName];
    extractedText += XLSX.utils.sheet_to_csv(sheet); // Convert each sheet to CSV text
  });

  return extractedText;
};


export const readFile = async (file) => {
  if (!file) {
    console.error('No file to process.');
    return;
  }

  let extractedText = '';

  try {
    const fileType = file.type;

    if (fileType === 'application/pdf') {
      // PDF extraction
      extractedText = await extractPdfText(file);
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // DOCX extraction
      extractedText = await extractDocxText(file);
    } else if (fileType.startsWith('image/')) {
      // Image extraction
      extractedText = await extractImageText(file);
    } else if (fileType === 'application/rtf') {
      // RTF extraction
      extractedText = await extractRtfText(file);
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      // PPTX extraction
      extractedText = await extractPptxText(file);
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      // XLSX extraction
      extractedText = await extractXlsxText(file);
    } else {
      console.error('Unsupported file type:', fileType);
      return 'Unsupported file type.';
    }

    // Once the text is extracted, send it to OpenAI
    const messages = [
      {
        role: 'user',
        content: `Please analyze the following file content: ${extractedText}`,
      },
    ];

    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: messages,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`, // Your OpenAI API key
        },
      }
    );

    // Return the response from OpenAI
    return response.data.choices[0].message.content;

  } catch (error) {
    console.error('Error processing file:', error);
    return 'Error processing file.';
  }
};

// Function to decode Base64 encoded text
// const decodeBase64 = (encodedText) => {
//   try {
//     const decodedText = atob(encodedText); // atob() decodes a Base64-encoded string
//     console.log("Decoded text (Base64):", decodedText);
//     return decodedText;
//   } catch (error) {
//     console.error("Error decoding Base64:", error);
//     return null; // Return null if decoding fails
//   }
// };

// // Function to decrypt AES-encrypted text
// const decryptAES = (encryptedText, key, iv) => {
//   try {
//     const bytes = CryptoJS.AES.decrypt(encryptedText, CryptoJS.enc.Utf8.parse(key), {
//       iv: CryptoJS.enc.Utf8.parse(iv),
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7,
//     });
//     const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
//     console.log("Decrypted text (AES):", decryptedText);
//     return decryptedText;
//   } catch (error) {
//     console.error("Error decrypting AES:", error);
//     return null; // Return null if decryption fails
//   }
// };

// export const readFile = async (file, decryptionKey = null, decryptionIV = null) => {
//   if (!file) {
//     console.error('No file to process.');
//     return;
//   }

//   const fileType = file.type;

//   try {
//     if (fileType === 'application/pdf') {
//       // Handle PDF file with pdfjs-dist
//       const arrayBuffer = await file.arrayBuffer();
//       const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;

//       let extractedText = '';

//       // Loop through each page and render as an image
//       for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
//         const page = await pdf.getPage(pageNum);
//         const viewport = page.getViewport({ scale: 2 }); // Adjust scale as needed

//         // Create a canvas to render the page
//         const canvas = document.createElement('canvas');
//         const context = canvas.getContext('2d');
//         canvas.height = viewport.height;
//         canvas.width = viewport.width;

//         // Render the page into the canvas
//         await page.render({ canvasContext: context, viewport }).promise;

//         // Convert the canvas to an image (Base64 URL)
//         const imageDataUrl = canvas.toDataURL();

//         // Use Tesseract.js to perform OCR on the image
//         const result = await Tesseract.recognize(imageDataUrl, 'eng', {
//           logger: (m) => console.log(m), // Log progress
//         });

//         extractedText += result.data.text + '\n'; // Append the OCR text from each page
//       }

//       console.log('Extracted text from PDF:', extractedText);
//       return extractedText;

//     } else if (fileType.startsWith('image/')) {
//       // Handle image file (JPEG, PNG, etc.)
//       const result = await Tesseract.recognize(file, 'eng', {
//         logger: (m) => console.log(m), // Log progress
//       });

//       const extractedText = result.data.text;
//       console.log('Extracted text from image:', extractedText);

//       return extractedText;

//     } else if (fileType === 'text/plain') {
//       // Handle plain text files
//       const text = await file.text();
//       console.log('Extracted text from TXT file:', text);

//       // Try to decode Base64
//       let decodedText = decodeBase64(text);
//       if (decodedText) {
//         return decodedText;
//       }

//       // If Base64 decoding fails, try AES decryption (if key and IV are provided)
//       if (decryptionKey && decryptionIV) {
//         const decryptedText = decryptAES(text, decryptionKey, decryptionIV);
//         if (decryptedText) {
//           return decryptedText;
//         }
//       }

//       return text; // If no decoding or decryption is applied, return the raw text

//     } else {
//       console.error('Unsupported file type:', fileType);
//       return 'Unsupported file type.';
//     }

//   } catch (error) {
//     console.error('Error processing file:', error);
//     return 'Error processing file.';
//   }
// };



export const LLMTextToJSON = async (processedInput) => {
  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    if (messageContent.includes('...')) {
      messageContent = messageContent.replace(/.../g, '').trim();
    }


    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }

    console.log(messageContent)
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};


export const LLMTextToText = async (processedInput) => {
  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};

export const writeNotes = async (instruction, input) => {
  var respond = await LLMTextToText(instruction);
  return respond;
}

export const sortingText = async (input) => {
    var respond = await LLMTextToJSON(input);
    return respond;
}


export const AITextToObject = async (instruction, input) => {

  var processedInput = `${instruction}\nUser: ${input}}`;

  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
};


export const AITextToText = async (instruction, text, formData) => {

  var processedInput = `${instruction}\nUser: ${text}`;

  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4-turbo',
        messages: [{ role: 'user', content: processedInput }],
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );

    let messageContent = res.data.choices[0].message.content;

    return messageContent;
  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // or you can return an empty string, or rethrow the error
  }
};


export const complexInput = async(processedInput) => {
  
  const input = JSON.stringify(processedInput);
  console.log(input)
  try {
    const res = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4o-mini',
        messages: [
          { role: 'system', content: "You are a medical assistant AI." },
          { role: 'user', content: input }
        ]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI}`,
        },
      }
    );
    console.log(res)

    let messageContent = res.data.choices[0].message.content;
    console.log(messageContent)
    // Checking for JSON content and cleaning up formatting artifacts
    if (messageContent.includes('```json')) {
      messageContent = messageContent.replace(/```json/g, '').trim();
    }

    if (messageContent.includes('```')) {
      messageContent = messageContent.replace(/```/g, '').trim();
    }

    // Handling the prefix "Here is the report in JSON format:" or similar
    const jsonPrefix = 'Here is the report in JSON format:';
    if (messageContent.startsWith(jsonPrefix)) {
      messageContent = messageContent.substring(jsonPrefix.length).trim();
    }
    // Attempt to parse the cleaned message content
    const jsonResponse = JSON.parse(messageContent);
    return jsonResponse;

  } catch (error) {
    console.error('Error fetching the response:', error);
    return null; // Return null or handle the error as you see fit
  }
}