import React from 'react';
import { withNavigation } from '../../utilities/NavigationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const formatDateForDisplay = (dateString) => {
  if (!dateString) return "";

  const dateObject = new Date(dateString);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${day}/${month}/${year}`; // Format as 'DD-MM-YYYY' for display
}

const formatDateForInput = (dateString) => {
  const dateObject = new Date(dateString);
  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObject.getFullYear();
  return `${year}-${month}-${day}`; // Format as 'YYYY-MM-DD' for input field
}

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      editableHistory: {},
      editRowId: null, // To track which row is being edited
      editSection: null, // To track which section (table) is being edited
    };
  }

  componentDidMount() {
    this.fetchPatientHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history !== this.props.history) {
      this.setState({ editableHistory: { ...this.props.history } });
    }
  }

  fetchPatientHistory = async () => {
    const history = this.props.history;
    this.setState({
      editableHistory: history,
    });
  };

  toggleEdit = () => {
    this.setState(prevState => ({
      isEditing: !prevState.isEditing
    }));
  };

  handleSave = async () => {
    this.props.updateHistory(this.state.editableHistory);
    toast.success(`History updated successfully`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideProgressBar: false
    });
    this.setState({ editRowId: null, editSection: null }); // Exit editing mode after saving
  };

  handleDeleteRow = (section, rowId) => {
    const updatedHistory = { ...this.state.editableHistory };
    updatedHistory[section] = updatedHistory[section].filter(item => item.id !== rowId);
    this.setState({ editableHistory: updatedHistory });
  };

  handleRowChange = (section, rowId, field, value) => {
    const updatedHistory = { ...this.state.editableHistory };
    const row = updatedHistory[section].find(item => item.id === rowId);
    if (row) {
      row[field] = value;
      this.setState({ editableHistory: updatedHistory });
    }
  };

  handleAddRow = (section) => {
    const newId = Date.now(); // Generate a unique ID for new rows
    const newRow = { id: newId, date: '', condition: '', details: '' }; // Adjust as needed for different sections
    const updatedHistory = { ...this.state.editableHistory };
    updatedHistory[section] = [...updatedHistory[section], newRow];
    this.setState({ editableHistory: updatedHistory, editRowId: newId, editSection: section });
  };

  renderTable(data, headers, section) {
    const { editRowId, editSection } = this.state;
  
    return (
      <div>
        <button
          onClick={() => this.handleAddRow(section)}
          className="bg-sky-600 hover:bg-sky-700 text-white font-bold py-1 px-4 m-2 rounded float-right"
        >
          Add
        </button>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-sky-600 hover:bg-sky-800 text-white">
              {headers.map((header, index) => (
                <th
                  key={index}
                  className="py-2 px-4 border-b"
                  style={header === 'Date' ? { width: '10ch' } : header === 'Actions' ? { width: '3ch' } : {}}
                >
                  {header}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr
                key={row.id}
                className="border-b"
                onDoubleClick={() => this.setState({ editRowId: row.id, editSection: section })}
              >
                {Object.keys(row).map((field, cellIndex) => (
                  <td key={cellIndex} className={`py-2 px-4 border-b ${field === 'id' ? 'hidden' : ''}`}>
                    {editRowId === row.id && editSection === section ? (
                      field === 'date' ? (
                        <input
                          type="date"
                          value={formatDateForInput(row[field])}
                          onChange={(e) => this.handleRowChange(section, row.id, field, e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') this.handleSave();
                          }}
                          className="w-full border border-slate-200"
                          style={{ width: '10ch' }}
                        />
                      ) : (
                        <input
                          type="text"
                          value={row[field]}
                          onChange={(e) => this.handleRowChange(section, row.id, field, e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') this.handleSave();
                          }}
                          className="w-full border border-slate-200"
                        />
                      )
                    ) : (
                      field === 'details' && Array.isArray(row[field]) ? (
                        <ul className="list-disc ml-4">
                          {row[field].map((detail, detailIndex) => (
                            <li key={detailIndex}>
                              <span>{formatDateForDisplay(detail.date)} - {detail.journey}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        field === 'date' ? formatDateForDisplay(row[field]) : row[field]
                      )
                    )}
                  </td>
                ))}
                <td className="py-2 px-4 border-b" style={{ width: '3ch' }}>
                  <button onClick={() => this.handleDeleteRow(section, row.id)}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  

  renderActiveHistory() {
    const { editableHistory } = this.state;
    const headers = ["Date", "Condition", "Details"];
    const data = editableHistory && editableHistory.activeHistory ? editableHistory.activeHistory.map(item => ({
      id: item.id,
      date: item.date,
      condition: item.condition,
      details: item.details || ""
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Active History:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'activeHistory')
        ) : (
          <p className="italic">No Active History available.</p>
        )}
      </div>
    );
  }

  renderPastHistory() {
    const { editableHistory } = this.state;
    const headers = ["Date", "Condition", "Details"];
    const data = editableHistory && editableHistory.pastHistory ? editableHistory.pastHistory.map(item => ({
      id: item.id,
      date: item.date,
      condition: item.condition,
      details: item.details || ""
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Past History:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'pastHistory')
        ) : (
          <p className="italic">No Past history available.</p>
        )}
      </div>
    );
  }

  renderFamilyHistory() {
    const { editableHistory } = this.state;
    const headers = ["Relation", "Condition", "Notes"];
    const data = editableHistory && editableHistory.familyHistory ? editableHistory.familyHistory.map(item => ({
      id: item.id,
      relation: item.relation,
      condition: item.condition,
      notes: item.notes || ""
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Family History:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'familyHistory')
        ) : (
          <p className="italic">No Family History available.</p>
        )}
      </div>
    );
  }

  renderEmergency() {
    const { editableHistory } = this.state;
    const headers = ["Name", "Relationship", "Phone"];
    const data = editableHistory && editableHistory.emergency ? editableHistory.emergency.map(contact => ({
      id: contact.id,
      name: contact.name,
      relationship: contact.relationship,
      phone: contact.phone
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Emergency Contacts:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'emergency')
        ) : (
          <p className="italic">No Emergency contacts available.</p>
        )}
      </div>
    );
  }

  renderMedications() {
    const { editableHistory } = this.state;
    const headers = ["Name", "Dose", "Unit", "Form", "Frequency"];
    const data = editableHistory && editableHistory.medications ? editableHistory.medications.map(medication => ({
      id: medication.id,
      name: medication.name,
      dose: medication.dose,
      unit: medication.unit,
      form: medication.form,
      frequency: medication.frequency
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Medications:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'medications')
        ) : (
          <p className="italic">No Medications listed.</p>
        )}
      </div>
    );
  }

  renderAllergies() {
    const { editableHistory } = this.state;
    const headers = ["Substance", "Severity"];
    const data = editableHistory && editableHistory.allergies ? editableHistory.allergies.map(allergy => ({
      id: allergy.id,
      substance: allergy.substance,
      severity: allergy.severity
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Allergies:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'allergies')
        ) : (
          <p className="italic">No known allergies.</p>
        )}
      </div>
    );
  }

  renderImmunisations() {
    const { editableHistory } = this.state;
    const headers = ["Date", "Vaccine"];
    const data = editableHistory && editableHistory.immunisations ? editableHistory.immunisations.map(immunisation => ({
      id: immunisation.id,
      date: immunisation.date,
      vaccine: immunisation.vaccine
    })) : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Immunisations:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'immunisations')
        ) : (
          <p className="italic">No Immunisations data.</p>
        )}
      </div>
    );
  }

  renderSubstance() {
    const { editableHistory } = this.state;
    const headers = ["Status", "Notes"];
    const data = editableHistory &&  editableHistory.substance ? [{
      id: editableHistory.substance.id,
      status: editableHistory.substance.status,
      notes: editableHistory.substance.notes
    }] : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Substance Use:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'substance')
        ) : (
          <p className="italic">No substance history.</p>
        )}
      </div>
    );
  }

  renderSmoking() {
    const { editableHistory } = this.state;
    const headers = ["Status", "Notes"];
    const data = editableHistory && editableHistory.smoking ? [{
      id: editableHistory.smoking.id,
      status: editableHistory.smoking.status,
      notes: editableHistory.smoking.notes
    }] : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Smoking:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'smoking')
        ) : (
          <p className="italic">No smoking data.</p>
        )}
      </div>
    );
  }

  renderAlcohol() {
    const { editableHistory } = this.state;
    const headers = ["Status", "Notes"];
    const data = editableHistory && editableHistory.alcohol ? [{
      id: editableHistory.alcohol.id,
      status: editableHistory.alcohol.status,
      notes: editableHistory.alcohol.notes
    }] : [];
    return (
      <div className='p-2'>
        <p className='font-bold mb-2'>Alcohol:</p>
        {data.length > 0 ? (
        this.renderTable(data, headers, 'alcohol')
        ) : (
          <p className="italic">No Alcohol history.</p>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className='col-span-6 m-2 rounded shadow-sm p-2 bg-slate-100 relative sm:col-span-6 xs:col-span-6'>
        <ToastContainer />
        <p className='text-lg font-semibold p-1'>History</p>
        <div className='profile containerparent grid grid-cols-2 gap-4 xs:grid-cols-1'>
          {this.renderActiveHistory()}
          {this.renderPastHistory()}
          {this.renderFamilyHistory()}
          {this.renderEmergency()}
          {this.renderAllergies()}
          {this.renderMedications()}
          {this.renderSmoking()}
          {this.renderAlcohol()}
          {this.renderSubstance()}
          {this.renderImmunisations()}
        </div>
      </div>
    );
  }
}

export default withNavigation(History);
