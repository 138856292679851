import { AITextToObject, AITextToText, complexInput } from './AI';

// Helpers
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};

const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};

// Prompts
const instructionTextToPlan = `
Your task is to create a list of medical problems from user input and verify if each problem has a corresponding reference in a predefined list (provided as referrences). 
Your other task is to update existing data based on the input.
Use British spelling throughout. 
Provide only the information requested; do not add or summarise. 
Format your response as a flat JSON object.

For each problem in the medical problems list:

1. Check if the problem already exists in existing data, do not create a new one. 
2. If the problem does not exist in existing data, proceed as follows:
    - If the problem exists in referrences, or if a similar disease type is found (e.g., 'emphysema' can be considered similar to 'COPD', or 'subacromial bursitis' similar to 'shoulder pain'), return a JSON object containing:
        - The exact problem name as it appears in referrences (with the first letter capitalised).
        - A list of goals (with their descriptions and status as 'plan').
        - A list of plans (with their entries and status).
    - If the problem does not exist in referrences or no similar disease type is found, return a JSON object with the input problem and empty lists for goals and plans.
    - Ensure that every problem in the medical problems list is represented in the output, even if it doesn't have a corresponding entry in referrences.
3. If specify in user input, update goal and plan as follows : 
    - Add, delete or modify goal associated with the problem as per user input.
    - Add, delete or modify plan associated with the problem as per user input.
    - Change status of plan as per user input with the value of "plan" if it's not being done, "doing" if it's currently ongoing, or "done" if it's already performed as per user input
4. Add journey data related to the problem:
    - Fill journey data with any mention of pathology result, imaging, diagnosis, report or any medical procedure associated with the problem as per user input. 
    - If journey data is available for the problem, include it in the response as: [{id: 'null', date: 'yyyy-mm-dd', journey: 'journey data', type: 'pathology', 'imaging', 'diagnosis', 'report', or 'procedure'}].
    - If no journey data is available, return an empty journey list: journey: [].
    - For example if there's HbA1C result, write the journey in the Diabetes problem.

5. Format the response as follows:
   [{ problem: 'problem entry from references, indicate location/side if applicable (e.g., left, right, lower, upper, inferior, superior)', goal: [list of goals], plan: [list of plans], journey: [associated journey data with the problem, e.g., subacromial bursitis as part of shoulder pain, CT-scan lumbosacral as part of low back pain] }].

Example: Given 'emphysema' in the input, search for 'COPD' in referrences. If found, return 'COPD' as the problem name, along with its goals and plans."
6. Combine both existing data and the new problem list as one list.
`

const instructionTextToReview = `
   You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:
   
   Use short sentences and simple points.
   Organize the notes in this specific order:
   History: Summarize key points of the patient's history concisely.
   Examination: Include details of any physical or mental state examination conducted, if applicable.
   Assessment: List any identified problems, issues, or diagnoses.
   Management: Outline any plans or actions to be taken, if applicable.
   Write the content directly underneath each title. If no content is provided for a section, skip that title. Do not include anything outside the input.
   `


// AI Methods

// text to plan

// Chain of AI
// 1. Add new problem if not exist in prevData, based on referrences
// 2. 
export const textToPlan = async (input, prevData, referrences) => {

    var processedInput = {
        instruction : instructionTextToPlan,
        user_input : input,
        existing_data: prevData,
        referrences : referrences
    }

    const data = await complexInput(processedInput);
    const plan = regenerateIds(data);
    return plan;
}

// text to review
export const textToReview = async (text, reviews) => {
    const data = await AITextToText(instructionTextToReview, text, reviews);
    const reviewData = [...(reviews || [])];
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const newReview = { date: formattedDate, review: data };
    
    reviewData.push(newReview);
    return reviewData;

}

