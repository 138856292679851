import React, { Component } from 'react';
import CryptoJS from 'crypto-js';

import Contacts from '../SettingComponents/Contacts';
import Practices from '../SettingComponents/Practices';
import Dashboard from '../SettingComponents/Dashboard';
import Referrences from '../SettingComponents/Referrences';

const secretKey = process.env.REACT_APP_SECRET_KEY;
const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedComponent : this.props.settingComponent,
      adminData : this.props.adminData
      // adminFileHandle: null,
      // isFileLoaded: false,
      // adminData: { contacts: [], practices: [], dashboard: [], referrences: [] },
      // selectedComponent: 'contacts',
    };
  }

  // Method to change the selected component
  // changeComponent = (component) => {
  //   this.setState({ selectedComponent: component });
  // };

  componentDidUpdate(prevProps) {
    if (prevProps.settingComponent !== this.props.settingComponent) {
        this.setState({ selectedComponent: this.props.settingComponent });
    }
  }


  saveFile = async (fileHandle, data) => {
    const writable = await fileHandle.createWritable();
    await writable.write(new Blob([data], { type: 'text/plain' }));
    await writable.close();
  };

  saveToFileSystem = async (updatedData) => {
    // const { adminFileHandle, adminData } = this.state;
    // const newData = { ...adminData, ...updatedData };

    // if (!adminFileHandle) {
    //   try {
    //     const fileHandle = await window.showSaveFilePicker({
    //       suggestedName: 'admin.cdv',
    //       types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
    //     });
    //     this.setState({ adminFileHandle: fileHandle });
    //     localStorage.setItem('adminFile', fileHandle);
    //   } catch (error) {
    //     console.error('Error selecting file for saving:', error);
    //     return;
    //   }
    // }

    // try {
    //   const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(newData), secretKey).toString();
    //   await this.saveFile(this.state.adminFileHandle, encryptedData);
    //   this.setState({ adminData: newData });
    //   console.log('Data saved successfully.');
    // } catch (error) {
    //   console.error('Error saving to file system:', error);
    // }
  };

  saveToLocalStorage = (updatedData) => {
    this.props.saveData(updatedData)
    // const { adminData } = this.state;
    // const newData = { ...adminData, ...updatedData };

    // try {
    //   const encryptedAdmin = CryptoJS.AES.encrypt(JSON.stringify(newData), secretStorage).toString();
    //   localStorage.setItem('admin', encryptedAdmin);
    //   this.setState({ adminData: newData });
    //   console.log('Data successfully saved to localStorage.');
    // } catch (error) {
    //   console.error('Error encrypting or saving to localStorage:', error);
    // }
  };

  loadFromLocalStorage = () => {
    const encryptedAdmin = localStorage.getItem('admin');
    if (encryptedAdmin) {
      const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
      const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.setState({ adminData });
      return adminData;
    }
    return { contacts: [], practices: [], dashboard: [], referrences: [] };
  };

  // handleCreateAdminFile = async () => {
  //   try {
  //     const fileHandle = await window.showSaveFilePicker({
  //       suggestedName: 'admin.cdv',
  //       types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
  //     });

  //     const defaultAdmin = { contacts: [], practices: [], referrences: [], dashboard: [] };
  //     const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(defaultAdmin), secretKey).toString();
  //     await this.saveFile(fileHandle, encryptedData);
  //     this.saveToLocalStorage(defaultAdmin);

  //     this.setState({ adminFileHandle: fileHandle, isFileLoaded: true, adminData: defaultAdmin });
  //   } catch (error) {
  //     console.error('Error creating admin file:', error);
  //   }
  // };

  // handleLoadAdminFile = async () => {
  //   try {
  //     const fileHandle = await window.showOpenFilePicker({
  //       types: [{ description: 'CDV Files', accept: { 'text/plain': ['.cdv'] } }],
  //     });
  //     const file = await fileHandle[0].getFile();
  //     const content = await file.text();
  //     const bytes = CryptoJS.AES.decrypt(content, secretKey);
  //     const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     this.saveToLocalStorage(decryptedData);
  //     this.setState({ adminFileHandle: fileHandle, isFileLoaded: true, adminData: decryptedData });
  //   } catch (error) {
  //     console.error('Error loading admin file:', error);
  //   }
  // };

  // handleCloseAdminFile = () => {
  //   this.setState({ 
  //     adminFileHandle: null, 
  //     isFileLoaded: false, 
  //     adminData: { contacts: [], practices: [], dashboard: [], referrences: [] } 
  //   });
  //   localStorage.removeItem('admin');
  //   localStorage.removeItem('adminFile');
  // };

  renderComponent = () => {
    const { selectedComponent, adminData } = this.state;

    switch (selectedComponent) {
      case 'contacts':
        return (
          <Contacts
            saveToFileSystem={this.saveToFileSystem}
            saveToLocalStorage={this.saveToLocalStorage}
            loadFromLocalStorage={this.loadFromLocalStorage}
            data={adminData.contacts}
            changeComponent={this.changeComponent}
          />
        );
      case 'practices':
        return (
          <Practices
            saveToFileSystem={this.saveToFileSystem}
            saveToLocalStorage={this.saveToLocalStorage}
            loadFromLocalStorage={this.loadFromLocalStorage}
            data={adminData.practices}
          />
        );
      case 'referrences':
        return (
          <Referrences
            saveToFileSystem={this.saveToFileSystem}
            saveToLocalStorage={this.saveToLocalStorage}
            loadFromLocalStorage={this.loadFromLocalStorage}
            data={adminData.referrences}
          />
        );
      default:
        return <div>Select a component from the menu</div>;
    }
  };

  render() {
    const { isFileLoaded } = this.state;
    const adminFile = localStorage.getItem('admin')

    return (
      <div className='col-span-6'>
      
        {adminFile ? (
          <div className="component-container">
            {this.renderComponent()}
          </div>
        ) : (
          <div className="m-20 bg-slate-100 shadow rounded p-10 text-center">
            Load Admin file to display settings
          </div>
        )}
      </div>
    );
  }
}

export default Settings;
