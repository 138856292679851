import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigation } from '../../utilities/NavigationContext';
import { useAuth } from '../../utilities/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class UpdatePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        password: '',
        passwordConfirm:'',
        token: ''
        };
    }

    componentDidMount() {
        const currentUrl = window.location.href;

        // Create a URLSearchParams object from the current URL
        const urlParams = new URLSearchParams(new URL(currentUrl).search);

        // Check if the 'token' parameter exists
        if (urlParams.has('token')) {
            // If the token exists, you can retrieve its value like this
            const token = urlParams.get('token');
            this.setState({token: token})

            // You can then proceed with your logic for handling the token
        } else {
    
            toast.error('This link is invalid. Check your email again please', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
              });
            this.props.navigate('/login');
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { password, passwordConfirm, token } = this.state;

        // Check if passwords match
        if (password !== passwordConfirm) {
            toast.error(`Passwords do not match!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
              });
            return;
        }

        

        try {
            const response = await axios.post(`${EndPoint}/user/update-password`, { password, token });
            if (response.status === 200) {
                toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
                });
                this.props.navigate('/login');
            } else {
                toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
                });
        
            }
        } catch (error) {
            
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar : false
        });
        }
    };


    render() {
        return (
        <div className='flex flex-col justify-center items-center h-screen'>
            <ToastContainer />
            <form onSubmit={this.handleSubmit} className='flex flex-col gap-4 mt-6 bg-slate-100 p-10 rounded shadow-lg'>
            <h2 className='font-semibold text-lg text-center'>Reset Password</h2>
            <p>Enter your new password below</p>
            <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                <input
                    type="password"
                    name="passwordConfirm"
                    placeholder="Confirm Password"
                    value={this.state.passwordConfirm}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
            
            <div className='text-center'><button type="submit" className='text-white bg-sky-600 hover:bg-sky-700 rounded shadow-sm w-32 p-2 m-1'>Reset Password</button></div>
            <p className='text-center'>Remember your password? <button onClick={()=> {  this.props.navigate('/login');}} className='text-red-700 hover:text-red-800'>Login here</button></p>
            </form>
            <div className='py-4 inline-block'>Don't have an account? <div className='text-red-700'><Link to="/register">Click here to register.</Link></div></div>
        </div>
        );
    }
}

// Higher-order component to consume the NavigationContext
const UpdatePasswordWithNavigation = (props) => {
  const navigate = useNavigation();
  const [, setAuth] = useAuth(); // Get the setAuth function from AuthContext

  return <UpdatePassword {...props} navigate={navigate} setAuth={setAuth} />;
};

export default UpdatePasswordWithNavigation;
