import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import Tools from './components/Tools';
import Login from './components/Login'; 
import Register from './components/Register'; 
import ForgotPassword from './components/AccountComponents/ForgotPassword';
import UpdatePassword from './components/AccountComponents/UpdatePassword';

import Navigation from './components/Navigation';

import Home from './components/Home';
import NewInterview from './components/NewInterview';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavigationProvider } from './utilities/NavigationContext';
import { AuthProvider } from './utilities/AuthContext';
import PrivateRoutes from './utilities/PrivateRoutes';

import LocalAI from './components/LocalAI';


// rewrite 
import Master from './components/Master';

class App extends React.Component {

  render() {

    return (
      <Router>
        <AuthProvider>
          <NavigationProvider>
          <ToastContainer />
            <Navigation />

            <Routes>
             
              <Route path="/" element={<Home />} />
              {/* <Route path="/" element={<Master />} /> */}
              {/* <Route element={<PrivateRoutes />}> */}
               
    
           
                  <Route path="/tools" element={<Tools />} />
                  <Route path="/master" element={<Master />} />

                  <Route path="/updatepassword" element={<UpdatePassword />} />

                  
        
              {/* </Route>        */}
              <Route path="/localai" element={<LocalAI />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/newinterview" element={<NewInterview />} />

  
            </Routes>
          </NavigationProvider>
        </AuthProvider>
      </Router>
    );
  }
}

export default App;
