import React, { Component } from 'react';

// Helper function to generate unique IDs
function generateId() {
  return Math.random().toString(36).substr(2, 9);
}

// Constants for dropdown options
const titles = ['Mr', 'Mrs', 'Ms', 'Dr'];
const australianStates = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];
const professionOptions = [
  "General Practitioner (GP)",
  "Pediatrician",
  "Cardiologist",
  "Dermatologist",
  "Neurologist",
  "Oncologist",
  "Ophthalmologist",
  "Orthopedic Surgeon",
  "Psychiatrist",
  "Radiologist",
  "Anesthesiologist",
  "Endocrinologist",
  "Gastroenterologist",
  "Hematologist",
  "Infectious Disease Specialist",
  "Nephrologist",
  "Obstetrician/Gynecologist (OB/GYN)",
  "Pulmonologist",
  "Rheumatologist",
  "Urologist",
  "Surgeon",
  "Plastic Surgeon",
  "Emergency Medicine Physician",
  "Pathologist",
  "Otolaryngologist (ENT)",
  "Allergy and Immunology Specialist",
  "Critical Care Medicine Specialist",
  "Geriatrician",
  "Geneticist",
  "Sports Medicine Specialist",
  "Podiatrist",
  "Occupational Medicine Specialist",
  "Physiatrist (Physical Medicine and Rehabilitation)",
  "Family Medicine Physician",
  "Hospitalist",
  "Medical Geneticist",
  "Pain Management Specialist",
  "Sleep Medicine Specialist",
  "Nuclear Medicine Specialist",
  "Preventive Medicine Specialist",
  "Physiotherapist",
  "Occupational Therapist",
  "Speech Pathologist",
  "Dietitian",
  "Diabetes Educator",
  "Pharmacist",
  "Audiologist",
  "Social Worker",
  "Chiropractor",
  "Osteopath",
  "Exercise Physiologist",
  "Prosthetist/Orthotist",
  "Medical Laboratory Scientist",
  "Medical Imaging Technologist",
  "Psychologist",
  "Clinical Psychologist",
  "Counsellor",
  "Mental Health Nurse",
  "Case Manager",
  "Medical Power of Attorney (MPOA)",
  "Care Worker",
  "Aged Care Worker",
  "Disability Support Worker",
  "Home Care Assistant",
  "Personal Care Assistant (PCA)"
];

class Practices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      practices: [], // List of practices
      newPractice: this.resetNewPractice(), // Form state for a new practice
      editingPracticeId: null, // ID of the practice currently being edited
    };
  }

  componentDidMount() {
    // Load practices from localStorage on mount
    const adminData = this.props.loadFromLocalStorage();
    if (adminData) {
      this.setState({ practices: adminData.practices || [] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data !== this.state.practices) {
      this.setState({
        practices: this.props.data || [],
        newPractice: this.resetNewPractice(),
        editingPracticeId: null,
      });
    }
  }

  // Reset the form fields to default values for adding a new practice
  resetNewPractice = () => ({
    id: '',
    title: '',
    firstName: '',
    lastName: '',
    profession: '',
    providerNumber: '',
    practiceName: '',
    address1: '',
    address2: '',
    suburb: '',
    postcode: '',
    state: '',
    phone: '',
    fax: '',
    email: ''
  });

  // Add or update a practice
  handleSubmit = () => {
    const { practices, newPractice } = this.state;
    const { saveToFileSystem, saveToLocalStorage } = this.props;

    const existingPractice = practices.find(practice => practice.id === newPractice.id);

    if (existingPractice) {
      // Update existing practice
      this.setState(
        prevState => ({
          practices: prevState.practices.map(practice =>
            practice.id === existingPractice.id ? { ...practice, ...prevState.newPractice } : practice
          ),
          newPractice: this.resetNewPractice(),
          editingPracticeId: null,
        }),
        () => {
          saveToFileSystem({ practices: this.state.practices });
          saveToLocalStorage({ practices: this.state.practices });
        }
      );
    } else {
      // Add new practice
      this.setState(
        prevState => ({
          practices: [...prevState.practices, { ...prevState.newPractice, id: generateId() }],
          newPractice: this.resetNewPractice(),
          editingPracticeId: null,
        }),
        () => {
          saveToFileSystem({ practices: this.state.practices });
          saveToLocalStorage({ practices: this.state.practices });
        }
      );
    }
  };

  // Handle changes in form inputs
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      newPractice: {
        ...prevState.newPractice,
        [name]: value,
      },
    }));
  };

  // Edit an existing practice
  handleEdit = (practice) => {
    this.setState({
      newPractice: { ...practice },
      editingPracticeId: practice.id,
    });
  };

  // Delete a practice by ID
  handleDelete = (id) => {
    const { saveToFileSystem, saveToLocalStorage } = this.props;
    this.setState(
      prevState => ({
        practices: prevState.practices.filter(practice => practice.id !== id),
      }),
      () => {
        saveToFileSystem({ practices: this.state.practices });
        saveToLocalStorage({ practices: this.state.practices });
      }
    );
  };

  render() {
    const { practices, newPractice, editingPracticeId } = this.state;

    return (
      <div>
        <p className="text-xl text-center font-bold m-10">Practice Manager</p>

        {/* Form for adding/editing a practice */}
        <div className="flex justify-center">
          <div className="p-4 w-[1200px] grid grid-cols-2 gap-10 bg-slate-100 m-10 rounded shadow">
            <div className="mb-4">
              <h2 className="text-xl mb-2 bg-sky-800 text-white p-4 text-center rounded">
                {editingPracticeId ? 'Edit Practice' : 'Add New Practice'}
              </h2>
              <div className="grid grid-cols-[1fr_2fr] gap-4 p-4">
                {/* Practice form fields */}
                <label className="p-1 text-right">Title</label>
                <select
                  name="title"
                  value={newPractice.title}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                >
                  <option value="">Select Title</option>
                  {titles.map((title) => (
                    <option key={title} value={title}>
                      {title}
                    </option>
                  ))}
                </select>

                <label className="p-1 text-right">First Name</label>
                <input
                  type="text"
                  required
                  name="firstName"
                  value={newPractice.firstName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Last Name</label>
                <input
                  type="text"
                  required
                  name="lastName"
                  value={newPractice.lastName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Profession</label>
                <input
                  type="text"
                  required
                  name="profession"
                  value={newPractice.profession}
                  onChange={this.handleChange}
                  list="professionList"
                  className="border border-slate-200 p-1"
                />
                <datalist id="professionList">
                  {professionOptions.map((profession, index) => (
                    <option key={index} value={profession} />
                  ))}
                </datalist>

                <label className="p-1 text-right">Provider Number</label>
                <input
                  type="text"
                  name="providerNumber"
                  value={newPractice.providerNumber}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Practice/Clinic Name</label>
                <input
                  type="text"
                  required
                  name="practiceName"
                  value={newPractice.practiceName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Address 1</label>
                <input
                  type="text"
                  required
                  name="address1"
                  value={newPractice.address1}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Address 2</label>
                <input
                  type="text"
                  name="address2"
                  value={newPractice.address2}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Suburb</label>
                <input
                  type="text"
                  required
                  name="suburb"
                  value={newPractice.suburb}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">State</label>
                <select
                  name="state"
                  value={newPractice.state}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                >
                  <option value="">Select State</option>
                  {australianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                <label className="p-1 text-right">Postcode</label>
                <input
                  type="text"
                  required
                  name="postcode"
                  value={newPractice.postcode}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Phone</label>
                <input
                  type="text"
                  required
                  name="phone"
                  value={newPractice.phone}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Fax</label>
                <input
                  type="text"
                  name="fax"
                  value={newPractice.fax}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Email address</label>
                <input
                  type="email"
                  required
                  name="email"
                  value={newPractice.email}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />
              </div>

              <div className="flex justify-center">
                <button
                  className="bg-sky-600 hover:bg-sky-700 text-white py-2 px-4 rounded w-40"
                  onClick={this.handleSubmit}
                >
                  {editingPracticeId ? 'Update Practice' : 'Add Practice'}
                </button>
              </div>
            </div>

            {/* List of practices */}
            <div>
              <h2 className="text-xl mb-2 bg-sky-800 text-white p-4 text-center rounded">Practices List</h2>
              <ul>
                {practices.map((practice) => (
                  <li key={practice.id} className="border-b py-2 flex justify-between items-center">
                    <div>
                      <p className="font-bold">{practice.title} {practice.firstName} {practice.lastName}</p>
                      <p>{practice.profession}</p>
                      <p>{practice.address1} {practice.address2}</p>
                      <p>{practice.suburb}, {practice.postcode}, {practice.state}</p>
                      <p>Phone: {practice.phone}, Fax: {practice.fax}</p>
                      <p>Email: {practice.email}</p>
                    </div>
                    <div>
                      <button
                        className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-3 rounded mr-2"
                        onClick={() => this.handleEdit(practice)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                        onClick={() => this.handleDelete(practice.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Practices;
