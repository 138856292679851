import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigation } from '../utilities/NavigationContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          email: '',
          password: '',
          passwordConfirm: '',
          firstName: '',
          lastName: '',
          userType: '',
          role: '',
          comment : '',
          
          passwordMatch: true 
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        // If one of the passwords changed, check if they are the same
        if (name === 'password' || name === 'passwordConfirm') {
            const passwordMatch = 
            name === 'password'
                ? value === this.state.passwordConfirm
                : value === this.state.password;
            this.setState({
            [name]: value,
            passwordMatch: passwordMatch
            });
        } else {
            this.setState({
            [name]: value
            });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email, password, passwordConfirm, firstName, lastName, userType, comment, interview } = this.state;

        // Check if passwords match
        if (password !== passwordConfirm) {
            toast.error(`Passwords do not match!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
            return;
        }

        // check if comment exist
        if (comment === "") {
            toast.error(`Please add some comments`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
            return;

        }

        // check if interview exist
        if (!interview) {
            toast.error(`Please check yes/no for interview`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
            return;

        }

        // Implement registration logic here
        const registerEndpoint = `${EndPoint}/user`; // Replace with your actual backend API endpoint
    
        try {
            const response = await axios.post(registerEndpoint, {
                firstName,
                lastName,
                email,
                password, 
                passwordConfirm,
                userType,
                role: 'user', // Assuming 'role' is required by your backend
                comment, 
                interview
            });
            
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
            this.props.navigate('/login');
    
        } catch (error) {
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false
            });
        }
    };
    

    render() {
        return (
            <div>
                <div className='flex flex-col justify-center items-center h-screen'>
                <form onSubmit={this.handleSubmit} className='bg-slate-100 p-10 rounded shadow-lg' >
                <h2 className='text-lg font-semibold text-center'>Register</h2>
                <div className='flex flex-col m-2 p-1 gap-2 w-72'>
                <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                <input
                    type="password"
                    name="passwordConfirm"
                    placeholder="Confirm Password"
                    value={this.state.passwordConfirm}
                    onChange={this.handleInputChange}
                    className='rounded border border-slate-200 p-2 text-sm'
                />
                
                {/* <select
                name="role"
                value={this.state.role}
                onChange={this.handleInputChange}
                required
                className='rounded border border-slate-200 p-2 text-sm'
                >
                <option value="" disabled>Select your role</option>
                <option value="admin">Admin</option>
                <option value="user">User</option>
                <option value="guest">Guest</option>
                </select> */}

                <select
                name="userType"
                value={this.state.userType}
                onChange={this.handleInputChange}
                required
                className='rounded border border-slate-200 p-2 text-sm'
                >
                <option value="" disabled>Select your Role</option>
                <option value="GP">GP</option>
                <option value="Specialist">Specialist</option>
                <option value="Allied Health">Allied Health</option>
                <option value="Others">Others</option>
                </select>

                <textarea
                name="comment"
                value={this.state.comment}
                onChange={this.handleInputChange}
                placeholder="Are you presently overseeing patients with multiple chronic conditions and complex consultations ? What specific challenges do you encounter in this aspect, and what type of system do you use ?"     
                className='rounded border border-slate-200 p-2 text-sm resize-none h-52'/>

                <label className='text-sm my-4'>Are you open to being interviewed about the above issues? Your insights would be invaluable in crafting a product designed to support the management of chronic illnesses and complex consultations.</label>
                <div className='flex flex-row text-sm gap-2 mb-4'>
                <input
                type="checkbox"
                className=' border-slate-300'
                id="yesCheckbox"
                name="interview"
                checked={this.state.interview === "yes"}
                value="yes"
                onChange={this.handleInputChange}
                />
                <label htmlFor="yesCheckbox">Yes</label>

                <input
                type="checkbox"
                className=' border-slate-300'
                id="noCheckbox"
                name="interview"
                checked={this.state.interview === "no"}
                value="no"
                onChange={this.handleInputChange}
                />
                <label htmlFor="noCheckbox">No</label>
                </div>

                </div>
                <div className='text-center'>
                <button type="submit" className='bg-sky-600 hover:bg-sky-700 rounded shadow-lg w-40 text-white px-4 py-2'>Create Account</button>
                </div>
                </form>
                </div>
                <Link to="/">Already have an account? Login here.</Link>
                <ToastContainer />
            </div>
        );
    }
}

// Higher-order component to consume the NavigationContext
const RegisterWithNavigation = (props) => {
    const navigate = useNavigation(); // Use the useNavigation hook here
    return <Register {...props} navigate={navigate} />;
};

export default RegisterWithNavigation;
