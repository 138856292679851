import React, { Component } from 'react';
import CryptoJS from 'crypto-js';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

function calculateAge(birthdate) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

function generateDemographicData(patients) {
    const demographicData = {
        ageGroup: { child: 0, adult: 0, old: 0 },
        gender: { male: 0, female: 0, others: 0 },
        ethnic: {}
    };

    patients.forEach(patient => {
        const age = calculateAge(patient.dob);
        const ageGroup = age <= 12 ? 'child' : age <= 59 ? 'adult' : 'old';
        demographicData.ageGroup[ageGroup]++;
        const genderKey = patient.gender.toLowerCase();
        demographicData.gender[genderKey === 'male' || genderKey === 'female' ? genderKey : 'others']++;
        demographicData.ethnic[patient.ethnic] = (demographicData.ethnic[patient.ethnic] || 0) + 1;
    });

    return demographicData;
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        const dashboardData = props.data || [];
        this.state = {
            dashboard: dashboardData,
        };
    }

    // Sync props with state if data changes in parent component
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ dashboard: this.props.data || [] });
        }
    }

    // Helper function to create Pie chart data
    createChartData = (labels = [], data = []) => ({
        labels: labels.length > 0 ? labels : ['No Data'],
        datasets: [
            {
                data: data.length > 0 ? data : [1],
                backgroundColor: ['#5499c7', '#f9e79f', '#d98880', '#abebc6', '#d2b4de', '#edbb99'],
                hoverBackgroundColor: ['#5499c7', '#f9e79f', '#d98880', '#abebc6', '#d2b4de', '#edbb99'],
            },
        ],
    });

    // Clear dashboard data from parent and localStorage
    clearDashboard = () => {
        this.setState({ dashboard: [] });
        this.props.saveToLocalStorage({ dashboard: [] });
        this.props.saveToFileSystem({ dashboard: [] });
    };

    renderMH = () => {
        const { dashboard } = this.state;
        const totals = dashboard.reduce(
            (acc, patient) => ({
                MH: acc.MH + (patient.MH || 0),
                MHPlans: acc.MHPlans + (patient.MHPlans || 0),
                MHPriority: acc.MHPriority + (patient.MHPriority || 0),
                MHReviews: acc.MHReviews + (patient.MHReviews || 0),
            }),
            { MH: 0, MHPlans: 0, MHPriority: 0, MHReviews: 0 }
        );

        return (
            <div className="bg-red-100 p-4 rounded shadow flex flex-col justify-center items-center h-[300px]">
                <h2 className="text-xl font-semibold">Mental Health</h2>
                <div className="text-9xl mt-4 font-bold text-red-700">{totals.MH}</div>
                <div className="text-sm mt-auto">
                    <p>Plans: {totals.MHPlans}</p>
                    <p>Reviews: {totals.MHReviews}</p>
                    <p>High Priority: {totals.MHPriority}</p>
                </div>
            </div>
        );
    };

    renderHA = () => {
        const { dashboard } = this.state;
        const totals = dashboard.reduce(
            (acc, patient) => ({
                HA: acc.HA + (patient.HA || 0),
                HAPlans: acc.HAPlans + (patient.HAPlans || 0),
                HAPriority: acc.HAPriority + (patient.HAPriority || 0),
                HAReviews: acc.HAReviews + (patient.HAReviews || 0),
            }),
            { HA: 0, HAPlans: 0, HAPriority: 0, HAReviews: 0 }
        );

        return (
            <div className="bg-sky-100 p-4 rounded shadow flex flex-col justify-center items-center h-[300px]">
                <h2 className="text-xl font-semibold">Health Assessment</h2>
                <div className="text-9xl mt-4 font-bold text-sky-700">{totals.HA}</div>
                <div className="text-sm mt-auto">
                    <p>Plans: {totals.HAPlans}</p>
                    <p>Reviews: {totals.HAReviews}</p>
                    <p>High Priority: {totals.HAPriority}</p>
                </div>
            </div>
        );
    };

    renderCDM = () => {
        const { dashboard } = this.state;
        const totals = dashboard.reduce(
            (acc, patient) => ({
                CDM: acc.CDM + (patient.CDM || 0),
                CDMPlans: acc.CDMPlans + (patient.CDMPlans || 0),
                CDMPriority: acc.CDMPriority + (patient.CDMPriority || 0),
                CDMReviews: acc.CDMReviews + (patient.CDMReviews || 0),
            }),
            { CDM: 0, CDMPlans: 0, CDMPriority: 0, CDMReviews: 0 }
        );

        return (
            <div className="bg-yellow-100 p-4 rounded shadow flex flex-col justify-center items-center h-[300px]">
                <h2 className="text-xl font-semibold">Chronic Disease</h2>
                <div className="text-9xl mt-4 font-bold text-yellow-700">{totals.CDM}</div>
                <div className="text-sm mt-auto">
                    <p>Plans: {totals.CDMPlans}</p>
                    <p>Reviews: {totals.CDMReviews}</p>
                    <p>High Priority: {totals.CDMPriority}</p>
                </div>
            </div>
        );
    };

    render() {
        const { dashboard } = this.state;
        const demographicData = generateDemographicData(dashboard);

        return (
            <div className="ml-10 grid grid-cols-3 gap-4 p-4 mt-10">
                <p className="flex justify-center col-span-3 text-4xl font-bold mb-10">Dashboard</p>
                <div className="bg-slate-100 p-4 col-span-3 rounded shadow">
                    <h2 className="text-xl font-semibold mb-4">Demographic</h2>
                    <div className="grid grid-cols-3 gap-4">
                        <div className='p-10'>
                        <p className="text-center mt-2">Age Group</p>
                            <Pie data={this.createChartData(['Child', 'Adult', 'Old'], Object.values(demographicData.ageGroup))} />
                        </div>
                        <div className='p-10'>  
                            <p className="text-center mt-2">Gender</p>
                            <Pie data={this.createChartData(['Male', 'Female', 'Others'], Object.values(demographicData.gender))} />
                        </div>
                        {Object.keys(demographicData.ethnic).length > 0 ? (
                            <div className='p-10'>
                                <Pie
                                    data={this.createChartData(
                                        Object.keys(demographicData.ethnic), // Use ethnic groups as labels
                                        Object.values(demographicData.ethnic) // Use ethnic group counts as data
                                    )}
                                />
                                <p className="text-center mt-2">Ethnicity</p>
                            </div>
                        ) : (
                            <div className="p-10 text-center">

                                <p className="text-center mt-2">Ethnicity</p>
                            </div>
                        )}
                    </div>
                </div>
                {this.renderMH()}
                {this.renderHA()}
                {this.renderCDM()}
            </div>
        );
    }
}

export default Dashboard;
