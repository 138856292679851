import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AudioRecorder from '../../utilities/AudioRecorder';

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

class Planning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            problems: [],
            newGoal: {}, // Track new goal input for each problem
            newPlan: {}, // Track new plan input for each problem and status
            editingGoalId: null, // Track which goal is being edited
            editingPlanId: null, // Track which plan entry is being edited
            editValue: '', // Track the current value being edited
            editingProblemId: null, // Track which problem is being edited
            editProblemValue: '',
            editingNotesId: null,  // Track which notes are being edited
            editNotesValue: '',    
        };
    }

    componentDidMount() {
        this.setState({ problems: this.props.problems });
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.problems !== this.props.problems) {
            this.setState({ problems: this.props.problems });
        }
    }
    

    // Toggle collapse/expand
    toggleCollapse = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        collapsed: !problem.collapsed,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a goal
    deleteGoal = (problemId, goalId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: problem.goal.filter((goal) => goal.id !== goalId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete a plan entry
    deletePlanEntry = (problemId, planId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: problem.plan.filter((plan) => plan.id !== planId),
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to delete Problem
    deleteProblem = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.filter(problem => problem.id !== problemId);
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }

    // Method to add a new goal
    addGoal = (problemId) => {
        const { newGoal } = this.state;
        if (!newGoal[problemId] || newGoal[problemId].trim() === '') return; // Don't add empty goals

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        goal: [
                            ...problem.goal,
                            { id: generateId(), description: newGoal[problemId] },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newGoal: { ...prevState.newGoal, [problemId]: '' },
            };
        }, () => {
            // Now `updatedProblems` is available within the callback as `this.state.problems`
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    // Method to add a new plan entry
    addPlanEntry = (problemId, status) => {
        const { newPlan } = this.state;
        const key = `${problemId}-${status}`;
        const newEntry = newPlan[key];

        if (!newEntry || newEntry.trim() === '') return; // Don't add empty entries

        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    return {
                        ...problem,
                        plan: [
                            ...problem.plan,
                            { id: generateId(), entry: newEntry, status: status },
                        ],
                    };
                }
                return problem;
            });

            return {
                problems: updatedProblems,
                newPlan: { ...prevState.newPlan, [key]: '' },
            };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    addNewProblem = () => {
        const newProblem = {
            id: generateId(),
            problem: 'New Problem', 
            notes : '...',
            goal: [],
            plan: [],
            priority: 'Low',
            collapsed: false
        };
    
        this.setState((prevState) => ({
            problems: [newProblem, ...prevState.problems]
        }), () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    }

    startEditingProblem = (problemId, currentProblem) => {
        this.setState({
            editingProblemId: problemId,
            editProblemValue: currentProblem
        });
    }

    // Handle key press for adding new entries
    handleKeyPress = (event, problemId, type, status) => {
        if (event.key === 'Enter') {
            if (type === 'goal') {
                this.addGoal(problemId);
            } else if (type === 'plan') {
                this.addPlanEntry(problemId, status);
            }
        }
    };

    // Handle input changes
    handleInputChange = (event, problemId, type, status) => {
        const value = event.target.value;

        if (type === 'goal') {
            this.setState((prevState) => ({
                newGoal: { ...prevState.newGoal, [problemId]: value },
            }));
        } else if (type === 'plan') {
            const key = `${problemId}-${status}`;
            this.setState((prevState) => ({
                newPlan: { ...prevState.newPlan, [key]: value },
            }));
        }
    };

    // Handle double-click to edit
    handleDoubleClick = (entryId, type, currentValue) => {
        if (type === 'goal') {
            this.setState({ editingGoalId: entryId, editValue: currentValue });
        } else if (type === 'plan') {
            this.setState({ editingPlanId: entryId, editValue: currentValue });
        }
    };

    // Handle change while editing
    handleEditChange = (event) => {
        this.setState({ editValue: event.target.value });
    };

    // Save edited value
    handleEditSave = (event, problemId, type) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        if (type === 'goal') {
                            return {
                                ...problem,
                                goal: problem.goal.map((goal) => {
                                    if (goal.id === prevState.editingGoalId) {
                                        return { ...goal, description: prevState.editValue };
                                    }
                                    return goal;
                                }),
                            };
                        } else if (type === 'plan') {
                            return {
                                ...problem,
                                plan: problem.plan.map((plan) => {
                                    if (plan.id === prevState.editingPlanId) {
                                        return { ...plan, entry: prevState.editValue };
                                    }
                                    return plan;
                                }),
                            };
                        }
                    }
                    return problem;
                });

                return {
                    problems: updatedProblems,
                    editingGoalId: null,
                    editingPlanId: null,
                    editValue: '',
                };
            });
        }
    };

    handleProblemEditChange = (event) => {
        this.setState({ editProblemValue: event.target.value });
    }
    
    handleProblemEditSave = (event, problemId) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        return {
                            ...problem,
                            problem: prevState.editProblemValue
                        };
                    }
                    return problem;
                });
    
                return {
                    problems: updatedProblems,
                    editingProblemId: null,
                    editProblemValue: ''
                };
            }, () => {
                this.props.onProblemsUpdate(this.state.problems);
            });
        }
    }

    // Start editing notes
    startEditingNotes = (problemId, currentNotes) => {
        this.setState({
            editingNotesId: problemId,
            editNotesValue: currentNotes
        });
    }

    // Handle change in the notes input box
    handleNotesChange = (event) => {
        this.setState({ editNotesValue: event.target.value });
    }

    // Save edited notes on "Enter" key press
    handleNotesSave = (event, problemId) => {
        if (event.key === 'Enter') {
            this.setState((prevState) => {
                const updatedProblems = prevState.problems.map((problem) => {
                    if (problem.id === problemId) {
                        return {
                            ...problem,
                            notes: prevState.editNotesValue
                        };
                    }
                    return problem;
                });

                return {
                    problems: updatedProblems,
                    editingNotesId: null,
                    editNotesValue: ''
                };
            }, () => {
                this.props.onProblemsUpdate(this.state.problems);
            });
        }
    }


    // Handle drag end
    onDragEnd = (result, problemId) => {
        const { destination, draggableId } = result;
        if (!destination) return;
    
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    const draggedItem = problem.plan.find((plan) => plan.id === draggableId);
    
                    // Remove dragged item from its original place
                    const newPlan = problem.plan.filter((plan) => plan.id !== draggableId);
    
                    // Add dragged item to the new status
                    draggedItem.status = destination.droppableId;
                    newPlan.splice(destination.index, 0, draggedItem);
    
                    return {
                        ...problem,
                        plan: newPlan,
                    };
                }
                return problem;
            });
    
            return { problems: updatedProblems };
        }, () => {
            // Now `updatedProblems` is available within the callback
            this.props.onProblemsUpdate(this.state.problems);
        });
    };
    

    // Method to toggle priority
    togglePriority = (problemId) => {
        this.setState((prevState) => {
            const updatedProblems = prevState.problems.map((problem) => {
                if (problem.id === problemId) {
                    let newPriority;
                    if (problem.priority === 'Inactive') {
                        newPriority = "Low"
                    } else if (problem.priority === "Low") {
                        newPriority = "Moderate";
                    } else if (problem.priority === "Moderate") {
                        newPriority = "High";
                    } else {
                        newPriority = "Inactive";
                    }
                    return {
                        ...problem,
                        priority: newPriority,
                    };
                }
                return problem;
            });
            return { problems: updatedProblems };
        }, () => {
            this.props.onProblemsUpdate(this.state.problems);
        });
    };

    handleInputUpdate = async (newTranscription, toastId) => {

        this.props.onVoiceInput(newTranscription, toastId)
    };


    render() {
        const { problems, newGoal, newPlan, editingGoalId, editingPlanId, editValue, editingNotesId, editNotesValue } = this.state;
    
        // Sorting the problems array by priority
        const sortedProblems = problems.sort((a, b) => {
            const priorityOrder = { High: 1, Moderate: 2, Low: 3, Inactive: 4 };
            return priorityOrder[a.priority] - priorityOrder[b.priority];
        });
    
        return (
            <div className='col-span-6 bg-slate-50 '>
                <div className='col-span-6'>  <AudioRecorder onInputUpdate={this.handleInputUpdate} /></div>
      
                <div className='flex justify-center'>
                <button className="text-md bg-sky-600 hover:bg-sky-700 text-white rounded text-center p-2 m-2 cursor-pointer w-60" onClick={this.addNewProblem}>Add Problem</button>
                </div>
                {sortedProblems.map((item) => (
                    <div key={item.id}
                        className={`m-2 p-2 rounded border-l-8 bg-slate-200 ${
                        item.priority === "High" ? "border-red-700" :
                        item.priority === "Moderate" ? "border-yellow-400" : 
                        item.priority === "Low" ? "border-green-400" :
                        "border-slate-500"
                        
                    }`}
                    >
                        <div 
                            className='col-span-4 text-md font-semibold m-2'
                        >
                            {this.state.editingProblemId === item.id ? (
                            <>
                            <input
                                value={this.state.editProblemValue}
                                onChange={this.handleProblemEditChange}
                                onKeyPress={(e) => this.handleProblemEditSave(e, item.id)}
                                className='border-slate-100 focus:border-slate-200 rounded placeholder-gray-400 w-80'
                            />
                            </>
                            ) : (
                                <span onDoubleClick={() => this.startEditingProblem(item.id, item.problem)}>
                                    {item.problem}
                                </span>
                            )}
                            <span className="text-sm mx-2 italic rounded text-black p-1 font-light float-right cursor-pointer" onClick={() => this.toggleCollapse(item.id)}>{item.collapsed ? "Click to expand" : "Click to collapse"}</span>
                            <span className='float-right bg-red-700 rounded p-1 mx-2 text-sm font-light hover:bg-red-800 cursor-pointer w-20 text-center text-white' onClick={()=> this.deleteProblem(item.id)}>Delete</span>
                            <span
                                className={`text-sm mx-2 rounded p-1 font-light float-right cursor-pointer w-20 text-center ${
                                    item.priority === "High" ? "bg-red-700 text-white" :
                                    item.priority === "Moderate" ? "bg-yellow-400 text-black" : 
                                    item.priority === "Low" ? "bg-green-400 text-black" :
                                    "bg-slate-500 text-gray-300"}`}
                                onClick={() => this.togglePriority(item.id)}
                            >
                                {item.priority}
                            </span>
                        </div>
                        {!item.collapsed && (
                            <DragDropContext onDragEnd={(result) => this.onDragEnd(result, item.id)}>
                                <div className='grid grid-cols-4'>
                                    <div className='m-2 p-2 text-sm bg-white rounded'>
                                        {/* Conditionally render notes or input box */}
                                        <div className='p-2 m-1 bg-slate-200'>
                                            {editingNotesId === item.id ? (
                                                <textarea
                                                    value={editNotesValue}
                                                    onChange={this.handleNotesChange}
                                                    onKeyPress={(e) => this.handleNotesSave(e, item.id)}
                                                    className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400 resize-none'
                                                />
                                            ) : (
                                                <span onDoubleClick={() => this.startEditingNotes(item.id, item.notes)}>
                                                    {item.notes}
                                                </span>
                                            )}
                                        </div>
                                        <div className='p-2 m-1 bg-slate-200'>
                                            <p className='text-md font-semibold'>Goals</p>
                                            {item.goal.map((each) => (
                                                <div key={each.id} className='bg-white p-2 m-1 flex justify-between items-center'>
                                                    {editingGoalId === each.id ? (
                                                        <input
                                                            value={editValue}
                                                            onChange={this.handleEditChange}
                                                            onKeyPress={(e) => this.handleEditSave(e, item.id, 'goal')}
                                                            className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                        />
                                                    ) : (
                                                        <span
                                                            onDoubleClick={() => this.handleDoubleClick(each.id, 'goal', each.description)}
                                                        >
                                                            {each.description}
                                                        </span>
                                                    )}
                                                    <button
                                                        className='text-slate-800 ml-4'
                                                        onClick={() => this.deleteGoal(item.id, each.id)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                            <div className='p-2 text-md flex justify-between items-center'>
                                                <input
                                                    placeholder='Type in new goal here'
                                                    className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                    value={newGoal[item.id] || ''}
                                                    onChange={(e) => this.handleInputChange(e, item.id, 'goal')}
                                                    onKeyPress={(e) => this.handleKeyPress(e, item.id, 'goal')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {['plan', 'doing', 'done'].map((status, index) => (
                                        <Droppable droppableId={status} key={status}>
                                            {(provided) => (
                                                <div
                                                    className='bg-white rounded m-2'
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    <div
                                                        className={`p-2 text-md text-center rounded m-2 ${
                                                            status === 'plan'
                                                                ? 'bg-yellow-400 text-black'
                                                                : status === 'doing'
                                                                ? 'bg-red-700 text-white'
                                                                : 'bg-teal-600 text-white'
                                                        }`}
                                                    >
                                                        {status.charAt(0).toUpperCase() + status.slice(1)}
                                                    </div>
                                                    {item.plan
                                                        .filter((each) => each.status === status)
                                                        .map((each, idx) => (
                                                            <Draggable key={each.id} draggableId={each.id} index={idx}>
                                                                {(provided) => (
                                                                    <div
                                                                        className='bg-slate-200 m-2 p-4 text-md flex justify-between items-center rounded'
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        {editingPlanId === each.id ? (
                                                                            <input
                                                                                value={editValue}
                                                                                onChange={this.handleEditChange}
                                                                                onKeyPress={(e) =>
                                                                                    this.handleEditSave(e, item.id, 'plan')
                                                                                }
                                                                                className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                                            />
                                                                        ) : (
                                                                            <span
                                                                                onDoubleClick={() =>
                                                                                    this.handleDoubleClick(
                                                                                        each.id,
                                                                                        'plan',
                                                                                        each.entry
                                                                                    )
                                                                                }
                                                                            >
                                                                                {each.entry}
                                                                            </span>
                                                                        )}
                                                                        <button
                                                                            className='text-slate-800 ml-4'
                                                                            onClick={() =>
                                                                                this.deletePlanEntry(item.id, each.id)
                                                                            }
                                                                        >
                                                                            X
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    {provided.placeholder}
                                                    <div className='p-2 text-md flex justify-between items-center'>
                                                        <input
                                                            placeholder={`Type in new task here`}
                                                            className='border-slate-100 focus:border-slate-200 rounded w-full placeholder-gray-400'
                                                            value={newPlan[`${item.id}-${status}`] || ''}
                                                            onChange={(e) =>
                                                                this.handleInputChange(e, item.id, 'plan', status)
                                                            }
                                                            onKeyPress={(e) =>
                                                                this.handleKeyPress(e, item.id, 'plan', status)
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    ))}
                                </div>
                            </DragDropContext>
                        )}
                    </div>
                ))}
            </div>
        );
    }
    
}

export default Planning;