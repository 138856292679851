import React, { Component } from 'react';
import axios from 'axios';

class LocalAI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null,
    };
  }

  handleChatCompletion = () => {
    const requestData = {
      model: "gpt-4",
      messages: [
        {
          role: "user",
          content: "What is the weather like in Boston?",
        },
      ],
      tools: [
        {
          type: "function",
          function: {
            name: "get_current_weather",
            description: "Get the current weather in a given location",
            parameters: {
              type: "object",
              properties: {
                location: {
                  type: "string",
                  description: "The city and state, e.g. San Francisco, CA",
                },
                unit: {
                  type: "string",
                  enum: ["celsius", "fahrenheit"],
                },
              },
              required: ["location"],
            },
          },
        },
      ],
      tool_choice: "auto",
    };

    axios
      .post('http://localhost:8080/v1/chat/completions', requestData, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => {
        this.setState({ response: response.data });
      })
      .catch((error) => {
        console.error('There was an error making the request!', error);
      });
  };

  render() {
    const { response } = this.state;

    return (
      <div>
        <h1>Chat Completion</h1>
        <button onClick={this.handleChatCompletion}>Get Weather in Boston</button>
        {response && (
          <div>
            <h2>Response:</h2>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </div>
        )}
      </div>
    );
  }
}

export default LocalAI;
