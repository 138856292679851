import { AITextToObject, AITextToText,  complexInput  } from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};



const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};


// Prompts
const instructionTextToForm = `
    You are a medical practitioner. Write a report as medical notes using only the provided input. Ensure British spelling is used throughout.

    Specific guidelines:
    - Only include treatment if it is explicitly mentioned.
    - Skip the follow-up section if it is not provided.
    - Do not add any additional comments or summaries.

    Return the response as a JSON object with the following fields:
    - presentingIssue
    - history
    - pastHistory
    - childhoodHistory
    - familyHistory
    - parentChildNeed
    - occupation
    - social
    - substance
    - currentMedication
    - allergies
    - treatmentHistory
    - medicationHistory
    - relevantExamination
    - relevantInvestigation
    - devTesting
    - mentalStateExamination
    - risk
    - tools
    - diagnosis
    - predisposing
    - precipitating
    - perpetuating
    - protective
    - reason
    - cando
    - help
    - call
    - talk
    - professional
    - triggers
    - warningSigns
    - copingStrategy
    - support
    - emergency

    Detailed instructions for each field:
    - presentingIssue: Briefly summarise the patient's current issues.
    - history: Document the history of the current patient presentation.
    - pastHistory: Include the patient's past medical and psychiatric history.
    - childhoodHistory: Include any history of childhood trauma, abuse, learning difficulties, school bullying, ADHD, Autism, ODD, or any childhood consultations or medications.
    - familyHistory: Record any family history of mental health issues, such as depression, anxiety, bipolar disorder, OCD, autism, ADHD, or learning difficulties.
    - parentChildNeed: Note any specific needs related to parenting or child care.
    - occupation: Document the patient’s employment history, including current and past employment, duration, and whether they are currently working.
    - social: Detail the patient's living situation, including who they live with, housing status (renting or own home), and relationships with family and friends.
    - substance: List any substance use, including smoking, alcohol, and illicit drugs like ice, cannabis, ecstasy, heroin, cocaine, or methamphetamine.
    - currentMedication: List all medications the patient is currently taking.
    - allergies: List any known allergies.
    - treatmentHistory: Include any previous consultations, emergency attendances, or hospitalisations.
    - medicationHistory: Document previous medications prescribed for mental health issues.
    - relevantExamination: Record any relevant physical examination findings.
    - relevantInvestigation: Include relevant investigations such as blood tests, imaging, or other diagnostic studies.
    - devTesting: Note any previous psychological or developmental testing, such as IQ or cognitive testing.
    - mentalStateExamination: Complete the mental state exam, using default values if not provided:
        {
            Appearance and Behaviour: Good posture, eye contact, appropriate attire,
            Speech: Normal rate, normal volume,
            Mood: Normal,
            Affect: Congruent,
            Thought Content: No delusions or suicidal ideation,
            Thought Form: Normal,
            Perception: No hallucinations,
            Cognition: Not assessed, no memory issues,
            Insight: Good
        }
    - risk: Assess for signs of suicidality, perceptual disturbances, delusions, hallucinations, substance use, and alcohol use.
    - tools: List any assessment tools used, such as K10, DASS21, AQ score, ASRS, PCL-5, ACE.
    - diagnosis: Provide a diagnosis based on the given information; do not fabricate any details.
    - predisposing: List factors that may increase the risk for the presenting problem.
    - precipitating: Document any events or stressors that may trigger or worsen symptoms.
    - perpetuating: Identify conditions that maintain or worsen the problem.
    - protective: Note any of the patient’s competencies, skills, or supportive elements that could be protective.
    - reason: Record the patient’s reasons to live.
    - cando: Specify actions the patient can take to stay safe.
    - help: Indicate where the patient can seek help.
    - call: List who the patient can call for support.
    - talk: Note who the patient can talk to for emotional support.
    - professional: Specify what professional help the patient can access.
    - triggers: List any potential triggers for a relapse of mental health issues.
    - warningSigns: Identify any warning signs of a relapse.
    - copingStrategy: Document any techniques the patient is using to cope, including cognitive and behavioural strategies.
    - support: Note any social and professional support available to the patient.
    - emergency: Outline immediate actions to be taken in case of a relapse.

    Handling data:
    - If a field has no entry, return the field with "".
    - Ensure that the 'problems' field is populated by reviewing the other fields for any issues or concerns that should be documented as problems.
    - Do not include any summaries or additional comments at the end.`

// const instructionTextToPlan = `
//  You are a medical practitioner. Your job is to identify list of problems from the input.
//  Ensure British spelling is used throughout.
//  Return respond as JSON Object.
// Identify any potential issues/problems based on the input. 
// Create a separate entry for each problem and avoid combining multiple problems into a single entry. 
// Each problem should be structured as follows:
//         {
//             id: null,
//             problem: "Problem description",
//             notes: "Details about the problem if applicable",
//             goal: [
//                 {
//                     id: null,
//                     description: "Goal description if applicable"
//                 }
//             ],
//             plan: [
//                 {
//                     id: null,
//                     entry: "Plan description if applicable",
//                     status: "plan"
//                 }
//             ]
//         }
// `

const instructionTextToPlan = `
Your task is to create a list of medical problems from user input and verify if each problem has a corresponding reference in a predefined list (provided as referrences). 
Your other task is to update existing data based on the input.
Use British spelling throughout. 
Provide only the information requested; do not add or summarise. 
Format your response as a flat JSON object.

For each problem in the medical problems list:

1. Check if the problem already exists in existing data, do not create a new one. 
2. If the problem does not exist in existing data, proceed as follows:
    - If the problem exists in referrences, or if a similar problem type is found (e.g. has a lot of debt can be considered as financial trouble, loneliness as social isolation), return a JSON object containing:
        - The exact problem name as it appears in referrences (with the first letter capitalised).
        - A list of goals (with their descriptions and status as 'plan').
        - A list of plans (with their entries and status).
    - If the problem does not exist in referrences or no similar disease type is found, return a JSON object with the input problem and empty lists for goals and plans. 
    - Ensure that every problem in the medical problems list is represented in the output, even if it doesn't have a corresponding entry in referrences.
3. If specify in user input, update goal and plan as follows : 
    - Add, delete or modify goal associated with the problem as per user input.
    - Add, delete or modify plan associated with the problem as per user input.
    - Change status of plan as per user input with the value of "plan" if it's not being done, "doing" if it's currently ongoing, or "done" if it's already performed as per user input
4. Add journey data related to the problem:
    - Fill journey data with any mention of pathology result, imaging, diagnosis, report, medication or any medical procedure associated with the problem as per user input. 
    - If journey data is available for the problem, include it in the response as: [{id: 'null', date: 'yyyy-mm-dd', journey: 'journey data', type: 'pathology', 'imaging', 'medication', 'diagnosis', 'report', or 'procedure'}].
    - If no journey data is available, return an empty journey list: journey: [].
    - For example if there's escitalopram, add as journey: "Start escitalopram 20mg/tab OD", type: "medication"

5. Format the response as follows:
   [{ problem: 'problem entry from references, goal: [list of goals], plan: [list of plans], journey: [associated journey data with the problem] }].

6. Combine both existing data and the new problem list as one list.
`

const instructionTextToReview = `
You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:

Use short sentences and simple points.
Organize the notes in this specific order:
History: Summarize key points of the patient's history concisely.
Examination: Include details of any physical or mental state examination conducted, if applicable.
Assessment: List any identified problems, issues, or diagnoses.Use ICD 10 diagnosis if possible. 
Management: Outline any plans or actions to be taken, if applicable. Do not provide any information if not provided.
Write the content directly underneath each title. If no content is provided for a section, skip that title. Do not include anything outside the input.
`
// AI Methods

// text to Form
export const textToForm = async (text, formData) => {

    // Creating system command
    const data = await AITextToObject(instructionTextToForm, text, formData);

    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const mhcp = {
        presentingIssue: data.presentingIssue,
        history: data.history,
        pastHistory: data.pastHistory,
        childhoodHistory: data.childhoodHistory,
        familyHistory: data.familyHistory,
        parentChildneed: data.parentChildNeed,
        occupation :data.occupation,
        social: data.social,
        substance : data.substance,
        currentMedication: data.currentMedication,
        allergies : data.allergies,

        treatmentHistory: data.treatmentHistory,
        medicationHistory: data.medicationHistory,

        relevantExamination : data.relevantExamination,
        relevantInvestigation: data.relevantInvestigation,
        devTesting : data.devTesting,


        mentalStateExamination: data.mentalStateExamination,
        risk: data.risk,
        tools: data.tools,
        diagnosis: data.diagnosis,

        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhformulation = {
        predisposing: data.predisposing,
        precipitating: data.precipitating,
        perpetuating: data.perpetuating,
        protective: data.protective,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhsafety = {
        reason: data.reason,
        cando: data.cando,
        help: data.help,
        call: data.call,
        talk: data.talk,
        professional: data.professional,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };

    const relapsePrevention = {
        triggers: data.triggers,
        warningSigns: data.warningSigns,
        copingStrategy: data.copingStrategy,
        support: data.support,
        emergency: data.emergency
    }

    if (mhcp && mhformulation && mhsafety && relapsePrevention) {
        return ({mhcp, mhformulation, mhsafety, relapsePrevention})
    } else {
        console.log("Error")
        return null;
    }
}

// // text to plan
// export const textToPlan = async (text, formData) => {
//     const data = await AITextToObject(instructionTextToPlan, text, formData);
//     const plan = regenerateIds(data);
//     return plan;
// }

// text to plan
export const textToPlan = async (input, prevData, referrences) => {

    var processedInput = {
        instruction : instructionTextToPlan,
        user_input : input,
        existing_data: prevData,
        referrences : referrences
    }
    const data = await complexInput(processedInput);
    const plan = regenerateIds(data);
    return plan;
}


// text to review
export const textToReview = async (text, reviews) => {
    const data = await AITextToText(instructionTextToReview, text, reviews);
    const reviewData = [...(reviews || [])];
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const newReview = { date: formattedDate, review: data };
    
    reviewData.push(newReview);
    return reviewData;

}

