import { AITextToObject, AITextToText } from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};

//prompts

const instructionTextToPlan = `
You are a medical practitioner. Your job is to identify list of problems from the input.
Ensure British spelling is used throughout.
Return respond as JSON Object.
Create a separate entry for each problem and avoid combining multiple problems into a single entry. 
Each problem should be structured as follows:
        {
            id: null,
            problem: "Problem description",
            goal: [
                {
                    id: null,
                    description: "Goal description if applicable"
                }
            ],
            plan: [
                {
                    id: null,
                    entry: "Plan description if applicable",
                    status: "plan"
                }
            ]
        }
`

// text to plan
export const textToPlan = async (text) => {
    const data = await AITextToObject(instructionTextToPlan, text);
    const plan = regenerateIds(data);
    return plan;
}