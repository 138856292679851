import React from 'react';
import CryptoJS from 'crypto-js';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigation } from '../../utilities/NavigationContext';
import { textToForm, textToPlan, textToReview } from '../../utilities/MentalHealthAI';
import AudioRecorder from '../../utilities/AudioRecorder';


import Planning from '../MHComponents/Planning';
import Review from '../MHComponents/Review';


// const referrences = [
//     {
//       problem: "Depression",
//       description: "A mood disorder that causes persistent feelings of sadness, loss of interest, and emotional distress.",
//       goals: [
//         "Improve mood and emotional stability",
//         "Increase engagement in daily activities",
//         "Enhance coping skills and emotional regulation"
//       ],
//       todo: [
//         "Daily mood tracking using a journal",
//         "Regular sessions with a therapist",
//         "Engage in 30 minutes of physical exercise at least 3 times a week"
//       ]
//     },
//     {
//       problem: "Generalized Anxiety Disorder (GAD)",
//       description: "A condition characterized by excessive, uncontrollable worry about everyday situations.",
//       goals: [
//         "Reduce frequency of anxious thoughts",
//         "Improve relaxation and coping techniques",
//         "Increase social engagement"
//       ],
//       todo: [
//         "Practice mindfulness meditation for 10 minutes daily",
//         "Cognitive-behavioral therapy (CBT) exercises to challenge anxious thoughts",
//         "Participate in one social activity each week"
//       ]
//     },
//     {
//       problem: "Post-Traumatic Stress Disorder (PTSD)",
//       description: "A mental health condition triggered by experiencing or witnessing a traumatic event.",
//       goals: [
//         "Reduce the impact of traumatic memories",
//         "Enhance emotional regulation and resilience",
//         "Improve sleep and reduce nightmares"
//       ],
//       todo: [
//         "Practice grounding techniques during flashbacks",
//         "Attend weekly trauma-focused therapy sessions",
//         "Use relaxation exercises before bedtime"
//       ]
//     },
//     {
//       problem: "Alcohol Misuse",
//       description: "Excessive consumption of alcohol leading to negative physical, emotional, and social consequences.",
//       goals: [
//         "Reduce or eliminate alcohol intake",
//         "Enhance coping strategies for stress",
//         "Improve overall health and relationships"
//       ],
//       todo: [
//         "Attend alcohol counseling or support groups",
//         "Track alcohol consumption in a journal",
//         "Identify and avoid triggers for drinking"
//       ]
//     },
//     {
//       problem: "Benzodiazepine Dependency",
//       description: "Dependence on benzodiazepines, a type of sedative, which can lead to tolerance, withdrawal, and health complications.",
//       goals: [
//         "Safely reduce or discontinue benzodiazepine use",
//         "Increase use of non-pharmacological relaxation techniques",
//         "Restore healthy sleep patterns"
//       ],
//       todo: [
//         "Follow a medically supervised tapering plan",
//         "Practice deep breathing or meditation",
//         "Engage in sleep hygiene practices"
//       ]
//     },
//     {
//       problem: "Heavy Smoker",
//       description: "Smoking more than 20 cigarettes a day, leading to significant health risks including lung disease and cancer.",
//       goals: [
//         "Reduce or quit smoking",
//         "Improve lung capacity and overall health",
//         "Increase physical activity"
//       ],
//       todo: [
//         "Join a smoking cessation program",
//         "Use nicotine replacement therapies",
//         "Exercise daily to support lung health"
//       ]
//     },
//     {
//       problem: "Cannabis Use Disorder",
//       description: "Problematic use of cannabis that leads to health, social, or functional impairment.",
//       goals: [
//         "Reduce or eliminate cannabis use",
//         "Improve mental clarity and motivation",
//         "Enhance coping strategies for stress"
//       ],
//       todo: [
//         "Attend addiction counseling or support groups",
//         "Track cannabis use patterns and triggers",
//         "Develop alternative stress-relief techniques"
//       ]
//     },
//     {
//       problem: "Caffeine Addiction",
//       description: "Excessive caffeine consumption leading to dependency and symptoms such as insomnia and restlessness.",
//       goals: [
//         "Reduce daily caffeine intake",
//         "Improve sleep quality and overall energy levels",
//         "Manage stress without caffeine"
//       ],
//       todo: [
//         "Gradually reduce caffeine consumption",
//         "Replace caffeinated drinks with water or herbal teas",
//         "Monitor and regulate sleep patterns"
//       ]
//     },
//     {
//       problem: "Financial Constraint",
//       description: "Insufficient financial resources leading to stress and limitations in meeting basic needs.",
//       goals: [
//         "Develop a budget and improve financial stability",
//         "Access financial support services",
//         "Reduce stress related to financial difficulties"
//       ],
//       todo: [
//         "Meet with a financial counselor",
//         "Create a monthly budget",
//         "Track expenses",
//         "Apply for financial aid or benefits"
//       ]
//     },
//     {
//       problem: "Unemployment",
//       description: "Lack of employment leading to financial instability and emotional distress.",
//       goals: [
//         "Find stable employment",
//         "Improve skills and employability",
//         "Reduce stress and anxiety related to joblessness",
//         "Update Curriculum Vitae"
//       ],
//       todo: [
//         "Attend job skills training programs",
//         "Update CV and apply for job opportunities",
//         "Network with potential employers or agencies"
//       ]
//     },
//     {
//       problem: "Homelessness",
//       description: "Lack of stable housing resulting in vulnerability and health risks.",
//       goals: [
//         "Secure safe and stable housing",
//         "Improve access to essential services",
//         "Enhance personal safety and well-being"
//       ],
//       todo: [
//         "Contact housing services or shelters",
//         "Apply for housing benefits or subsidies",
//         "Develop a long-term housing plan"
//       ]
//     },
//     {
//       problem: "Intimate Partner Violence",
//       description: "Experiencing physical, emotional, or sexual abuse in a relationship, leading to trauma and safety concerns.",
//       goals: [
//         "Ensure safety and well-being",
//         "Access support services for abuse survivors",
//         "Recover from trauma and build healthier relationships"
//       ],
//       todo: [
//         "Contact a domestic violence hotline",
//         "Create a safety plan and seek legal protection",
//         "Attend counseling or support groups"
//       ]
//     },
//     {
//       problem: "Not Driving",
//       description: "Inability or decision not to drive, potentially leading to reduced mobility and independence.",
//       goals: [
//         "Maintain mobility and independence",
//         "Develop alternative transportation options",
//         "Reduce isolation due to lack of driving"
//       ],
//       todo: [
//         "Explore public transportation or rideshare options",
//         "Coordinate with friends or family for transportation",
//         "Attend driver rehabilitation or driving lessons if applicable"
//       ]
//     },
//     {
//       problem: "Legal Issues",
//       description: "Involvement in legal problems causing emotional distress and financial burden.",
//       goals: [
//         "Resolve legal problems effectively",
//         "Reduce stress and anxiety related to legal proceedings",
//         "Improve long-term legal and financial stability"
//       ],
//       todo: [
//         "Consult with a lawyer for legal advice",
//         "Attend court hearings as required",
//         "Seek counseling for emotional support"
//       ]
//     },
//     {
//       problem: "Lack of Activities",
//       description: "Limited engagement in social or physical activities, leading to boredom, isolation, and poor mental health.",
//       goals: [
//         "Increase participation in social and recreational activities",
//         "Enhance social connections and mental well-being",
//         "Develop new hobbies and interests"
//       ],
//       todo: [
//         "Join a local club or group",
//         "Schedule regular outings with friends or family",
//         "Start a new hobby or class"
//       ]
//     }
//   ];
  
  


const secretStorage = process.env.REACT_APP_SECRET_STORAGE;


function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    if (!Array.isArray(problems)) {
        return []; // Return an empty array or handle the error as needed
    }

    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        priority : "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    }));
};


const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};


class MentalHealth extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');

        this.state = {
            referrences: [],
            dashboard: [],
            formData: this.initializeFormData(props.patientData),
            adminData: this.props.adminData,
            isEditingMHCP: false,
            isEditingFormulation: false,
            isEditingSafety: false,
            isEditingRelapse: false,
            //AI section
            isLoading: false,
            //active tab
            activeTab: 'background',
        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    // componentDidMount() {
    //     const encryptedAdmin = localStorage.getItem('admin');
    //     if (encryptedAdmin) {
    //         const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
    //         const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //         const referrences = adminData.contacts;
    //         const dashboard = adminData.dashboard;
    //         this.setState({ referrences: referrences, dashboard: dashboard });
    //     }
    // }

    initializeFormData = (patientData) => {

        const mhcp = {
            presentingIssue: "",
            history: "",
            pastHistory: "",
            childhoodHistory: "",
            familyHistory: "",
            parentChildNeed : "",
    
            occupation: "",
            social : "",
            substance: "",
            currentMedication: "",
            allergies: "",
    
    
            treatmentHistory: "",
            medicationHistory: "",
    
            relevantExamination : "",
            relevantInvestigation: "",
            devTesting: "",
    
            mentalStateExamination: {},
            risk: "",
            tools: "",
            diagnosis: "",
            date: new Date()
        }

        const mhformulation = {
            predisposing: "",
            precipitating: "",
            perpetuating:"",
            protective: "",
            date: new Date()  
        }
        const mhsafety= {
            reason: "",
            cando: "",
            help: "",
            call: "",
            talk: "",
            professional: "",
            date: new Date()
        }
    
        const relapsePrevention = {
            triggers: "",
            warningSigns: "",
            copingStrategy :"",
            support: "",
            emergency: "",
            date: new Date()
        }

        // Ensure healthassessment has all the necessary fields
        return {
            ...patientData,
            mhcp: {
                ...mhcp,
                ...patientData.mhcp,
            },
            mhformulation : {
                ...mhformulation,
                ...patientData.mhformulation
            },
            mhsafety : {
                ...mhsafety,
                ...patientData.mhsafety
            },
            relapsePrevention:{
                ...relapsePrevention,
                ...patientData.relapsePrevention
            },
            mhproblems: patientData.mhproblems || [], 
            mhreviews: patientData.mhreviews || []
        };
    };

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({
                formData: this.initializeFormData(this.props.patientData),
            });
        }
    }

    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    toggleEdit = (section) => {
        this.setState((prevState) => {
            // If we're switching from editing to saving, update the parent data
            if (prevState[section]) {
                this.updatePatientData(); // Call updatePatientData to send the updated formData to the parent
            }
    
            return {
                [section]: !prevState[section] // Toggle editing state
            };
        });
    };
    
    handleInputChange = (section, key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [section]: {
                    ...prevState.formData[section],
                    [key]: value,
                },
            },
        }));
    };
    
    
    /////  AI SECTION

    // After receiving transcription from Voice
    AITranscription = async (text, toastId) => {

        var formData = this.state.formData

        // filling up form
        toast.update(toastId, {
            render: 'Filling up Form..',
            autoClose: false,
            hideProgressBar: false
        });

        var respond = await textToForm(text, formData);

        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                mhcp: respond.mhcp,
                mhformulation: respond.mhformulation,
                mhsafety: respond.mhsafety,
                relapsePrevention: respond.relapsePrevention,
            },
        }), this.updatePatientData);

        // organising Plan
        toast.update(toastId, {
            render: 'Organising Plan..',
            autoClose: false,
            hideProgressBar: false
        });

        var adminData = this.state.adminData;
        var referrences = adminData.referrences
        var respond2 = await textToPlan(text, formData.mhproblems, referrences);
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                mhproblems: respond2
            },
        }));

        // writing review
        toast.update(toastId, {
            render: 'Writing Review...',
            autoClose: false,
            hideProgressBar: false
        });
        var respond3 = await textToReview(text, formData.mhreviews);
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                mhreviews: respond3,
            }
        })); 


        // all done
        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });

    };

    
    ////// UPDATE FROM CHILD COMPONENT 

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                mhproblems: updatedProblems,
            },
        }), this.updatePatientData);
    };

    // Update data from Review
    handleReviewUpdate = (updatedReviews) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            mhreviews: updatedReviews,
          },
        }), this.updatePatientData);
    };


    // Render Form
    renderBackground() {
        const { isEditingMHCP, isEditingFormulation, isEditingSafety, isEditingRelapse, formData } = this.state;

        // // Add checks for formData and its sub-properties
        // if (!formData || !formData.mhcp || !formData.mhformulation || !formData.mhsafety || !formData.relapsePrevention) {
        //     return <div>Select File, then open, to open patient file.</div>;  
        // }
    
        const { mhcp, mhformulation, mhsafety, relapsePrevention } = formData;


        // setting up Fields and labels for Forms
        const profileFields = [
            { label: 'Presenting Issue', key: 'presentingIssue' },
            { label: 'History', key: 'history' },
            { label: 'Past History', key: 'pastHistory' },
            { label: 'Childhood History', key: 'childhoodHistory' },
            { label: 'Family History', key: 'familyHistory' },
            { label: "Parent and Children Needs", key: "parentChildNeed"},

            { label: "Employment History", key: "occupation"},
            { label: "Social History", key: "social"},
            { label: "Substance Use History", key: "substance"},
            { label: "Current Medication", key: "currentMedication"},
            { label: "Allergies", key: "allergies"},

            { label: "Relevant Physical Examination", key: "relevantExamination" },
            { label: "Relevant Investigations", key: "relevantInvestigation"},
            { label: "Previous psychological and Development Testing", key: "devTesting"},

            { label: 'Treatment History', key: 'treatmentHistory' },
            { label: 'Medication History', key: 'medicationHistory' },
       
            { label: 'Mental State Examination', key: 'mentalStateExamination' },
            { label: 'Risk and Comorbidities', key: 'risk' },
            { label: 'Diagnosis', key: 'diagnosis' },
            { label: 'Date', key: 'date' }
        ];

        const formulationFields = [
            { label: 'Predisposing factor', key: 'predisposing' },
            { label: 'Precipitating factor', key: 'precipitating' },
            { label: 'Perpetuating factor', key: 'perpetuating' },
            { label: 'Protective factor', key: 'protective' },
            { label: 'Date', key: 'date' }
        ];

        const safetyFields = [
            { label: 'Reason to Live', key: 'reason' },
            { label: 'What I can do to keep me safe', key: 'cando' },
            { label: 'Where can I seek help', key: 'help' },
            { label: 'Who can I call', key: 'call' },
            { label: 'Who can I talk to', key: 'talk' },
            { label: 'What professional help can I reach', key: 'professional' },
            { label: 'Date', key: 'date' }
        ];

        const preventionFields = [
            { label: "Possible Triggers", key: "triggers"},
            { label: "Warning Signs", key: "warningSigns"},
            { label: "Coping Strategies", key: "copingStrategy"},
            { label: "Support System", key: "support"},
            { label: "Emergency Plan", key: "emergency"},
            { label: 'Date', key: 'date' },
         
        ]

        return (
   
                <div className='grid grid-cols-6 xs:grid-cols-2 sm:grid-cols-4'>
                <div className='col-span-6 xs:col-span-2 sm:col-span-2'>  <AudioRecorder onInputUpdate={this.AITranscription} /></div>
 
                {/* Mental Health Care Plan */}
                <div className='col-span-4 row-span-3 p-4 bg-slate-100 rounded shadow-md m-2 xs:col-span-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-xl font-bold text-center'>History</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingMHCP')}
                            className={`px-4 py-2 rounded text-white ${isEditingMHCP ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingMHCP ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {profileFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhcp[field.key]}
                                        readOnly={!isEditingMHCP}
                                        onChange={(e) => this.handleInputChange('mhcp', field.key, e.target.value)}
                                        disabled={!isEditingMHCP}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            typeof mhcp[field.key] === 'object' && mhcp[field.key] !== null
                                                ? Array.isArray(mhcp[field.key])
                                                    ? mhcp[field.key].join('\n')
                                                    : Object.entries(mhcp[field.key]).map(([key, value]) => `${key}: ${value}`).join('\n')
                                                : mhcp[field.key]
                                        }
                                        readOnly={!isEditingMHCP}
                                        onChange={(e) => this.handleInputChange('mhcp', field.key, e.target.value)}
                                        disabled={!isEditingMHCP}
                                    />
                                
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Formulation */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Formulation</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingFormulation')}
                            className={`px-4 py-2 rounded text-white ${isEditingFormulation ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingFormulation ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {formulationFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhformulation[field.key]}
                                        readOnly={!isEditingFormulation}
                                        onChange={(e) => this.handleInputChange('mhformulation', field.key, e.target.value)}
                                        disabled={!isEditingFormulation}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(mhformulation[field.key])
                                                ? mhformulation[field.key].join('\n')
                                                : mhformulation[field.key]
                                        }
                                        readOnly={!isEditingFormulation}
                                        onChange={(e) => this.handleInputChange('mhformulation', field.key, e.target.value)}
                                        disabled={!isEditingFormulation}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Safety Plan */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Safety Plan</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingSafety')}
                            className={`px-4 py-2 rounded text-white ${isEditingSafety ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingSafety ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4  xs:grid-cols-1'>
                        {safetyFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={mhsafety[field.key]}
                                        readOnly={!isEditingSafety}
                                        onChange={(e) => this.handleInputChange('mhsafety', field.key, e.target.value)}
                                        disabled={!isEditingSafety}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(mhsafety[field.key])
                                                ? mhsafety[field.key].join('\n')
                                                : mhsafety[field.key]
                                        }
                                        readOnly={!isEditingSafety}
                                        onChange={(e) => this.handleInputChange('mhsafety', field.key, e.target.value)}
                                        disabled={!isEditingSafety}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {/* Relapse Prevention Plan */}
                <div className='col-span-2 p-4 bg-slate-100 rounded shadow-md m-2'>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold text-center'>Relapse Prevention Plan</h2>
                        <button
                            onClick={() => this.toggleEdit('isEditingRelapse')}
                            className={`px-4 py-2 rounded text-white ${isEditingRelapse ? 'bg-sky-600 hover:bg-sky-700' : 'bg-yellow-500 hover:bg-yellow-600'}`}
                        >
                            {isEditingRelapse ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className='mt-4 grid grid-cols-2 gap-4 xs:grid-cols-1'>
                        {preventionFields.map((field) => (
                            <div key={field.key}>
                                <h3 className='text-sm font-semibold'>{field.label}</h3>
                                {field.label === 'Date' && field.key === 'date' ? (
                                    <input
                                        type="date"
                                        className='w-full text-black w-[90%] text-sm border-gray-200'
                                        value={relapsePrevention[field.key]}
                                        readOnly={!isEditingRelapse}
                                        onChange={(e) => this.handleInputChange('relapsePrevention', field.key, e.target.value)}
                                        disabled={!isEditingRelapse}
                                    />
                                ) : (
                                    <textarea
                                        className='w-full h-40 resize-none text-black w-[90%] text-sm border-gray-200'
                                        value={
                                            Array.isArray(relapsePrevention[field.key])
                                                ? relapsePrevention[field.key].join('\n')
                                                : relapsePrevention[field.key]
                                        }
                                        readOnly={!isEditingRelapse}
                                        onChange={(e) => this.handleInputChange('relapsePrevention', field.key, e.target.value)}
                                        disabled={!isEditingRelapse}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                </div>
        );

    }

    render() {
        const { formData, activeTab } = this.state;
        var adminData = this.state.adminData;
        var referrences = adminData.referrences
       
        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
             
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Mental Health Care Plan</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                    <div className='bg-slate-100 rounded w-92 text-center'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'background' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('background')}
                    >
                        Background
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'review' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('review')}
                    >
                        Review
                    </button>
                    </div>
                </div>
                <div className='tabContent col-span-6'>
                    {activeTab === 'background' &&  this.renderBackground()}
                    {activeTab === 'planning' && formData.mhproblems && (
                    <Planning problems={formData.mhproblems} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription} />
                    )}
                    {activeTab === 'review' && formData.mhreviews && (
                    <Review reviews={formData.mhreviews} onReviewUpdate={this.handleReviewUpdate} onVoiceInput={this.AITranscription}/>
                    )}
                
                </div>
                <ToastContainer />
            </div>
        );     
        
    }
    
}

export default MentalHealth;
