import React from 'react';
import axios from 'axios';
import { useNavigation, withNavigation } from '../../utilities/NavigationContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TeamForm from './Forms/TeamForm';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

function generateRandomId() {
    // Generate a random ID (basic implementation)
    return Math.random().toString(36).substring(2, 9);
  };
  

class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            selectedData: {},
            isEditing: false,
            teamData: [],
        };
    }

   componentDidMount() {
        this.fetchPatientTeam();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
          this.setState({ teamData: this.props.patientData.teams });
        }
    }
    
    fetchPatientTeam = async () => {
        const patientData = this.props.patientData;
        const teams = patientData.teams;
        this.setState({ teamData: teams });
    };

    handleShowForm = (data) => {
        this.setState({ showForm: true, selectedData: data, isEditing: true });
    }

    handleAddForm = () => {
        this.setState({ showForm: true, selectedData: {}, isEditing: false });
    }

    handleCancel = () => {
        this.setState({ showForm: false });
    }

    handleSave = async (data) => {
        let updatedTeam = [];
    
        // Check if teamData is initialized as an array
        if (Array.isArray(this.state.teamData) && this.state.teamData.length > 0) {
            if (data._id) {
                // Update an existing team member
                updatedTeam = this.state.teamData.map(member =>
                    member._id === data._id ? data : member
                );
            } else {
                // Add a new team member
                updatedTeam = [...this.state.teamData, { ...data, _id: generateRandomId() }];
            }
        } else {
            // If teamData is empty, initialize it with the new data
            updatedTeam = [{ ...data, _id: generateRandomId() }];
        }
        // Set the updated team data to state
        this.setState({ teamData: updatedTeam });
    
        // Update patient data with the new team data
        var patientData = { ...this.props.patientData }; 
        patientData.teams = updatedTeam; 

        this.props.updatePatientData(patientData);
    
        // Handle cancel and display toast notification
        this.handleCancel();
    
        toast.success("Team member updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false
        });
    };
    

    handleDelete = async (id) => {
        const updatedTeam = this.state.teamData.filter(member => member._id !== id);
        this.setState({ teamData: updatedTeam });
        var patientData = { ...this.props.patientData }; 
        patientData.teams = updatedTeam; 

        this.props.updatePatientData(patientData);

        toast.success("Team member deleted!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false
        });
    };

    renderContent() {
        const team = this.state.teamData;

        if (team && team.length > 0) {
            return team.map((person, index) => {
                return (
                    <div 
                        className='p-2 m-1 bg-white shadow-sm rounded border-l-4 border-sky-300' 
                        key={index}
                        onClick={() => this.handleShowForm(person)}
                    >
                        <p className='text-xs font-semibold'>{person.firstName} {person.lastName}</p>
                        <p className='text-xs'>{person.profession}</p>
                        <button className='text-xs text-sky-500 underline' onClick={(e) => {
                            e.stopPropagation(); 
                            this.handleShowForm(person)}}
                        >
                        Edit
                        </button>
                    </div>
                )
            })
        }  
    }

    render() {
        return (
            <div className='col-span-2 rounded shadow-sm p-2 m-2 bg-slate-100 relative sm:col-span-6 xs:col-span-6'>
                <p className='text-lg font-semibold p-1'>Team</p>
                <div className='grid grid-cols-3 gap-2'>{this.renderContent()}</div>
                <div>
                    <button className='absolute right-2 bottom-1 bg-sky-600 hover:bg-sky-700 text-white text-xs w-32 p-2 m-1 rounded shadow-sm' onClick={this.handleAddForm}>
                        Add Team
                    </button>
                </div>
                {this.state.showForm && (
                    <TeamForm 
                        data={this.state.selectedData} 
                        style={{ display: this.state.showForm ? 'block' : 'none' }} 
                        onCancel={this.handleCancel}
                        onSave={this.handleSave}
                        onDelete={this.handleDelete}
                    />
                )}
                <ToastContainer />
            </div>
        )
    }
}

export default Team;
