import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigation } from '../../utilities/NavigationContext';
import { useAuth } from '../../utilities/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        email: '',
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { email } = this.state;
        

        try {
            const response = await axios.post(`${EndPoint}/user/forgot-password`, { email });

            if (response.status === 200) {
                toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
                });
        
                // this.props.setAuth(prevAuth => ({ ...prevAuth, isLoggedIn: true }));
                // this.props.navigate('/patientselect');
            } else {
                toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar : false
                });
        
            }
        } catch (error) {
            
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar : false
        });
        }
    };


    render() {
        return (
        <div className='flex flex-col justify-center items-center h-screen'>
            <ToastContainer />
            <form onSubmit={this.handleSubmit} className='flex flex-col gap-4 mt-6 bg-slate-100 p-10 rounded shadow-lg'>
            <h2 className='font-semibold text-lg text-center'>Forgot Password ?</h2>
            <p>Enter your registered email to reset your password</p>
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
                className='border border-slate-300 rounded shadow-sm m-1 p-2'
            />
            
            <div className='text-center'><button type="submit" className='text-white bg-sky-600 hover:bg-sky-700 rounded shadow-sm w-32 p-2 m-1'>Reset Password</button></div>
            <p className='text-center'>Remember your password? <button onClick={()=> {  this.props.navigate('/login');}} className='text-red-700 hover:text-red-800'>Login here</button></p>
            </form>
            <div className='py-4 inline-block'>Don't have an account? <div className='text-red-700'><Link to="/register">Click here to register.</Link></div></div>
        </div>
        );
    }
}

// Higher-order component to consume the NavigationContext
const ForgotPasswordWithNavigation = (props) => {
  const navigate = useNavigation();
  const [, setAuth] = useAuth(); // Get the setAuth function from AuthContext

  return <ForgotPassword {...props} navigate={navigate} setAuth={setAuth} />;
};

export default ForgotPasswordWithNavigation;
