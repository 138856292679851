
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';

const CTA = () => {
    const navigate = useNavigate();
    return (
            <div>
            <button               
                onClick={() => navigate('/register')}
                className='my-10 mx-4 bg-sky-700 hover:bg-sky-800 rounded w-52 p-4 shadow text-white text-xl font-bold'>Sign Up
            </button>
            </div>

    )
}


export class GridRightImage extends Component {

    render() {
        return (
            <div className='grid grid-cols-2 gap-10 py-24 mt-6 mx-24 xs:grid-cols-1 xs:m-2'>
                <div className='align-items-right'>
                    <h1 className='text-4xl font-bold text-right mb-4 text-slate-700 mx-4 xs:text-center xs:mx-2'>{this.props.title}</h1>
                    <p className='text-2xl text-right mx-4 xs:text-center xs:text-xl xs:mx-0'    style={{ whiteSpace: 'pre-line' }}>{this.props.subtitle}</p>
                    <div className='flex flex-col items-end xs:items-center'><CTA /></div>
                </div>
                <div>
                    <img src={this.props.image} alt="Consultation Image" className="w-[700px] object-contain shadow-xl float-left rounded sm:mt-14"/>
                
                </div>
               
            </div>
        )
    }
}


export class GridLeftImage extends Component {

    render() {
        return (
            <div className='grid grid-cols-2 gap-10 py-24 mt-6 mx-24 xs:grid-cols-1 xs:m-2'>
                <div>
                    <img src={this.props.image} alt="Consultation Image" className="w-[700px] object-contain shadow-xl float-right rounded sm:mt-14"/>
                </div>
                <div className='align-items-left'>
                    <h1 className='text-4xl font-bold text-left mb-4 text-slate-700 mx-4 xs:text-center xs:mx-2'>{this.props.title}</h1>
                    <p className='text-2xl text-left mx-4 xs:text-center xs:text-xl xs:mx-0' style={{ whiteSpace: 'pre-line' }}>{this.props.subtitle}</p>
                    <div className='flex flex-col items-start xs:items-center'><CTA /></div>
                </div>
             

            </div>
        )
    }
}


export class FeatureListRow extends Component {

    render() {
        return (
            <div className='flex flex-col py-24 m-6'>
             
                <div className='text-center'>
                    <p className='text-5xl text-sky-800 font-bold mx-80 my-10'>{this.props.title}</p>
                    <p className='text-2xl m-4'>{this.props.subtitle}</p>
                </div>
                <div className='flex justify-center p-6'>
                    {this.props.features.map(item => {
                        return (
                            <div className='p-4 rounded m-2 shadow hover:bg-slate-50'>
                                <div className='text-lg font-semibold text-center'>{item.title}</div>
                                <div className='text-md'>{item.description}</div>
                                <div className='flex justify-center mt-6'> <img src={item.image} alt="Consultation Image" className="w-[300px] object-contain shadow-xl rounded"/></div>
                            </div>
                        )
                    })}
                </div>
             

            </div>
        )
    }
}

export class FeatureListLeft extends Component {
    constructor(props) {
        super(props);


        this.state = {
            content: 0,
        }
    }

    showFeature(index) {
        this.setState({content:index})
    }

    render() {
        var content = this.props.features[this.state.content].image;
        return (
            <div className='flex flex-col p-4 mx-6 my-20'>
             
                <div className='text-center'>
                    <p className='text-4xl mx-80 my-4 text-slate-800 font-bold'>{this.props.title}</p>
                    <p className='text-xl'>{this.props.subtitle}</p>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='p-10'>
                    {this.props.features.map((item, index) => {
                        return (
                            <div key={index} className='p-4 bg-slate-100 rounded m-2 hover:bg-sky-200 cursor-pointer' onClick={() => this.showFeature(index)}>
                                <div className='text-lg font-semibold'>{item.title}</div>
                                <div className='text-md'>{item.description}</div>
                            </div>
                        )
                    })}
                    </div>
                    <div className='p-4 mt-10'>
                        <img src={content} alt="Consultation Image" className="w-[800px] object-contain shadow-xl rounded"/>
                    </div>
                </div>
             

            </div>
        )
    }
}

export class FeatureListRight extends Component {
    constructor(props) {
        super(props);


        this.state = {
            content: 0,
        }
    }

    showFeature(index) {
        this.setState({content:index})
    }

    render() {
        var content = this.props.features[this.state.content].image;
        return (
            <div className='flex flex-col p-4 m-6'>
             
                <div className='text-center'>
                <p className='text-5xl text-sky-800 font-bold mx-80 my-10'>{this.props.title}</p>
                <p className='text-2xl m-4'>{this.props.subtitle}</p>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='p-4 mt-10'>
                        <img src={content} alt="Consultation Image" className="w-[800px] object-contain shadow-xl rounded"/>
                    </div>
                    <div className='p-10'>
                    {this.props.features.map((item, index) => {
                        return (
                            <div key={index} className='p-4 bg-slate-100 rounded m-2 hover:bg-sky-200 cursor-pointer' onClick={() => this.showFeature(index)}>
                                <div className='text-lg font-semibold'>{item.title}</div>
                                <div className='text-md'>{item.description}</div>
                            </div>
                        )
                    })}
                    </div>
                
                </div>
             

            </div>
        )
    }
}

export class FeatureListTop extends Component {
    constructor(props) {
        super(props);


        this.state = {
            content: 0,
        }
    }

    showFeature(index) {
        this.setState({content:index})
    }

    render() {
        var content = this.props.features[this.state.content].image;
        return (
            <div className='flex flex-col p-4 m-6'>
             
                <div className='text-center'>
                    <p className='text-5xl text-sky-800 font-bold mx-80 my-10'>{this.props.title}</p>
                    <p className='text-2xl m-4'>{this.props.subtitle}</p>
                </div>
                <div className='flex flex-col gap-4'>
              
                    <div className='p-10 flex justify-center'>
                    {this.props.features.map((item, index) => {
                        return (
                            <div key={index} className='p-4 bg-slate-100 rounded m-2 hover:bg-sky-200 cursor-pointer w-full' onClick={() => this.showFeature(index)}>
                                <div className='text-lg font-semibold text-center'>{item.title}</div>
                                <div className='text-md text-center'>{item.description}</div>
                            </div>
                        )
                    })}
                    </div>
                    <div className='p-4 flex justify-center'>
                        <img src={content} alt="Consultation Image" className="w-[1200px] object-contain shadow-xl rounded"/>
                    </div>
                
                </div>
             

            </div>
        )
    }
}

export class BentoFeatures extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        const content = this.props.content;
        return (
            <div className='my-14 sm:my-10 md:my-8'>
            <div className='text-center'>
                <p className='text-4xl text-slate-700 font-bold mx-80 my-10 xs:text-4xl xs:mx-4 xs:my-4 sm:mx-4'>{this.props.title}</p>
            </div>
       
            <div className='grid grid-cols-3 grid-rows-3 gap-4 justify-items-stretch mx-auto text-center w-[60%] h-[700px] xs:grid-cols-2 xs:h-[1000px] xs:w-auto xs:m-4 sm:m-4 sm:w-auto sm:h-[800px] md:m-4 md:w-auto'>

             
                <div className='bg-sky-700 rounded shadow-lg col-span-2 row-span-2 hover:bg-sky-600 xs:mt-20'>
                    <div className='text-4xl text-sky-100 font-bold m-10 xs:text-center xs:text-3xl'>{this.props.content[0].title}</div>
                    <div className='text-2xl text-sky-200 m-10 xs:text-center xs:text-xl'>{this.props.content[0].notes}</div>
        
                </div>
                {this.props.content.slice(1).map (item => {
                    return (
                    <div className='bg-slate-100 rounded shadow-lg hover:bg-sky-200'>
                        <div className='text-2xl text-sky-800 font-semibold m-6 mb-2 xs:text-xl xs:text-center'>{item.title}</div>
                        <div className='text-lg text-black m-6 xs:text-sm xs:text-center'>{item.notes}</div>
                    </div>
                    )

                })}
                
            </div>
            </div>
        )
    }

}
