import React, { Component } from 'react';
import AudioRecorder from '../../utilities/AudioRecorder';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [],
      editingIndex: null,
      newDate: '',
      newReview: '',
      editDate: '',
      editReview: '',

    };
  }

  componentDidMount() {
    this.setState({ reviews: this.props.reviews });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reviews !== this.props.reviews) {
      this.setState({ reviews: this.props.reviews });
    }
  }

  handleAddReview = () => {
    const { newDate, newReview, reviews } = this.state;
    if (newDate && newReview) {
      const updatedReviews = [...reviews, { date: newDate, review: newReview }];
      this.setState({
        reviews: updatedReviews,
        newDate: '',
        newReview: ''
      });
      this.props.onReviewUpdate(updatedReviews); // Update parent with new reviews
    }
  };

  handleSaveEdit = () => {
    const { editDate, editReview, reviews, editingIndex } = this.state;
    if (editingIndex !== null) {
      const updatedReviews = [...reviews];
      updatedReviews[editingIndex] = { date: editDate, review: editReview };

      this.setState({
        reviews: updatedReviews,
        editingIndex: null,
        editDate: '',
        editReview: '',

      });
      this.props.onReviewUpdate(updatedReviews); // Update parent with edited reviews
    }
  };

  handleReviewClick = (index) => {
    const selectedReview = this.state.reviews[index];
    this.setState({
      editingIndex: index,
      editDate: selectedReview.date,
      editReview: selectedReview.review,
   
    });
  };


  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.state.editingIndex !== null ? this.handleSaveEdit() : this.handleAddReview();
    }
  };

  handleInputUpdate = async (newTranscription, toastId) => {

    this.props.onVoiceInput(newTranscription, toastId)
  };


  render() {
    const { editDate, editReview } = this.state;

    return (
      <div className="mx-10 grid grid-cols-2 gap-4">
        <div className='col-span-2'>  <AudioRecorder onInputUpdate={this.handleInputUpdate} /></div>
        {/* Left Column - List of Reviews */}
        <div>
          {this.state.reviews.map((review, index) => (
            <div
              key={index}
              onClick={() => this.handleReviewClick(index)}
              className="bg-slate-100 w-full p-4 mb-4 shadow rounded cursor-pointer"
            >
              <p className="text-sm italic mb-2">{formatDate(review.date)}</p>
              <p className="text-md overflow-hidden whitespace-nowrap text-ellipsis">{review.review}</p>


            </div>
          ))}
        </div>
        {/* Right Column - Form */}
        <div
          onDoubleClick={this.handleDoubleClick}
          className="border border-gray-200 p-4 rounded cursor-pointer"
        >
          <div style={{ marginBottom: '20px' }}>
            <div>
              <input
                type="date"
                value={editDate || this.state.newDate}
                className="border border-gray-300 rounded p-2 mb-4 w-full"
                onChange={(e) =>
                  this.state.editingIndex !== null
                    ? this.setState({ editDate: e.target.value })
                    : this.setState({ newDate: e.target.value })
                }
                placeholder="Enter date"

              />
              <textarea
                value={editReview || this.state.newReview}
                className="border border-gray-300 rounded p-2 mb-4 h-[700px] w-full resize-none"
                onChange={(e) =>
                  this.state.editingIndex !== null
                    ? this.setState({ editReview: e.target.value })
                    : this.setState({ newReview: e.target.value })
                }
                placeholder="Enter review notes"
            
              />
            </div>
            <button
              onClick={
                this.state.editingIndex !== null ? this.handleSaveEdit : this.handleAddReview
              }
              className="bg-sky-600 hover:bg-sky-700 text-white p-2 rounded w-full"
            >
              {this.state.editingIndex !== null ? 'Save Update' : 'Add Review'}
            </button>
          </div>
        </div>

   
      </div>
    );
  }
}

export default Review;
