import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigation } from '../utilities/NavigationContext';
import { useAuth } from '../utilities/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    const loginEndpoint = `${EndPoint}/user/login`;

    try {
      const response = await axios.post(loginEndpoint, { email, password }, { withCredentials: true });

      if (response.status === 200) {
        toast.success("Login Success !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar : false
        });
  
        this.props.setAuth(prevAuth => ({ ...prevAuth, isLoggedIn: true }));
        this.props.navigate('/master');
      } else {
        toast.error("Login Failed !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar : false
        });
  
      }
    } catch (error) {
      toast.error("There was an error during login !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideProgressBar : false
      });
    }
};


  render() {
    return (
      <div className='flex flex-col justify-center items-center h-screen'>
        <ToastContainer />
        <form onSubmit={this.handleSubmit} className='flex flex-col gap-4 mt-6 bg-slate-100 p-10 rounded shadow-lg'>
        <h2 className='font-semibold text-lg text-center'>Login</h2>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
            className='border border-slate-300 rounded shadow-sm m-1 p-2'
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleInputChange}
            required
            className='border border-slate-300 rounded shadow-sm m-1 p-2'
          />

          <p tabIndex="-1">Forgot your password?  <button  type='button' tabIndex="-1"  onClick={()=> {  this.props.navigate('/forgotpassword')}} className='text-red-700 hover:text-red-800'>Reset here</button></p>
          <div className='text-center'><button type="submit" className='text-white bg-sky-600 hover:bg-sky-700 rounded shadow-sm w-32 p-2 m-1'>Login</button></div>
        </form>
        <div className='py-4 inline-block'>Don't have an account? <div className='text-red-700'><Link to="/register">Click here to register.</Link></div></div>
      </div>
    );
  }
}

// Higher-order component to consume the NavigationContext
const LoginWithNavigation = (props) => {
  const navigate = useNavigation();
  const [, setAuth] = useAuth(); // Get the setAuth function from AuthContext

  return <Login {...props} navigate={navigate} setAuth={setAuth} />;
};

export default LoginWithNavigation;
