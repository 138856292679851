import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../../utilities/NavigationContext';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { sortingText, LLMTextToJSON,  writeNotes } from '../../utilities/AI';
import AudioRecorder from '../../utilities/AudioRecorder';


const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

const formatTimeStamp = (dateString) => {
    const date = new Date(dateString);
    
    // Format date as DD/MM/YYYY
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    
    // Format time as hh:mm AM/PM
    const formattedTime = date.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    return `${formattedDate} on ${formattedTime}`;
};

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        goal: problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })),
        plan: problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })),
    }));
};

const combineNotesByCategory = (input) => {
    const combined = {};

    input.forEach((item) => {
        if (combined[item.category]) {
            combined[item.category] += ' ' + item.notes;
        } else {
            combined[item.category] = item.notes;
        }
    });

    return Object.keys(combined).map((key) => ({
        category: key,
        notes: combined[key]
    }));
};

const filterCategories = (input, categories) => {
    return input.filter(item => categories.includes(item.category));
};

function generateRandomDate(start, end) {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('T')[0]; // Format as yyyy-mm-dd
}

function addFieldsToProblems(problems) {

    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    return problems.map(problem => ({
      ...problem,
      dateAdded: formattedDate,
      id: generateId(),
      priority: "Low",
      collapsed: true,
      review: [],
      journey: problem.journey.map(g => ({
        ...g,
        id: generateId(),
        dateAdded: formattedDate
      })),
      goal: problem.goal.map(g => ({
        ...g,
        id: generateId(),
        dateAdded: formattedDate
      })),
      plan: problem.plan.map(p => ({
        ...p,
        id: generateId(),
        dateAdded: formattedDate
      }))
    }));
}


//   example data
const sortingExample = [
    {
        notes: "Patient reports feeling anxious and having trouble sleeping.",
        category: "mentalHealth"
    },
    {
        notes: "They have been smoking heavily and drinking alcohol daily.",
        category: "substance"
    },
    {
        notes: "They also mention that they have been taking paracetamol for chronic back pain.",
        category: "medications"
    },
    {
        notes: "The patient lives alone but has a supportive network of friends. They are concerned about their financial situation due to recent job loss.",
        category: "social"
    },
    {
        notes: "The patient has been experiencing chest pain for the past two weeks.",
        category: "chronicDisease"
    },
    {
        notes: "They have a history of hypertension and are currently on lisinopril.",
        category: "medications"
    },
    {
        notes: "The patient has also been feeling isolated since their spouse passed away last year.",
        category: "social"
    },
    {
        notes: "They have been drinking two cups of coffee daily and occasionally using cannabis to help with sleep.",
        category: "substance"
    },
    {
        notes: "They are due for their annual flu vaccine.",
        category: "prevention"
    },
    {
        notes: "The patient is a 78-year-old male with a history of falls at home.",
        category: "agedCare"
    },
    {
        notes: "He is currently using a walker for mobility.",
        category: "agedCare"
    },
    {
        notes: "The patient has poor vision and is scheduled for cataract surgery next month.",
        category: "agedCare"
    },
    {
        notes: "He has been taking aspirin daily and was recently prescribed metformin for diabetes.",
        category: "medications"
    },
    {
        notes: "The patient’s diet is poor, and he has been losing weight over the past few months.",
        category: "agedCare"
    },
    {
        notes: "The patient reports ongoing stress at work and difficulty managing their workload.",
        category: "mentalHealth"
    },
    {
        notes: "They are considering taking time off to focus on their mental health.",
        category: "mentalHealth"
    },
    {
        notes: "The patient also mentions that they have been drinking energy drinks daily to stay alert.",
        category: "substance"
    },
    {
        notes: "They have no significant medical history but are concerned about their recent lack of sleep.",
        category: "mentalHealth"
    }
];


const initialConversation = `
Patient has been complaining of low back pain for the past 3 years. He's been on lyrica 75mg/tab BD and panadol osteo 665mg/tab QID. Started Targin 5/2.5mg/tab BD
He's been suffering from depression as he can't drive truck anymore due to his back pain. 
He can't drive as he can't sit very long.
He has been on Workcover to help with his finance. 
He lives alone, but has supportive friends who visit.
He drinks a lot of alcohol, up to 2 bottles of wine per day.
He smokes 20 cigs per day.
Occasionally he takes up to 1 gm cannabis to help with his pain. 
He has been applying for job 5 times per week.
He has seen social worker to update his resume.
He has been calling his mother and sister twice weekly.
`;

//////

const patientData = {
    patient: {
        _id: '23lkjf',
        firstName: 'Firstname',
        lastName: 'lastname',
        gender: 'male',
        ethnic: 'Caucasian',
        pronoun: 'he/him',
        dob: '2000-01-01T08:25:37.123Z',
        medicare: '',
        address1 : '',
        address2 : '',
        suburb : '',
        state : '',
        postcode: ''
    },
    practitioner: {},
    teams: [],
    consultation: [],
    history : [],
    todos: [],
    goals: [],
    reviews: [],
    journeys: [],
    tags: [],
    mhcp: {},
    mhformulation: {},
    mhsafety: {},
    relapsePrevention : {},
    mhproblems: [],
    mhreviews: [],
    healthassessment: {},
    haplanning: [],
    hareviews: [],
    cdm: [],
    cdmreviews: []
};

const instructionSorting = `
    You are a medical practitioner. Write a report as medical notes.
    You need to separate userInput into different category.
    Put the same sentence into one category.
    Do not add anything outside of the given input. Use British spelling.
    Don't add any other comment.
    Avoid repetition within the notes.
    Return response as a JSON object.
    Use this format : 
    [{
        notes : "notes of the consultation",
        category : "category of this notes. 
        use "mentalHealth" if mental health and psychiatric related, 
        "chronicDisease" if related with chronic disease, 
        "social" if related with social issue such as relationship, friends, social support, occupation, legal, finance, driving, activities
        "prevention" if related with vaccination, health screening, repeat colonoscopy/endoscopy, scan blood test or other imaging, 
        "substance" if related with smoking, alcohol, caffeine, cannabis, ice, ecstassy, methaemphetamine, coccaine
        "medications" if there's any medication being taken"
        "agedCare" if related with medication arrangement, vision, hearing, fall, home safety, skin, feet, nutrition, oral health, cognition, mobility
    }]
`;

const instructionWriting = `
You are a medical professional writing medical notes from the data.
Do not add anything outside of the given input. Use British spelling.
Don't add any other comment.
Avoid repetition within the notes.
Sort it with the following order, and ensure that each section title is followed by a colon and a space, write the content underneath:
Subjective : contains the history and the complaints
Objective : any physical examination if applicable. If there is no content, do not include the "Objective :" section.
Assessment : Assessment for this consultation as a list of diagnoses or problems. If there is no content, do not include the "Assessment :" section.
Plan : Treatment and investigation plan if any. Don't create any plan if not mentioned. If there is no content, do not include the "Plan :" section.
Return the response as text.
`

const instructionDataToPlan = `
Your job is to create review note.
Read through the data provided. 
Use british spelling
Return as JSON Object
Do not add anything outside of the given input. Use British spelling.
Don't add any other comment.
Return as the following format : {id: null, date: null, review: combined data provided into one note, different paragraph for each topic} 
For the review field sort it with the following order, and ensure that each section title is followed by a colon and a space, write the content underneath:
Subjective : contains the history and the complaints
Objective : any physical examination if applicable. If there is no content, do not write the objective section
Assessment : Assessment for this consultation as a list of diagnoses or problems. If there is no content, do not write the assessment section
Plan : Treatment and investigation plan if any. Don't create any plan if not mentioned. If there is no content, do not write the plan section
`


class Consult extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');
        this.state = {
            referrences: [],
            dashboard: [],
            formData: savedFormData ? JSON.parse(savedFormData): {},
            //AI section
            isLoading: false,
            AInote: "",
            // printing option
            showPrintOption: false,

        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    componentDidMount() {
        const encryptedAdmin = localStorage.getItem('admin');
        if (encryptedAdmin) {
            const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
            const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const referrences = adminData.contacts;
            const dashboard = adminData.dashboard;
            this.setState({ referrences: referrences, dashboard: dashboard });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if formData has changed and if it's not empty, then save to localStorage
        if (prevState.formData !== this.state.formData && Object.keys(this.state.formData).length !== 0) {
            this.saveToLocalStorage();
        }
    }

    // Local Storage method
    saveToLocalStorage = () => {
        localStorage.setItem('currentPatient', JSON.stringify(this.state.formData));
    };

    clearLocalStorage = () => {
        localStorage.removeItem('currentPatient');
        this.render()
    }



    //Edit form functions
    toggleEdit = (section) => {
        this.setState((prevState) => ({
            [section]: !prevState[section]
        }), () => {
            if (!this.state[section]) {
                // If toggling from editing to not editing, save to localStorage
                this.saveToLocalStorage();
            }
        });
    };
    

    handleInputChange = (section, key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [section]: {
                    ...prevState.formData[section],
                    [key]: value,
                },
            },
        }));
    };
    
    // AI text to form and notes 
    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    AITranscription = async (newTranscription, toastId) => {

        const initData = this.state.formData;
        var processedInput;

        // Agent to write notes, then add to consultation box
        toast.update(toastId, {
            render: 'Writing doctor notes...',
            autoClose: false,
            hideProgressBar: false
        });
        processedInput = `${instructionWriting}\nUser: ${newTranscription}`;
        const notes = await writeNotes(processedInput);
        this.setState({ AInote: notes });

        // Add the new notes to the consultation array
        const timeStamp = new Date();
        const consultation = Array.isArray(initData.consultation) ? [...initData.consultation] : [];
        if (typeof notes === 'string') {
            consultation.push({ consultation: notes, timeStamp: timeStamp });
        } else if (Array.isArray(notes)) {
            consultation.push(...{ consultation: notes, timeStamp: timeStamp });
        } else {
            console.warn("Unexpected notes format:", notes);
    
        }

        // Agent to sort Text
        toast.update(toastId, {
            render: 'Sorting notes...',
            autoClose: false,
            hideProgressBar: false
        });
        processedInput = `${instructionSorting}\nUser: ${newTranscription}`;
        const sortedData = await sortingText(processedInput);

        // Agent to sort Data to different JSON section in Mental Health, Health Assessment and CDM
        const mhreviews = initData.mhreviews ?? [];
        const hareviews = initData.hareviews ?? [];
        const cdmreviews = initData.cdmreviews ?? [];

        let updated_mhreviews = [...mhreviews];
        let updated_hareviews = [...hareviews];
        let updated_cdmreviews = [...cdmreviews];
            
        const date = new Date();
        const today = date.toISOString().split('T')[0];
       
        const allNotes = combineNotesByCategory(sortedData);
    
        // send note to mental health reviews

        var categories = [
            ["mentalHealth", "substance", "social"],
            ["social", "prevention", "medications", "agedCare"],
            ["chronicDisease"]
        ]

        
        // Process each category asynchronously
        const processCategories = async () => {
            for (let [index, item] of categories.entries()) {
                const noteData = filterCategories(allNotes, item);
                const processedInput = `${instructionDataToPlan}\nUser: ${JSON.stringify({ noteData })}`;
                let respond;

                if (noteData) {
                    respond = await LLMTextToJSON(processedInput);
                }

                if (respond) {
                    respond.date = today;
                    respond.id = generateId();
                    console.log(respond)
                    // Update mhreviews, hareviews, or cdmreviews based on the category index
                    if (index === 0) {
                        // First list (mentalHealth, substance, social) -> update mhreviews
                        toast.update(toastId, {
                            render: 'Writing mental health review...',
                            autoClose: false,
                            hideProgressBar: false
                        });
                        updated_mhreviews = [...updated_mhreviews, respond];
                    } else if (index === 1) {
                        // Second list (social, prevention, medications, agedCare) -> update hareviews
                        toast.update(toastId, {
                            render: 'Writing Health Assessment review...',
                            autoClose: false,
                            hideProgressBar: false
                        });
                        updated_hareviews = [...updated_hareviews, respond];
                    } else if (index === 2) {
                        // Third list (chronicDisease) -> update cdmreviews
                        toast.update(toastId, {
                            render: 'Writing Chronic Disease Review...',
                            autoClose: false,
                            hideProgressBar: false
                        });
                        updated_cdmreviews = [...updated_cdmreviews, respond];
                    }
                }
            }

            toast.update(toastId, {
                render: 'All task done!',
                type: toast.TYPE.SUCCESS,
                autoClose: 1000,  
                hideProgressBar: false
            });

            // After processing, update the state
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    consultation: consultation,
                    mhreviews: updated_mhreviews,
                    hareviews: updated_hareviews,
                    cdmreviews: updated_cdmreviews
                },
            }), this.saveToLocalStorage);
        };

        // Execute the async function
        processCategories();

        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });

    };

    // Fill in Consultation Form
    AItoForm = async(data) => {
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0];

        const consultation = data.consultation;
    
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                consultation: consultation
            },
        }));

    };

    // Methods for consultation 
    handleDeleteConsultation = (index) => {
        this.setState((prevState) => {
            const updatedConsultations = prevState.formData.consultation.filter((_, i) => i !== index);
            return {
                formData: {
                    ...prevState.formData,
                    consultation: updatedConsultations,
                },
            };
        }, this.saveToLocalStorage);  // Save changes after deleting
    };
    
    handleSelectConsultation = (index) => {
        const selectedConsultation = this.state.formData.consultation[index];
        this.setState({
            AInote: selectedConsultation.consultation,  // Populate the textarea for editing
            selectedConsultationIndex: index,  // Keep track of the selected consultation
        });
    };
    
    updateSelectedConsultation = () => {
        const { selectedConsultationIndex, AInote, formData } = this.state;
    
        if (selectedConsultationIndex !== undefined) {
            // Update the selected consultation with the new text
            const updatedConsultations = [...formData.consultation];
            updatedConsultations[selectedConsultationIndex] = {
                ...updatedConsultations[selectedConsultationIndex],
                consultation: AInote,
            };
    
            this.setState({
                formData: {
                    ...formData,
                    consultation: updatedConsultations,
                },
            }, this.saveToLocalStorage);  // Save changes
        }
    };
    

    
    // render Notes
    renderNotes() {
        const formData = this.state.formData;
        const consultations = formData.consultation ?? []; // Handle empty case
    
        return consultations.map((item, index) => {
            return (
                <div key={index} className="bg-slate-100 rounded p-2 shadow m-2 flex justify-between items-start">
                    <div onClick={() => this.handleSelectConsultation(index)} className="flex-grow cursor-pointer overflow-hidden">
                        <p className="text-sm my-1 overflow-hidden whitespace-nowrap text-ellipsis">{item.consultation}</p>
                        <p className="text-xs italic my-2">{formatTimeStamp(item.timeStamp)}</p>
                    </div>
                    <button 
                        className="text-red-500 text-sm font-bold ml-2" 
                        onClick={() => this.handleDeleteConsultation(index)}
                    >
                        X
                    </button>
                </div>
            );
            
        });
    }
    

    // rendering section
    renderOperational() {
        const { AInote} = this.state;
        
        var messages;
        if (AInote) {
            messages = AInote;
        } else {
            messages = ""
        }
        return (
   
       
            <div className="col-span-6 bg-white p-6 mt-20t" >
            <div className='col-span-6'>  <AudioRecorder onInputUpdate={this.AITranscription} /></div>
            <div className='grid grid-cols-2 gap-2 m-10 z-10 xs:grid-cols-1 xs:m-0' ref={this.drawerRef}>
                           
                <div className='m-4'>
                    <p className='text-md font-semibold mx-4 text-center'>Consultation Notes</p>
                    {this.renderNotes()}
                </div>
                <div className='m-4'>
                    <p className='text-md font-semibold mx-4 text-center'>Doctor Notes</p>
                    <textarea
                        value={this.state.AInote}
                        onChange={(e) => this.setState({ AInote: e.target.value })} 
                        onBlur={this.updateSelectedConsultation}  // Update on blur (when focus leaves the textarea)
                        className="w-[100%] h-[700px] border-gray-200 resize-none"
                        placeholder="Response appears here."
                    />

                    <button
                        className="text-white text-md my-2 p-4 w-full rounded shadow-lg bg-sky-800" 
                        type="button" 
                        onClick={() => this.copyToClipboard(this.state.AInote)}
                    >
                        Copy
                    </button>
                </div>
            </div>
            </div>
      

        
        );
    }

    render() {

      
        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(320px, auto)' }}>
              
                {this.renderOperational()}
                <ToastContainer />
            </div>
        );
    }
    
}

export default Consult
