import React from 'react';
import SmartPaste from '../../utilities/SmartPaste';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToPlan } from '../../utilities/ReferrencesAI';

// Helper function to generate unique IDs
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const samplePaste = `
Ashtma	
Goal : 
Maintain best lung function 
identify and avoid trigger factors, 
optimise medication regimen, 
non-smoking, 
Regular aerobic exercise.
Annual flu vaccination
Seven yearly Pneumovax.
Plan : 	
Inhalers review annually
Asthma Plan review annually
Respiratory Physician if indicated


Atrial Fibrillation	
Goal : 
Minimise symptoms of AF
Decrease the risks of AF including stroke	
Control rate – aiming <110 beats per minute
Plan : 
monitor and titrate medication therapy
Determine stroke risk (CHA2DS2VA) 
Determine bleeding risk (HASBLED)
Consider Cardiologist referral
Echocardiography

Coeliac Disease	
Goal : 
Manage and monitor symptoms
Optimise nutritional levels
Monitor for associated autoimmune conditions	
Maintain a gluten free diet
Plan : 
Annual nutritional bloods
2 yearly BMD	
Dietician referral
`

// Default reference data structure
const defaultReferrences = [
    {
        id: generateId(),
        problem: "Diabetes type-2",
        goal: [
            { id: generateId(), description: "Maintain HbA1C below 7%" },
            { id: generateId(), description: "Adopt healthy eating habits" }
        ],
        plan: [
            { id: generateId(), entry: "Start metformin 500mg daily" },
            { id: generateId(), entry: "Monitor blood glucose daily" },
            { id: generateId(), entry: "Referral to dietitian" }
        ]
    },
    {
        id: generateId(),
        problem: "Asthma",
        goal: [
            { id: generateId(), description: "Prevent asthma exacerbations" },
            { id: generateId(), description: "Improve lung function" }
        ],
        plan: [
            { id: generateId(), entry: "Inhaled corticosteroids" },
            { id: generateId(), entry: "Monitor peak flow regularly" },
            { id: generateId(), entry: "Review asthma action plan" }
        ]
    }
    // Add other default reference objects here
];

class Referrences extends React.Component {
    constructor(props) {
        super(props);
        
        const referrencesData = props.data || defaultReferrences;
        this.state = {
            referrences: referrencesData,
            selectedProblem: null,
            editingProblemId: null,
            editingGoalId: null,
            editingPlanId: null,
            editingFieldValues: {}, 
            newProblem: "",
            newGoals: [{ id: generateId(), description: "" }],
            newPlans: [{ id: generateId(), entry: "" }],
            fileHandle: null
        };
    }

    componentDidMount() {
        // Load practices from localStorage on mount
        const adminData = this.props.loadFromLocalStorage();
        if (adminData) {
          this.setState({ referrences: adminData.referrences || [] });
        }
      }

    // Update component state when parent data changes
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data && this.props.data !== this.state.referrences) {
            this.setState({
                referrences: this.props.data || defaultReferrences,
                selectedProblem: null,
                editingProblemId: null,
                editingGoalId: null,
                editingPlanId: null,
                editingFieldValues: {}, 
                newProblem: "",
                newGoals: [{ id: generateId(), description: "" }],
                newPlans: [{ id: generateId(), entry: "" }]
            });
        }
    }

    handleProblemClick = (problemId) => {
        this.setState({ selectedProblem: problemId });
    };

    handleDoubleClick = (id, type, field) => {
        this.setState({
            [type]: id,
            editingFieldValues: { ...this.state.editingFieldValues, [id]: field }
        });
    };

    handleKeyPress = (e, id, type) => {
        if (e.key === 'Enter') {
            const { referrences, editingFieldValues } = this.state;

            const updatedReferrences = referrences.map(ref => {
                if (ref.id === id && type === 'editingProblemId') {
                    return { ...ref, problem: editingFieldValues[id] };
                }
                if (type === 'editingGoalId') {
                    ref.goal = ref.goal.map(goal => {
                        if (goal.id === id) {
                            return { ...goal, description: editingFieldValues[id] };
                        }
                        return goal;
                    });
                }
                if (type === 'editingPlanId') {
                    ref.plan = ref.plan.map(plan => {
                        if (plan.id === id) {
                            return { ...plan, entry: editingFieldValues[id] };
                        }
                        return plan;
                    });
                }
                return ref;
            });

            this.setState({
                referrences: updatedReferrences,
                editingProblemId: null,
                editingGoalId: null,
                editingPlanId: null,
                editingFieldValues: {}
            });
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        }
    };

    handleInputChange = (e, id) => {
        const { value } = e.target;
        this.setState({
            editingFieldValues: { ...this.state.editingFieldValues, [id]: value }
        });
    };

    handleAddGoal = () => {
        const newGoal = { id: generateId(), description: "" };
    
        this.setState(prevState => {
            const updatedReferrences = prevState.referrences.map(ref => {
                if (ref.id === prevState.selectedProblem) {
                    return { ...ref, goal: [...ref.goal, newGoal] };
                }
                return ref;
            });
    
            return {
                referrences: updatedReferrences,
                editingGoalId: newGoal.id,
                editingFieldValues: { ...prevState.editingFieldValues, [newGoal.id]: "" }
            };
        }, () => {
            // Save to parent after state is updated
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    
    handleAddPlan = () => {
        const newPlan = { id: generateId(), entry: "" };
    
        this.setState(prevState => {
            const updatedReferrences = prevState.referrences.map(ref => {
                if (ref.id === prevState.selectedProblem) {
                    return { ...ref, plan: [...ref.plan, newPlan] };
                }
                return ref;
            });
    
            return {
                referrences: updatedReferrences,
                editingPlanId: newPlan.id,
                editingFieldValues: { ...prevState.editingFieldValues, [newPlan.id]: "" }
            };
        }, () => {
            // Save to parent after state is updated
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    

    handleDeleteProblem = (problemId) => {
        this.setState((prevState) => ({
            referrences: prevState.referrences.filter(ref => ref.id !== problemId),
            selectedProblem: null
        }));
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleDeleteGoal = (problemId, goalId) => {
        const updatedReferrences = this.state.referrences.map(ref => {
            if (ref.id === problemId) {
                ref.goal = ref.goal.filter(goal => goal.id !== goalId);
            }
            return ref;
        });
        this.setState({ referrences: updatedReferrences });
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleDeletePlan = (problemId, planId) => {
        const updatedReferrences = this.state.referrences.map(ref => {
            if (ref.id === problemId) {
                ref.plan = ref.plan.filter(plan => plan.id !== planId);
            }
            return ref;
        });
        this.setState({ referrences: updatedReferrences });
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleNewInputChange = (e, type, index = null) => {
        const { value } = e.target;
        if (type === "problem") {
            this.setState({ newProblem: value });
        } else if (type === "goal" && index !== null) {
            const newGoals = [...this.state.newGoals];
            newGoals[index].description = value;
            this.setState({ newGoals });
        } else if (type === "plan" && index !== null) {
            const newPlans = [...this.state.newPlans];
            newPlans[index].entry = value;
            this.setState({ newPlans });
        }
    };

    handleSaveNewProblem = () => {
        const { newProblem, newGoals, newPlans, referrences } = this.state;
        if (newProblem.trim() === "") return;
    
        const newReference = {
            id: generateId(),
            problem: newProblem,
            goal: newGoals,
            plan: newPlans
        };
    
        this.setState(prevState => ({
            referrences: [...prevState.referrences, newReference],
            selectedProblem: newReference.id,
            newProblem: "",
            newGoals: [{ id: generateId(), description: "" }],
            newPlans: [{ id: generateId(), entry: "" }]
        }), () => {
            // Save to parent after state is updated
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    
    AIPaste = async (text, toastId) => {
         // filling up form
         toast.update(toastId, {
            render: 'Reading plan..',
            autoClose: false,
            hideProgressBar: false
        });

        var respond = await textToPlan(text);
        

        // updating localStorage

        if (respond) {
            const combinedReferrences = [...this.state.referrences, ...respond];

            this.setState({ referrences: combinedReferrences }, () => {
                // Now this.state.referrences is updated, and you can safely call your functions
                this.props.saveToFileSystem({ referrences: this.state.referrences });
                this.props.saveToLocalStorage({ referrences: this.state.referrences });
            });
        }

        // all done
        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });

    }

    render() {
        const { referrences, selectedProblem, editingFieldValues, newProblem, newGoals, newPlans } = this.state;
        const selectedReference = referrences.find(ref => ref.id === selectedProblem);
 

        return (
            <div className="m-20 p-4 bg-slate-100 rounded shadow">
                <p className="text-3xl font-bold p-4 mb-8 flex justify-center">Referrences</p>
                <div><SmartPaste onPasteData={this.AIPaste}/></div>

                <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-4 shadow rounded">
                        <strong>Problems</strong>
                        <button onClick={() => this.setState({ selectedProblem: null })} className="bg-sky-800 text-white p-2 rounded shadow w-full my-6">
                            Add New Problem
                        </button>
                        {referrences.map(ref => (
                            <div key={ref.id} className="flex justify-between p-2 m-2 rounded shadow bg-gray-200 cursor-pointer" onClick={() => this.handleProblemClick(ref.id)}>
                                {ref.problem} 
                            </div>
                        ))}
                    </div>

                    <div className="bg-white p-4 shadow rounded">
                        {!selectedReference ? (
                            <>
                                <input
                                    type="text"
                                    value={newProblem}
                                    onChange={(e) => this.handleNewInputChange(e, "problem")}
                                    placeholder="Enter new problem"
                                    className="w-full p-2 border border-gray-300 rounded mb-4"
                                />

                                <div className="goal-section mb-4">
                                    <strong>Goals</strong>
                                    {newGoals.map((goal, index) => (
                                        <div key={goal.id} className="goal-div bg-slate-200 hover:bg-slate-300 text-black m-2 p-2 rounded shadow">
                                            <input
                                                type="text"
                                                value={goal.description}
                                                onChange={(e) => this.handleNewInputChange(e, "goal", index)}
                                                placeholder="Enter goal"
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddGoal} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Goal</button>
                                </div>

                                <div className="plan-section">
                                    <strong>Plans</strong>
                                    {newPlans.map((plan, index) => (
                                        <div key={plan.id} className="plan-div bg-sky-200 hover:bg-sky-300 m-2 p-2 rounded shadow">
                                            <input
                                                type="text"
                                                value={plan.entry}
                                                onChange={(e) => this.handleNewInputChange(e, "plan", index)}
                                                placeholder="Enter plan"
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddPlan} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Plan</button>
                                </div>

                                <button onClick={this.handleSaveNewProblem} className="bg-sky-800 text-white p-2 rounded text-lg font-semibold shadow w-full mt-8">
                                    Save Problem
                                </button>
                            </>
                        ) : (
                            <>
                                {/* Selected problem and editing functionality */}
                                <div className="flex justify-between bg-sky-800 text-white p-2 rounded shadow">
                                    {this.state.editingProblemId === selectedReference.id ? (
                                        <input
                                            type="text"
                                            value={editingFieldValues[selectedReference.id] || ""}
                                            onChange={(e) => this.handleInputChange(e, selectedReference.id)}
                                            onKeyPress={(e) => this.handleKeyPress(e, selectedReference.id, 'editingProblemId')}
                                            className="w-full p-2 text-black"
                                            autoFocus
                                        />
                                    ) : (
                                        <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(selectedReference.id, 'editingProblemId', selectedReference.problem)}>
                                            {selectedReference.problem}
                                        </div>
                                    )}
                                    <div>
                                        <button className="text-white font-semibold" onClick={() => this.handleDeleteProblem(selectedReference.id)}>
                                            X
                                        </button>
                                    </div>
                                </div>

                                {/* Goals and Plans Section */}
                                <div className="goal-section m-4">
                                    <strong>Goals</strong>
                                    {selectedReference.goal.map(goal => (
                                        <div key={goal.id} className="flex justify-between items-center bg-slate-200 hover:bg-slate-300 text-black m-2 p-2 rounded shadow">
                                            {this.state.editingGoalId === goal.id ? (
                                                <input
                                                    type="text"
                                                    value={editingFieldValues[goal.id] || ""}
                                                    onChange={(e) => this.handleInputChange(e, goal.id)}
                                                    onKeyPress={(e) => this.handleKeyPress(e, goal.id, 'editingGoalId')}
                                                    className="w-full p-2 mr-2 rounded border border-gray-100"
                                                    autoFocus
                                                />
                                            ) : (
                                                <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(goal.id, 'editingGoalId', goal.description)}>
                                                    {goal.description}
                                                </div>
                                            )}
                                            <div>
                                                <button className="text-gray-800 font-semibold" onClick={() => this.handleDeleteGoal(selectedReference.id, goal.id)}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddGoal} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Goal</button>
                                </div>

                                <div className="plan-section m-4">
                                    <strong>Plans</strong>
                                    {selectedReference.plan.map(plan => (
                                        <div key={plan.id} className="flex justify-between items-center bg-sky-200 hover:bg-sky-300 m-2 p-2 rounded shadow">
                                            {this.state.editingPlanId === plan.id ? (
                                                <input
                                                    type="text"
                                                    value={editingFieldValues[plan.id] || ""}
                                                    onChange={(e) => this.handleInputChange(e, plan.id)}
                                                    onKeyPress={(e) => this.handleKeyPress(e, plan.id, 'editingPlanId')}
                                                    className="w-full p-2 mr-2 rounded border border-gray-100"
                                                    autoFocus
                                                />
                                            ) : (
                                                <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(plan.id, 'editingPlanId', plan.entry)}>
                                                    {plan.entry}
                                                </div>
                                            )}
                                            <div>
                                                <button className="text-gray-800 font-semibold" onClick={() => this.handleDeletePlan(selectedReference.id, plan.id)}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddPlan} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Plan</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}

export default Referrences;
